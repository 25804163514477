<div fxLayout="row wrap" fxLayoutAlign="center space-between" class="databox">
  <!-- Single subscription for the icon configuration -->
  @if (databoxLivesIconRepository.icon$ | async; as iconConfig) {
    <app-fa-icon [config]="iconConfig"></app-fa-icon>
  }

  <!-- Single subscription for the lives count -->
  @if (databoxLivesIconRepository.lives$ | async; as lives) {
    <span>{{ lives }}</span>
  }
</div>
