import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { asyncScheduler, delay, filter, map, of, switchMap, throttleTime } from 'rxjs';
import { IDataboxContainer } from 'src/app/api/modules/core/dynamic/databoxes/IDataboxContainer';
import { IPlugin, IPluginHeartbeatOptions } from 'src/app/api/modules/core/dynamic/plugin/components/IPlugin';
import { DEFAULT_PLUGIN_COMPONENT_HEIGHT } from 'src/app/app-constants';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { PluginModel } from './plugin.model';

/**
 * The Default State
 */
function initialState(): PluginModel {
  return {
    configured: false,
    ready: false,
    src: undefined,
    databox: undefined,
    heartbeat: undefined,
    heartbeatScore: 0,
    onReadyAPI: undefined,
    onErrorAPI: undefined,
    onEventAPI: undefined,
    onCompleteAPI: undefined,
    fullWidth: false,
    interactionEnabled: false,
    contentHeight: DEFAULT_PLUGIN_COMPONENT_HEIGHT,
    effect: {
      startTime: 0,
      duration: 0,
    },
  };
}

/**
 * The Store used for a {@link PluginComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class PluginRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `plugin-${uniqueId()}`,
    },
    withProps<PluginModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initialize the store with JSON configuration.
   *
   * @param configuration - the incoming configuration json
   */
  applyConfiguration(configuration?: IPlugin) {
    // if there is configration then that is a issue
    if (!configuration) {
      throw createDigitaServiceError(`Plugin`, `initialize`, `No configuration provided but this is required.`, `config`);
    }

    // source
    const src = configuration.src;
    if (!configuration.src) {
      throw createDigitaServiceError(`Plugin`, `initialize`, `No "src" property provided but this is required.`, `config`);
    }

    // databox
    let databox: IDataboxContainer | undefined = undefined;
    if (configuration.databox) {
      databox = cloneDeep(configuration.databox);
    }

    // heartbeat
    let heartbeat: IPluginHeartbeatOptions | undefined = undefined;
    if (configuration.heartbeat) {
      // the heartbeat api
      const heartbeatAPI = configuration.heartbeat.api;
      if (!heartbeatAPI || heartbeatAPI.length === 0) {
        throw createDigitaServiceError(`Plugin`, `initialize`, `The heartbeat object was set but no heartbeat.api was provided.`, `config`);
      }

      // if the heartbeat throttle time is not set or is less than 1000 then set it to 1000
      let heartbeatThrottleTimeMS = configuration.heartbeat.throttleTimeMS;
      if (isNaN(heartbeatThrottleTimeMS) || heartbeatThrottleTimeMS < 1000) {
        heartbeatThrottleTimeMS = 1000;
      }

      // set the heartbeat
      heartbeat = {
        api: heartbeatAPI,
        throttleTimeMS: heartbeatThrottleTimeMS,
      };
    }

    // on ready API
    let onReadyAPI: string | undefined = undefined;
    if (configuration.onReadyAPI) {
      onReadyAPI = configuration.onReadyAPI;
    }

    // on error API
    let onErrorAPI: string | undefined = undefined;
    if (configuration.onErrorAPI) {
      onErrorAPI = configuration.onErrorAPI;
    }

    // on event API
    let onEventAPI: string | undefined = undefined;
    if (configuration.onEventAPI) {
      onEventAPI = configuration.onEventAPI;
    }

    // on complete API
    let onCompleteAPI: string | undefined = undefined;
    if (configuration.onCompleteAPI) {
      onCompleteAPI = configuration.onCompleteAPI;
    }

    // fullwidth
    let fullWidth = false;
    if (configuration.fullWidth === true) {
      fullWidth = true;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.src = src;
        state.databox = databox;
        state.heartbeat = heartbeat;
        state.onReadyAPI = onReadyAPI;
        state.onErrorAPI = onErrorAPI;
        state.onEventAPI = onEventAPI;
        state.onCompleteAPI = onCompleteAPI;
        state.fullWidth = fullWidth;
      }),
    );
  }

  /**
   * Occurs when the IFrame has loaded it's contents.
   */
  applyReady() {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.ready = true;
      }),
    );
  }

  /**
   * The height as a number value dispatched from the plugin.
   *
   * @param height - the height numeric value
   */
  applyContentHeight(height: number) {
    this.store.update(
      ElfWrite((state) => {
        state.contentHeight = Math.ceil(height);
      }),
    );
  }

  /**
   * Is the Iframe Interactive?
   *
   * @param flag - true if the iframe is interactive or false otherwise
   */
  applyFrameInteractive(flag = false) {
    this.store.update(
      ElfWrite((state) => {
        state.interactionEnabled = flag;
      }),
    );
  }

  /**
   * Set the latest score obtained from the plugin. The data is optionally used within the heartbeat method.
   *
   * @param score - the latest score from the plugin.
   */
  applyHeartbeatScore(score: number) {
    this.store.update(
      ElfWrite((state) => {
        state.heartbeatScore = score;
      }),
    );
  }

  /**
   * Update a delay to be used before the onCompleteAPI is called. This is applied when an Effect is used.
   *
   * @param delay - the delay in milliseconds
   */
  applyEffectDelay(delay = 0) {
    this.store.update(
      ElfWrite((state) => {
        state.effect = {
          startTime: performance.now(),
          duration: delay,
        };
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // CORE
  ///////////////////////////////////////////////////////////

  /**
   * Has the system been configured?
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((configured) => configured),
  );

  /**
   * Has the Plugin loaded and is ready for use?
   */
  private _ready$ = this.store.pipe(select((state) => state.ready));

  /**
   * Is interactions enabled on the iframe?
   */
  interactionEnabled$ = this.store.pipe(select((state) => state.interactionEnabled));

  /**
   * The source of the Iframe.
   */
  private _src$ = this.store.pipe(select((state) => state.src));

  /**
   * Should the plugin be full width?
   */
  private _fullWidth$ = this.store.pipe(select((state) => state.fullWidth));

  /**
   * The content height of the plugin as a number value.
   */
  private _contentHeight$ = this.store.pipe(select((state) => state.contentHeight));

  /**
   * The effects configuration.
   */
  private _effects = this.store.pipe(select((state) => state.effect));

  /**
   * The remaining duration of the effect.
   *
   * Will return zero if the time has expired.
   */
  private _effectsDuration$ = this._effects.pipe(
    map((effect) => {
      if (effect.duration === 0) {
        return 0;
      }

      const currentTime = performance.now();
      const elapsedTime = currentTime - effect.startTime;
      const remainingTime = effect.duration - elapsedTime;
      return remainingTime > 0 ? Math.floor(remainingTime) : 0;
    }),
  );

  /**
   * Contains all of the plugin state configuration, mostly for configuring the template.
   */
  templateData$ = ElfCombineQueries([
    this._configured$,
    this._ready$,
    this._contentHeight$,
    this._src$,
    this._fullWidth$,
    this.interactionEnabled$,
  ]).pipe(
    map(([configured, ready, contentHeight, src, fullWidth, interactionEnabled]) => {
      return {
        configured,
        ready,
        displayLoading: !ready,
        displayContent: ready,
        contentHeight: `${contentHeight}px`,
        src,
        fullWidth,
        interactionEnabled,
      };
    }),
  );

  ///////////////////////////////////////////////////////////
  // DATABOX
  ///////////////////////////////////////////////////////////

  /**
   * The Databox Configuration.
   */
  databox$ = this.store.pipe(select((state) => state.databox));

  ///////////////////////////////////////////////////////////
  // HEARTBEAT
  ///////////////////////////////////////////////////////////

  /**
   * The Heartbeat Configuration.
   */
  private _heartbeatConfig$ = this.store.pipe(select((state) => state.heartbeat));

  /**
   * The Heartbeat Score.
   */
  private _heartbeatScore$ = this.store.pipe(select((state) => state.heartbeatScore));

  /**
   * The Heartbeat Query combines and emits the heartbeat configuration and the heartbeat score.
   */
  heartbeat$ = this._heartbeatConfig$.pipe(
    // Only proceed if heartbeatConfig is truthy
    filter((heartbeatConfig) => !!heartbeatConfig),
    // Map to heartbeatScore observable
    switchMap((heartbeatConfig) =>
      this._heartbeatScore$.pipe(
        // Throttle based on the configuration
        throttleTime(heartbeatConfig.throttleTimeMS, asyncScheduler, { leading: true, trailing: false }),
        // Filter out scores that are 0 or less
        filter((heartbeatScore) => heartbeatScore > 0),
        // Map to the required object structure
        map((heartbeatScore) => ({
          heartbeatAPI: heartbeatConfig.api,
          heartbeatScore,
        })),
      ),
    ),
  );

  ///////////////////////////////////////////////////////////
  // ON READY API
  ///////////////////////////////////////////////////////////

  /**
   * The onReadyAPI end Point.
   */
  onReadyAPI$ = this.store.pipe(
    select((state) => state.onReadyAPI),
    filter(Boolean),
  );

  ///////////////////////////////////////////////////////////
  // ON Error API
  ///////////////////////////////////////////////////////////

  /**
   * The onErrorAPI end Point.
   */
  onErrorAPI$ = this.store.pipe(
    select((state) => state.onErrorAPI),
    filter(Boolean),
  );

  ///////////////////////////////////////////////////////////
  // ON Event API
  ///////////////////////////////////////////////////////////

  /**
   * The onEventAPI end Point.
   */
  onEventAPI$ = this.store.pipe(
    select((state) => state.onEventAPI),
    filter(Boolean),
  );

  ///////////////////////////////////////////////////////////
  // ON Complete API
  ///////////////////////////////////////////////////////////

  /**
   * The onCompleteAPI end Point.
   */
  private _onCompleteAPI$ = this.store.pipe(select((state) => state.onCompleteAPI));

  /**
   * Returns the onCompleteAPI end Point after the effects duration has expired.
   */
  onCompleteAPI$ = ElfCombineQueries([this._onCompleteAPI$, this._effectsDuration$]).pipe(
    switchMap(([onCompleteAPI, effectsDuration]) => {
      return of(onCompleteAPI).pipe(delay(effectsDuration));
    }),
  );
}
