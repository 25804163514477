import { animate, animation, keyframes, style } from '@angular/animations';
import { ANIMATION_DELAY, ANIMATION_DURATION, ANIMATION_TRANSLATION } from '../AnimationConsts';
import { IAnimationOptions } from '../IAnimationOptions';

export interface ICommonAnimation extends IAnimationOptions {
  /**
   * The starting opacity for the fade
   */
  startOpacity?: number;

  /**
   * The ending opacity for the fade
   */
  endOpacity?: number;

  /**
   * The offset from 0 where the element is placed to begin
   * its transform on the X Axis
   */
  startTranslateX?: number;

  /**
   * The offset from 0 where the element is placed to begin
   * its transform on the Y Axis
   */
  startTranslateY?: number;

  /**
   * The offset from 0 where the element ends
   * its transform on the X Axis
   */
  endTranslateX?: number;

  /**
   * The offset from 0 where the element ends
   * its transform on the Y Axis
   */
  endTranslateY?: number;

  /**
   * The starting staling factor as an integer
   */
  startScale?: number;

  /**
   * The starting staling factor as an integer
   */
  endScale?: number;
}

/**
 * The default animation options
 */
const DEFAULT_ANIMATION_OPTIONS: Required<ICommonAnimation> = {
  autoplay: false,
  delay: ANIMATION_DELAY,
  duration: ANIMATION_DURATION,
  ease: 'ease-out',
  endOpacity: 1,
  endScale: 1,
  endTranslateX: 0,
  endTranslateY: 0,
  startOpacity: 1,
  startScale: 1,
  startTranslateX: 0,
  startTranslateY: 0,
};

export const commonAnimation = (options: Required<ICommonAnimation>) => {
  return animation([
    animate(
      `${options.duration}ms ${options.delay}ms`,
      keyframes([
        style({
          opacity: options.startOpacity,
          transform: `translate3d(${options.startTranslateX}px, ${options.startTranslateY}px, 0) scale3d(${options.startScale}, ${options.startScale}, 1)`,
          easing: options.ease,
          offset: 0,
        }),
        style({
          opacity: options.endOpacity,
          transform: `translate3d(${options.endTranslateX}px, ${options.endTranslateY}px, 0) scale3d(${options.endScale}, ${options.endScale}, 1)`,
          easing: options.ease,
          offset: 1,
        }),
      ]),
    ),
  ]);
};

export const fadeInAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 0,
    endOpacity: 1,
    ...options,
  });
};

export const fadeOutAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 1,
    endOpacity: 0,
    ...options,
  });
};

export const slideInFromLeftAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    endOpacity: 1,
    startTranslateX: -ANIMATION_TRANSLATION,
    ...options,
  });
};

export const slideInFromRightAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    endOpacity: 1,
    startTranslateX: ANIMATION_TRANSLATION,
    ...options,
  });
};

export const slideInFromTopAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    endOpacity: 1,
    startTranslateY: -ANIMATION_TRANSLATION,
    ...options,
  });
};

export const slideInFromBottomAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 0,
    endOpacity: 1,
    startTranslateY: ANIMATION_TRANSLATION,
    ...options,
  });
};

export const slideOutToLeftAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 1,
    endOpacity: 0,
    endTranslateX: -ANIMATION_TRANSLATION,
    ...options,
  });
};

export const slideOutToRightAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 1,
    endOpacity: 0,
    endTranslateX: ANIMATION_TRANSLATION,
    ...options,
  });
};

export const slideOutToTopAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 1,
    endOpacity: 0,
    endTranslateY: -ANIMATION_TRANSLATION,
    ...options,
  });
};

export const slideOutToBottomAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 1,
    endOpacity: 0,
    endTranslateY: ANIMATION_TRANSLATION,
    ...options,
  });
};

export const zoomInAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 0,
    endOpacity: 1,
    startScale: 0.5,
    endScale: 1,
    ...options,
  });
};

export const zoomOutAnimation = (options: Partial<ICommonAnimation> = {}) => {
  return commonAnimation({
    ...DEFAULT_ANIMATION_OPTIONS,
    startOpacity: 1,
    endOpacity: 0,
    startScale: 1,
    endScale: 0.5,
    ...options,
  });
};
