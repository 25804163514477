import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, filter, Subscription } from 'rxjs';
import { DOMIntersectionObserverService } from '../services/dom-intersection.service';

/**
 * Wraps the DOM Intersection Observer API in an Angular Directive.
 *
 * Belongs to the {@link CoreModule}.
 */
@Directive({
  selector: '[appDomIntersectionObserver]',
  exportAs: 'intersection',
  standalone: true,
})
export class DomIntersectionObserverDirective implements OnInit, OnDestroy {
  /**
   * Tells us when the user has stopped scrolling.
   */
  @Input() debounceTime = 250;

  /**
   * Flag that we use to decide whether we want to continue observing an element for visibility changes.
   */
  @Input() isContinuous = false;

  /**
   * Emits an event that tells us if the element is intersecting
   */
  @Output() isIntersecting = new EventEmitter<boolean>();

  /**
   * A public property that we use to trigger changes to the template
   */
  intersecting = false;

  /**
   * Holds a reference to the subscription
   */
  private subscription?: Subscription;

  /**
   * Constructor
   *
   * @param element - the hosts element
   */
  constructor(
    private element: ElementRef,
    private readonly service: DOMIntersectionObserverService,
  ) {}

  /**
   * Lifecycle
   */
  ngOnInit() {
    // create the subscription to the intersection service
    this.subscription = this.service.entries$
      .pipe(
        // we only care about this specific element
        filter((entry) => entry.target === this.element.nativeElement),
        // debounce the event
        debounceTime(this.debounceTime),
      )
      .subscribe((entry) => {
        // if the element is intersecting and we don't want to continue observing
        if (entry.isIntersecting && !this.isContinuous) {
          // stop observing
          this.service.unobserve(this.element.nativeElement);
        }

        // set the property
        this.intersecting = entry.isIntersecting;

        // emit the event
        this.isIntersecting.emit(entry.isIntersecting);
      });

    // observe the element
    this.service.observe(this.element.nativeElement);
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    // unsubscribe from the service
    this.service.unobserve(this.element.nativeElement);

    // unsubscribe from the subscription
    this.subscription?.unsubscribe();
  }
}
