import { LoggerService } from '@angular-ru/cdk/logger';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { EMPTY, delay, filter, map, of, switchMap, take } from 'rxjs';
import { IMedia } from 'src/app/api/modules/core/dynamic/components/IMedia';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { MediaManagerRepository } from '../../../services/media-manager/media-manager.repository';
import { MediaNativeAudioModel } from './media-native-audio/media-native-audio.model';
import { MediaNativeVideoModel } from './media-native-video/media-native-video.model';
import { MediaVimeoModel } from './media-vimeo/media-vimeo.model';
import { MediaYouTubeModel } from './media-youtube/media-youtube.model';
import { MediaModel } from './media.model';
import { MediaTypes } from './media.types';

/**
 * The Default State
 */
function initialState(): MediaModel {
  return {
    width: undefined,
    height: undefined,
    autoplay: false,
    controls: true,
    keyboard: false,
    loop: false,
    playsinline: false,
    poster: undefined,
    muted: false,
    pip: false,
    interaction: true,
    youtube: undefined,
    vimeo: undefined,
    video: undefined,
    audio: undefined,
    configured: false,
    initialized: false,
    intersecting: false,
    hasIntersectedPreviously: false,
    errorIcon: {
      name: 'far:triangle-exclamation',
      size: '2x',
    },
    error: false,
    errorReason: undefined,
    detailsObtained: false,
    explicitPoster: false,
    explicitDimensions: false,
    type: MediaTypes.video,
    ready: false,
    playing: false,
    paused: false,
    ended: false,
    currentTime: 0,
    duration: 0,
    mediaID: 'media',
    hasMediaPlayed: false,
    displayUIOverlay: false,
    preventPauseOnViewportExit: false,
  };
}

/**
 * The Store used for an {@link MediaComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `media-${uniqueId()}`,
    },
    withProps<MediaModel>(initialState()),
  );

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly loggerService: LoggerService,
    private readonly mediaManagerRepository: MediaManagerRepository,
    protected readonly mediaObserver: MediaObserver,
  ) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IMedia) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`MediaStore`, `applyConfiguration`, `No configuration provided but this is required.`, `config`);
    }

    // one of the following must be provided
    let mediaTypeKnown = false;

    // process the configuration depending on the type
    if (configuration.youtube) {
      this.applyInitializeYouTube(configuration);
      mediaTypeKnown = true;
    } else if (configuration.vimeo) {
      this.applyInitializeVimeo(configuration);
      mediaTypeKnown = true;
    } else if (configuration.video) {
      this.applyInitializeNativeVideo(configuration);
      mediaTypeKnown = true;
    } else if (configuration.audio) {
      this.applyInitializeNativeAudio(configuration);
      mediaTypeKnown = true;
    }

    // if the media type is not known then throw an error
    if (!mediaTypeKnown) {
      throw createDigitaServiceError(
        `MediaStore`,
        `applyInitialize`,
        `No "youtube", "vimeo", "video" or "audio" media type provided but one of these is required.`,
        `config`,
      );
    }
  }

  /**
   * Initialize from a YouTube Configuration.
   *
   * @param configuration - the youtube based configuration
   */
  private applyInitializeYouTube(configuration: IMedia) {
    // if no youtube configuration then throw an error.
    if (!configuration?.youtube) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeYouTube',
        'No youtube configuration provided but this is required.',
        'config',
      );
    }

    // if no video id then throw an error.
    if (!configuration.youtube.videoId) {
      this.applyError('No YouTube "videoId" provided but this is required.');
      return;
    }

    // if the configuration comes with an explcit width and height then use that.
    // this allows the configuration to override the OEmbed Request width and height
    // which is useful for portrait videos.
    let explicitDimensions = false;
    let width: number | undefined = undefined;
    let height: number | undefined = undefined;
    const dimensionsAreNumbers = typeof configuration.width === 'number' && typeof configuration.height === 'number';
    const dimensionsAreValid = dimensionsAreNumbers && configuration.width > 0 && configuration.height > 0;
    if (dimensionsAreValid) {
      explicitDimensions = true;
      width = configuration.width;
      height = configuration.height;
    }

    // if the configuration comes with a specific poster then use that.
    let explicitPoster = false;
    const posterIsString = typeof configuration.poster === 'string';
    const posterIsUrl = posterIsString && configuration.poster.length > 0;
    let poster: string | undefined = undefined;
    if (posterIsUrl) {
      explicitPoster = true;
      poster = configuration.poster;
    }

    // interactive mode
    let interaction = true;
    if (configuration.interaction === false) {
      interaction = false;
    }

    // clone the youtube configuration
    const clonedProviderConfig: MediaYouTubeModel = cloneDeep(configuration.youtube);

    // if there is no playerVars then create an empty object
    if (!clonedProviderConfig.playerVars) {
      clonedProviderConfig.playerVars = {};
    }

    // the width and height must be 100% for the YouTube Player
    clonedProviderConfig.width = '100%';
    clonedProviderConfig.height = '100%';

    // if the configuration for autoplay has been set, then display a warning
    if (clonedProviderConfig.playerVars.autoplay === 1 || clonedProviderConfig.playerVars.autoplay === 0) {
      this.warnTopLevelProperty('youtube.playerVars.autoplay', 'autoplay');
    }
    // set the youtube property using the top level provider
    let autoplay = false;
    if (configuration.autoplay === true) {
      clonedProviderConfig.playerVars.autoplay = 1;
      autoplay = true;
    } else {
      clonedProviderConfig.playerVars.autoplay = 0;
      autoplay = false;
    }

    // if the configuration for controls has been set, then display a warning
    if (clonedProviderConfig.playerVars.controls === 1 || clonedProviderConfig.playerVars.controls === 0) {
      this.warnTopLevelProperty('youtube.playerVars.controls', 'controls');
    }
    // set the youtube property using the top level provider
    let controls = true;
    if (configuration.controls === false || interaction === false) {
      clonedProviderConfig.playerVars.controls = 0;
      controls = false;
    } else {
      clonedProviderConfig.playerVars.controls = 1;
      controls = true;
    }

    // if the configuration for disablekb has been set, then display a warning
    if (clonedProviderConfig.playerVars.disablekb === 1 || clonedProviderConfig.playerVars.disablekb === 0) {
      this.warnTopLevelProperty('youtube.playerVars.disablekb', 'keyboard');
    }
    // set the youtube property using the top level provider
    let keyboard = false;
    if (configuration.keyboard === true || interaction === false) {
      clonedProviderConfig.playerVars.disablekb = 0;
      keyboard = true;
    } else {
      clonedProviderConfig.playerVars.disablekb = 1;
      keyboard = false;
    }

    // force set the enablejsapi
    clonedProviderConfig.playerVars.enablejsapi = 1;

    // if the configuration for list is provided
    if (clonedProviderConfig.playerVars.list) {
      clonedProviderConfig.playerVars.list = undefined;
      this.warnDoNotUseProperty('youtube.playerVars.list');
    }

    // if the configuration for listType is provided
    if (clonedProviderConfig.playerVars.listType) {
      clonedProviderConfig.playerVars.listType = undefined;
      this.warnDoNotUseProperty('youtube.playerVars.listType');
    }

    // if the configuration for playlist is provided
    if (clonedProviderConfig.playerVars.playlist) {
      clonedProviderConfig.playerVars.playlist = undefined;
      this.warnDoNotUseProperty('youtube.playerVars.playlist');
    }

    // if the configuration for loop is provided
    if (clonedProviderConfig.playerVars.loop === 1 || clonedProviderConfig.playerVars.loop === 0) {
      this.warnTopLevelProperty('youtube.playerVars.loop', 'loop');
    }

    // set the youtube property using the top level provider
    let loop = false;
    if (configuration.loop === true) {
      clonedProviderConfig.playerVars.loop = 1;
      clonedProviderConfig.playerVars.playlist = configuration.youtube.videoId;
      loop = true;
    } else {
      clonedProviderConfig.playerVars.loop = 0;
      loop = false;
    }

    // force set the origin
    clonedProviderConfig.playerVars.origin = this.document?.defaultView?.location?.host;

    // if the configuration for playsinline is provided
    if (clonedProviderConfig.playerVars.playsinline === 1 || clonedProviderConfig.playerVars.playsinline === 0) {
      this.warnTopLevelProperty('youtube.playerVars.playsinline', 'playsinline');
    }
    // set the youtube property using the top level provider
    let playsinline = false;
    if (configuration.playsinline === true) {
      clonedProviderConfig.playerVars.playsinline = 1;
      playsinline = true;
    } else {
      clonedProviderConfig.playerVars.playsinline = 0;
      playsinline = false;
    }

    // pip
    const pip = false;

    // if the configuration for mute is provided
    if (clonedProviderConfig.playerVars.mute === 1 || clonedProviderConfig.playerVars.mute === 0) {
      this.warnTopLevelProperty('youtube.playerVars.mute', 'muted');
    }
    // set the youtube property using the top level provider
    let muted = false;
    if (configuration.muted === true) {
      clonedProviderConfig.playerVars.mute = 1;
      muted = true;
    } else {
      clonedProviderConfig.playerVars.mute = 0;
      muted = false;
    }

    // prevent media pause when not intersecting
    let preventPauseOnViewportExit = false;
    if (configuration.preventPauseOnViewportExit === true) {
      preventPauseOnViewportExit = true;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        if (explicitDimensions) {
          state.explicitDimensions = explicitDimensions;
          state.width = width;
          state.height = height;
        }
        if (explicitPoster) {
          state.explicitPoster = explicitPoster;
          state.poster = poster;
        }
        state.autoplay = autoplay;
        state.controls = controls;
        state.keyboard = keyboard;
        state.loop = loop;
        state.playsinline = playsinline;
        state.muted = muted;
        state.interaction = interaction;
        state.pip = pip;
        state.type = MediaTypes.youtube;
        state.configured = true;
        state.youtube = clonedProviderConfig;
        state.mediaID = this.store.name;
        state.preventPauseOnViewportExit = preventPauseOnViewportExit;
        state.displayUIOverlay = false; // youtube has its own
      }),
    );
  }

  /**
   * Initialize from a Vimeo Configuration.
   *
   * @param configuration - the vimeo based configuration
   */
  private applyInitializeVimeo(configuration: IMedia) {
    // if no vimeo configuration then throw an error.
    if (!configuration.vimeo) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeVimeo',
        'No Vimeo configuration provided but this is required.',
        'config',
      );
    }

    // if no video id OR url then throw an error.
    if (!configuration.vimeo.id && !configuration.vimeo.url) {
      this.applyError('No vimeo "id" or "url" provided but this is required.');
      return;
    }

    // if the configuration comes with an explcit width and height then use that.
    // this allows the configuration to override the OEmbed Request width and height
    // which is useful for portrait videos.
    let explicitDimensions = false;
    let width: number | undefined = undefined;
    let height: number | undefined = undefined;
    const dimensionsAreNumbers = typeof configuration.width === 'number' && typeof configuration.height === 'number';
    const dimensionsAreValid = dimensionsAreNumbers && configuration.width > 0 && configuration.height > 0;
    if (dimensionsAreValid) {
      explicitDimensions = true;
      width = configuration.width;
      height = configuration.height;
    }

    // if the configuration comes with a specific poster then use that.
    let explicitPoster = false;
    const posterIsString = typeof configuration.poster === 'string';
    const posterIsUrl = posterIsString && configuration.poster.length > 0;
    let poster: string | undefined = undefined;
    if (posterIsUrl) {
      explicitPoster = true;
      poster = configuration.poster;
    }

    // interactive mode
    let interaction = true;
    if (configuration.interaction === false) {
      interaction = false;
    }

    // inject required parameters
    const clonedProviderConfig: MediaVimeoModel = cloneDeep(configuration.vimeo);

    // if the configuration for autoplay is provided
    if (clonedProviderConfig.autoplay === true || clonedProviderConfig.autoplay === false) {
      this.warnTopLevelProperty('vimeo.autoplay', 'autoplay');
    }
    let autoplay = false;
    if (configuration.autoplay === true) {
      clonedProviderConfig.autoplay = true;
      autoplay = true;
    } else {
      clonedProviderConfig.autoplay = false;
      autoplay = false;
    }

    // if the configuration for autopause is provided
    if (clonedProviderConfig.autopause === true || clonedProviderConfig.autopause === false) {
      clonedProviderConfig.autopause = undefined;
      this.warnDoNotUseProperty('vimeo.autopause');
    }

    // if the configuration for background is provided
    if (clonedProviderConfig.background === true || clonedProviderConfig.background === false) {
      clonedProviderConfig.background = undefined;
      this.warnDoNotUseProperty('vimeo.background');
    }

    // if the configuration for controls is provided
    if (clonedProviderConfig.controls === true || clonedProviderConfig.controls === false) {
      this.warnTopLevelProperty('vimeo.controls', 'controls');
    }
    // set the vimeo property using the top level provider
    let controls = true;
    if (configuration.controls === false || interaction === false) {
      clonedProviderConfig.controls = false;
      controls = false;
    } else {
      clonedProviderConfig.controls = true;
      controls = true;
    }

    // if the width is provided
    if (clonedProviderConfig.width) {
      clonedProviderConfig.width = undefined;
      this.warnDoNotUseProperty('vimeo.width');
    }

    // if the height is provided
    if (clonedProviderConfig.height) {
      clonedProviderConfig.height = undefined;
      this.warnDoNotUseProperty('vimeo.height');
    }

    // if the configuration for keyboard is provided
    if (clonedProviderConfig.keyboard === true || clonedProviderConfig.keyboard === false) {
      this.warnTopLevelProperty('vimeo.keyboard', 'keyboard');
    }
    // set the vimeo property using the top level provider
    let keyboard = false;
    if (configuration.keyboard === true && interaction === true) {
      clonedProviderConfig.keyboard = true;
      keyboard = true;
    } else {
      clonedProviderConfig.keyboard = false;
      keyboard = false;
    }

    // if loop has been provided
    if (clonedProviderConfig.loop === true || clonedProviderConfig.loop === false) {
      this.warnTopLevelProperty('vimeo.loop', 'loop');
    }
    // set the vimeo property using the top level provider
    let loop = false;
    if (configuration.loop === true) {
      clonedProviderConfig.loop = true;
      loop = true;
    } else {
      clonedProviderConfig.loop = false;
      loop = false;
    }

    // if max width has been provided
    if (clonedProviderConfig.maxwidth) {
      clonedProviderConfig.maxwidth = undefined;
      this.warnDoNotUseProperty('vimeo.maxwidth');
    }

    // if max height has been provided
    if (clonedProviderConfig.maxheight) {
      clonedProviderConfig.maxheight = undefined;
      this.warnDoNotUseProperty('vimeo.maxheight');
    }

    // if the configuration for playsinline is provided
    if (clonedProviderConfig.playsinline === true || clonedProviderConfig.playsinline === false) {
      this.warnTopLevelProperty('vimeo.playsinline', 'playsinline');
    }
    // set the vimeo property using the top level provider
    let playsinline = false;
    if (configuration.playsinline === true) {
      clonedProviderConfig.playsinline = true;
      playsinline = true;
    } else {
      clonedProviderConfig.playsinline = false;
      playsinline = false;
    }

    // the responsive property is hardcoded
    clonedProviderConfig.responsive = true;

    // muted
    if (clonedProviderConfig.muted === true || clonedProviderConfig.muted === false) {
      this.warnTopLevelProperty('vimeo.muted', 'muted');
    }
    let muted = false;
    if (configuration.muted === true) {
      clonedProviderConfig.muted = true;
      muted = true;
    } else {
      clonedProviderConfig.muted = false;
      muted = false;
    }

    // pip
    if (clonedProviderConfig.pip === true || clonedProviderConfig.pip === false) {
      this.warnTopLevelProperty('vimeo.pip', 'pip');
    }
    let pip = false;
    if (configuration.pip === true) {
      clonedProviderConfig.pip = true;
      pip = true;
    } else {
      clonedProviderConfig.pip = false;
      pip = false;
    }

    // prevent media pause when not intersecting
    let preventPauseOnViewportExit = false;
    if (configuration.preventPauseOnViewportExit === true) {
      preventPauseOnViewportExit = true;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        if (explicitDimensions) {
          state.explicitDimensions = explicitDimensions;
          state.width = width;
          state.height = height;
        }
        if (explicitPoster) {
          state.explicitPoster = explicitPoster;
          state.poster = poster;
        }
        state.autoplay = autoplay;
        state.controls = controls;
        state.keyboard = keyboard;
        state.loop = loop;
        state.playsinline = playsinline;
        state.muted = muted;
        state.pip = pip;
        state.interaction = interaction;
        state.type = MediaTypes.vimeo;
        state.configured = true;
        state.vimeo = clonedProviderConfig;
        state.mediaID = this.store.name;
        state.preventPauseOnViewportExit = preventPauseOnViewportExit;
        state.displayUIOverlay = true;
      }),
    );
  }

  /**
   * Initialize from a Video Configuration.
   *
   * @param configuration - the Video based configuration
   */
  private applyInitializeNativeVideo(configuration: IMedia) {
    // if no video configuration then throw an error.
    if (!configuration.video) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeNativeVideo',
        'No video configuration provided but this is required.',
        'config',
      );
    }

    // if no sources then throw an error.
    if (!configuration.video.sources || configuration.video.sources.length === 0) {
      this.applyError('No media "sources" provided but this is required.');
      return;
    }

    // if no width and height then that is an error
    const explicitDimensions = true;
    const dimensionsAreNumbers = typeof configuration.width === 'number' && typeof configuration.height === 'number';
    const dimensionsAreValid = dimensionsAreNumbers && configuration.width > 0 && configuration.height > 0;
    if (!dimensionsAreValid) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeNativeVideo',
        'No media "width" and "height" provided but this is required.',
        'config',
      );
    }
    const width = configuration.width;
    const height = configuration.height;

    // if the configuration comes with a specific poster then use that.
    let explicitPoster = false;
    const posterIsString = typeof configuration.poster === 'string';
    const posterIsUrl = posterIsString && configuration.poster.length > 0;
    let poster: string | undefined = undefined;
    if (posterIsUrl) {
      explicitPoster = true;
      poster = configuration.poster;
    }

    let preload = false;
    if (configuration.video.preload === true) {
      preload = true;
    }

    const sources = configuration.video.sources;

    // interactive mode
    let interaction = true;
    if (configuration.interaction === false) {
      interaction = false;
    }

    // autoplay
    let autoplay = false;
    if (configuration.autoplay === true) {
      autoplay = true;
    }

    // controls
    let controls = true;
    if (configuration.controls === false || interaction === false) {
      controls = false;
    }

    // keyboard
    const keyboard = false;

    // loop
    let loop = false;
    if (configuration.loop === true) {
      loop = true;
    }

    // plays inline
    let playsinline = false;
    if (configuration.playsinline === true) {
      playsinline = true;
    }

    // muted
    let muted = false;
    if (configuration.muted === true) {
      muted = true;
    }

    // pip
    let pip = true;
    if (configuration.pip === false) {
      pip = false;
    }

    // clone the video configuration
    const clonedProviderConfig: MediaNativeVideoModel = {
      preload,
      sources,
      autoplay,
      controls,
      loop,
      playsinline,
      muted,
      pip,
      poster,
    };

    // prevent media pause when not intersecting
    let preventPauseOnViewportExit = false;
    if (configuration.preventPauseOnViewportExit === true) {
      preventPauseOnViewportExit = true;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        if (explicitDimensions) {
          state.explicitDimensions = explicitDimensions;
          state.width = width;
          state.height = height;
        }
        if (explicitPoster) {
          state.explicitPoster = explicitPoster;
          state.poster = poster;
        }
        state.autoplay = autoplay;
        state.controls = controls;
        state.keyboard = keyboard;
        state.loop = loop;
        state.playsinline = playsinline;
        state.muted = muted;
        state.pip = pip;
        state.interaction = interaction;
        state.type = MediaTypes.video;
        state.configured = true;
        state.video = clonedProviderConfig;
        state.mediaID = this.store.name;
        state.preventPauseOnViewportExit = preventPauseOnViewportExit;
        state.displayUIOverlay = true;
      }),
    );
  }

  /**
   * Initialize from a Audio Configuration.
   *
   * @param configuration - the Audio based configuration
   */
  private applyInitializeNativeAudio(configuration: IMedia) {
    // if no audio configuration then throw an error.
    if (!configuration.audio) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeNativeAudio',
        'No Audio configuration provided but this is required.',
        'config',
      );
    }

    // if no sources then throw an error.
    if (!configuration.audio.sources || configuration.audio.sources.length === 0) {
      this.applyError('No media "sources" provided but this is required.');
      return;
    }

    // if no width and height then that is an error
    const explicitDimensions = true;
    const dimensionsAreNumbers = typeof configuration.width === 'number' && typeof configuration.height === 'number';
    const dimensionsAreValid = dimensionsAreNumbers && configuration.width > 0 && configuration.height > 0;
    if (!dimensionsAreValid) {
      throw createDigitaServiceError(
        'MediaStore',
        'applyInitializeNativeAudio',
        'No media "width" and "height" provided but this is required.',
        'config',
      );
    }
    const width = configuration.width;
    const height = configuration.height;

    // if the configuration comes with a specific poster then use that.
    let explicitPoster = false;
    const posterIsString = typeof configuration.poster === 'string';
    const posterIsUrl = posterIsString && configuration.poster.length > 0;
    let poster: string | undefined = undefined;
    if (posterIsUrl) {
      explicitPoster = true;
      poster = configuration.poster;
    }

    // interactive mode
    let interaction = true;
    if (configuration.interaction === false) {
      interaction = false;
    }

    let preload = false;
    if (configuration.audio.preload === true) {
      preload = true;
    }

    let audioposter: undefined | string = undefined;
    if (configuration.audio.audioposter) {
      audioposter = configuration.audio.audioposter;
    }

    const sources = configuration.audio.sources;

    // autoplay
    let autoplay = false;
    if (configuration.autoplay === true) {
      autoplay = true;
    }

    // controls
    let controls = true;
    if (configuration.controls === false || interaction === false) {
      controls = false;
    }

    // keyboard
    const keyboard = false;

    // loop
    let loop = false;
    if (configuration.loop === true) {
      loop = true;
    }

    // plays inline is not supported on audio
    const playsinline = false;

    // muted
    let muted = false;
    if (configuration.muted === true) {
      muted = true;
    }

    // pip is not supported on audio
    const pip = false;

    // clone the audio configuration
    const clonedProviderConfig: MediaNativeAudioModel = {
      preload,
      audioposter,
      sources,
      autoplay,
      controls,
      loop,
      muted,
    };

    // prevent media pause when not intersecting
    let preventPauseOnViewportExit = false;
    if (configuration.preventPauseOnViewportExit === true) {
      preventPauseOnViewportExit = true;
    }

    // update the store

    this.store.update(
      ElfWrite((state) => {
        if (explicitDimensions) {
          state.explicitDimensions = explicitDimensions;
          state.width = width;
          state.height = height;
        }
        if (explicitPoster) {
          state.explicitPoster = explicitPoster;
          state.poster = poster;
        }
        state.autoplay = autoplay;
        state.controls = controls;
        state.keyboard = keyboard;
        state.loop = loop;
        state.playsinline = playsinline;
        state.muted = muted;
        state.pip = pip;
        state.interaction = interaction;
        state.type = MediaTypes.audio;
        state.configured = true;
        state.audio = clonedProviderConfig;
        state.mediaID = this.store.name;
        state.preventPauseOnViewportExit = preventPauseOnViewportExit;
        state.displayUIOverlay = true;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STATES
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The total duration of the video in seconds
   *
   * @param duration - the duration of the video in seconds
   */
  applyDuration(duration: number) {
    this.store.update(
      ElfWrite((state) => {
        state.duration = duration;
      }),
    );
  }

  /**
   * The current time of the video in seconds.
   *
   * @param currentTime - the current time of the video in seconds
   */
  applyCurrentTime(currentTime: number) {
    this.store.update(
      ElfWrite((state) => {
        state.currentTime = currentTime;
      }),
    );
  }

  /**
   * The video is ready to play.
   */
  applyReady() {
    this.store.update(
      ElfWrite((state) => {
        state.ready = true;
      }),
    );
  }

  /**
   * The video is Playing.
   */
  applyPlaying() {
    this.store.update(
      ElfWrite((state) => {
        state.playing = true;
        state.paused = false;
        state.ended = false;
        state.hasMediaPlayed = true;
      }),
    );
  }

  /**
   * The video is Paused.
   */
  applyPaused() {
    this.store.update(
      ElfWrite((state) => {
        state.playing = false;
        state.paused = true;
        state.ended = false;
      }),
    );
  }

  /**
   * The Video is Ended.
   */
  applyEnded() {
    const { loop } = this.store.getValue();
    if (!loop) {
      this.store.update(
        ElfWrite((state) => {
          state.ended = true;
        }),
      );
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // INITIALIZED
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * When the system has been configured, and loaded the required providers and video information (where applicable)
   * then the system is considered initialized and is ready to create the player.
   */
  applyInitialized() {
    const { intersecting } = this.store.getValue();
    this.store.update(
      ElfWrite((state) => {
        state.initialized = true;
        if (intersecting) {
          state.hasIntersectedPreviously = true;
        }
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // VIDEO DETAILS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Once the video details have been obtained from the YouTube OEmbed Service, it will contain
   * the width and height of the video and the poster.
   *
   * If width, height or poster have been explicitly set, then the values from the OEmbed
   * service will be ignored.
   *
   * @param details - the details of the video
   */
  applyYouTubeVideoDetails(details: YT.OEmbed) {
    const { explicitDimensions, explicitPoster } = this.store.getValue();
    this.store.update(
      ElfWrite((state) => {
        state.detailsObtained = true;
        if (!explicitPoster) {
          state.poster = details.thumbnail_url;
        }
        if (!explicitDimensions) {
          state.width = details.width;
          state.height = details.height;
        }
      }),
    );
  }

  /**
   * Once the video details have been obtained from the Vimeo OEmbed Service, it will contain
   * the width and height of the video and the poster.
   *
   * If width, height or poster have been explicitly set, then the values from the OEmbed
   * service will be ignored.
   *
   * @param details - the details of the video
   */
  applyVimeoVideoDetails(details: Vimeo.Ombed) {
    const { explicitDimensions, explicitPoster } = this.store.getValue();
    this.store.update(
      ElfWrite((state) => {
        state.detailsObtained = true;
        if (!explicitPoster) {
          state.poster = details.thumbnail_url;
        }
        if (!explicitDimensions) {
          state.width = details.width;
          state.height = details.height;
        }
      }),
    );
  }

  /**
   * All Details for a Media Native Video Component have been obtained already.
   */
  applyNativeVideoDetails() {
    this.store.update(
      ElfWrite((state) => {
        state.detailsObtained = true;
      }),
    );
  }

  /**
   * All Details for a Media Native Audio Component have been obtained already.
   */
  applyNativeAudioDetails() {
    this.store.update(
      ElfWrite((state) => {
        state.detailsObtained = true;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // ERROR
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The system has encountered an error.
   *
   * This can be because:
   *
   *   - the video source failed to load (e.g. youtube video id is invalid)
   *
   * @param reason - the reason for the error
   */
  applyError(reason?: string) {
    if (reason) {
      this.loggerService.error(`MediaComponent - ${reason}`);
    }

    const { width, height } = this.store.getValue();

    this.store.update(
      ElfWrite((state) => {
        state.initialized = false;
        state.error = true;
        state.errorReason = 'Error';
        state.width = width || 640;
        state.height = height || 360;
      }),
    );
  }

  /**
   * Is the component intersecting the viewport?
   *
   * @param isVisible - is the component intersecting?
   */
  applyIsIntersecting(isVisible: boolean) {
    const { initialized } = this.store.getValue();
    this.store.update(
      ElfWrite((state) => {
        state.intersecting = isVisible;
        if (isVisible && initialized) {
          state.hasIntersectedPreviously = true;
        }
      }),
    );
  }

  /**
   * Utility
   */
  private warnTopLevelProperty(propertyName: string, topLevelPropertyName: string) {
    this.loggerService.warn(
      `MediaComponent - The ${propertyName} property should not be used. Instead use the top level ${topLevelPropertyName} property of the Media API. Your setting has been ignored.`,
    );
  }

  /**
   * Utility
   */
  private warnDoNotUseProperty(propertyName: string) {
    this.loggerService.warn(
      `MediaComponent - The ${propertyName} property should not be used, it is either not supported or managed by the Media API. Your setting has been ignored.`,
    );
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERY
  ////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured. This means a configuration has been passed to the
   * component and has been successfully parsed and validated.
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    delay(100),
  );

  /**
   * What is the type of the Media?
   */
  private _type$ = this.store.pipe(select((state) => state.type));

  /**
   * Once the Media has been configured, we can get the type from the configuration as it
   * would have been set.
   */
  configuredType$ = this._configured$.pipe(
    filter((configured) => configured),
    switchMap(() => {
      return this._type$;
    }),
  );

  /**
   * Has an error occurred?
   */
  error$ = this.store.pipe(select((state) => state.error));

  /**
   * What is the reason for the error?
   */
  errorReason$ = this.store.pipe(select((state) => state.errorReason));

  /**
   * The Error Icon to display if things go wrong.
   */
  errorIcon$ = this.store.pipe(select((state) => state.errorIcon));

  /**
   * Once the Media has stopped initializing it becomes initialized.
   */
  initialized$ = this.store.pipe(select((state) => state.initialized));

  /**
   * The system is initializing when any of the following is true:
   *
   *    1. The Media is not configured with a JSON configuration.
   *    2. There is an error
   */
  initializing$ = ElfCombineQueries([this.initialized$, this.error$]).pipe(map(([initialized, error]) => !(initialized || error)));

  /**
   * This is true then the system is initializing and an error occurs.
   */
  initializingError$ = ElfCombineQueries([this.initialized$, this.error$]).pipe(map(([initialized, error]) => !initialized && error));

  //////////////////////////////////////////////////////////////////////////////////
  // PLAYBACK STATUS
  //////////////////////////////////////////////////////////////////////////////////

  // is the media ready to play?
  private _isReady$ = this.store.pipe(select((state) => state.ready));

  /**
   * Is the player currently ready? This is the case if:
   *
   *    1. The media is initialized
   *    2. The media is ready
   */
  ready$ = ElfCombineQueries([this.initialized$, this._isReady$, this.error$]).pipe(
    switchMap(([initialized, ready, error]) => {
      if (initialized && ready) {
        if (!error) {
          return of(true);
        }
      }
      return EMPTY;
    }),
    // the system can only be ready once.
    take(1),
  );

  /**
   * The Media ID of this media. This is used with {@link MediaManagerService} to
   * determine which media is currently playing globally within the app.
   */
  private _mediaID$ = this.store.pipe(select((state) => state.mediaID));

  /**
   * Is the media currently playing?
   */
  private _mediaPlaying$ = this.store.pipe(select((state) => state.playing));

  /**
   * Is the media currently playing? This is true if:
   *
   *   1. The media is initialized and contains an ID
   *   2. The media is playing
   *
   * It returns both pieces of data.
   */
  playing$ = ElfCombineQueries([this._mediaID$, this._mediaPlaying$]).pipe(
    switchMap(([mediaID, playing]) => {
      return of({
        mediaID,
        playing,
      });
    }),
  );

  /**
   * Has the media ended?
   */
  ended$ = this.store.pipe(select((state) => state.ended));

  /**
   * Is the media currently paused?
   */
  private _paused$ = this.store.pipe(select((state) => state.paused));

  /**
   * Is the media currently paused?
   *
   * Note that this
   */
  paused$ = ElfCombineQueries([this.ended$, this._paused$]).pipe(
    switchMap(([ended, paused]) => {
      // if the media is paused
      if (paused) {
        // and if the media is not ended then yes it's paused
        // if the media has ended, then the pause animation state
        // in media-ui should not be displayed.
        if (!ended) {
          return of(true);
        }
      }
      return of(false);
    }),
  );

  /**
   * The Duration of the media in seconds.
   *
   * (How long the total length of the media is)
   */
  duration$ = this.store.pipe(select((state) => state.duration));

  /**
   * The Current Time of the media in seconds.
   *
   * (How much of the video length has passed).
   */
  currentTime$ = this.store.pipe(select((state) => state.currentTime));

  /**
   * Return the progress of playback as a percentage.
   */
  progress$ = ElfCombineQueries([this.currentTime$, this.duration$]).pipe(
    switchMap(([currentTime, duration]) => {
      if (currentTime && duration > 0) {
        return of(Math.round((currentTime / duration) * 100));
      }
      return of(0);
    }),
  );

  //////////////////////////////////////////////////////////////////////////////////
  // CONFIGURED OPTIONS
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the media configured with controls?
   */
  private _controls$ = this.store.pipe(select((state) => state.controls));

  /**
   * Does the media have controls? This is used specifically by the {@link MediaVimeoComponent}
   * to apply a small div on top of the video to allow the user to click on it to play or pause
   * the media.
   */
  controls$ = ElfCombineQueries([this._isReady$, this._controls$]).pipe(
    switchMap(([ready, controls]) => {
      if (ready && controls) {
        return of(true);
      }
      return of(false);
    }),
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // MEDIA DETAILS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Have details been obtained? This is primary used for streaming providers such as
   * YouTube and Vimeo. Because these services require an API call to obtain the details
   * of the media. This is also used for Native Video and Audio but in those cases the
   * value is always true since the data for those is provided upfront during configuration.
   */
  private _detailsObtained$ = this.store.pipe(select((state) => state.detailsObtained));

  /**
   * The Poster of the Media. This is optional.
   */
  private _poster = this.store.pipe(select((state) => state.poster));

  /**
   * The Width of the Media.
   */
  private _width$ = this.store.pipe(select((state) => state.width));

  /**
   * The Height of the Media.
   */
  private _height$ = this.store.pipe(select((state) => state.height));

  //////////////////////////////////////////////////////////////////////////////////
  // TEMPLATE CONDITIONALS
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the Media intersecting with the DOM?
   */
  private _intersecting$ = this.store.pipe(select((state) => state.intersecting));

  /**
   * Has the Media previously intersected with the DOM?
   */
  private _hasIntersectedPreviously$ = this.store.pipe(select((state) => state.hasIntersectedPreviously));

  /**
   * Should media be rendered? This occurs if both the following is true:
   *
   *    1. The media is initialized.
   *    2. The media has previously intersected with the DOM.
   */
  private _shouldMediaRender$ = ElfCombineQueries([this.initialized$, this._hasIntersectedPreviously$]).pipe(
    switchMap(([initialized, hasIntersectedPreviously]) => {
      if (initialized && hasIntersectedPreviously) {
        return of(true);
      }
      return of(false);
    }),
  );

  /**
   * Should the YouTube player be rendered? This occurs if both the following is true:
   *
   *    1. The media is configured to be a YouTube video.
   *    2. The media should be rendered.
   */
  shouldRenderYouTube$ = ElfCombineQueries([this.configuredType$, this._shouldMediaRender$]).pipe(
    switchMap(([configuredType, shouldMediaRender]) => {
      if (configuredType === MediaTypes.youtube && shouldMediaRender) {
        return of(true);
      }
      return of(false);
    }),
  );

  /**
   * Should the Vimeo player be rendered? This occurs if both the following is true:
   *
   *    1. The media is configured to be a Vimeo video.
   *    2. The media should be rendered.
   */
  shouldRenderVimeo$ = ElfCombineQueries([this.configuredType$, this._shouldMediaRender$]).pipe(
    switchMap(([configuredType, shouldMediaRender]) => {
      if (configuredType === MediaTypes.vimeo && shouldMediaRender) {
        return of(true);
      }
      return of(false);
    }),
  );

  /**
   * Should the Native Video player be rendered? This occurs if both the following is true:
   *
   *    1. The media is configured to be a native Video.
   *    2. The media should be rendered.
   */
  shouldRenderVideo$ = ElfCombineQueries([this.configuredType$, this._shouldMediaRender$]).pipe(
    switchMap(([configuredType, shouldMediaRender]) => {
      if (configuredType === MediaTypes.video && shouldMediaRender) {
        return of(true);
      }
      return of(false);
    }),
  );

  /**
   * Should the Audio player be rendered? This occurs if both the following is true:
   *
   *    1. The media is configured to be a Native Audio.
   *    2. The media should be rendered.
   */
  shouldRenderAudio$ = ElfCombineQueries([this.configuredType$, this._shouldMediaRender$]).pipe(
    switchMap(([configuredType, shouldMediaRender]) => {
      if (configuredType === MediaTypes.audio && shouldMediaRender) {
        return of(true);
      }
      return of(false);
    }),
  );

  /**
   * What is the aspect ratio of the Media? This occurs when the width and height are both provided
   * otherwise 0 is returned.
   */
  aspectRatio$ = ElfCombineQueries([this._width$, this._height$]).pipe(
    switchMap(([width, height]) => {
      if (typeof width === 'number' && typeof height === 'number') {
        if (width > 0 && height > 0) {
          return of(width / height);
        }
      }
      return of(0);
    }),
  );

  /**
   * Determine if the Media is Portrait. This is known when:
   *
   *   1. The Media Details have been obtained.
   *   2. The Media Height is greater than the Media Width.
   */
  isMediaPortrait$ = ElfCombineQueries([this._detailsObtained$, this._width$, this._height$]).pipe(
    switchMap(([detailsObtained, width, height]) => {
      if (detailsObtained) {
        if (height > width) {
          return of(true);
        }
      }
      return of(false);
    }),
  );

  /**
   * From the Media Observer, get the current breakpoint.
   */
  private _mediaObserver$ = this.mediaObserver.asObservable().pipe(filter((changes: MediaChange[]) => changes.length > 0));

  /**
   * Using the Media Observer and the Media Portrait, determine if the Media should be limited in width on desktop to prevent
   * portrait videos being too big to fit on horizontal screens.
   */
  limitPortraitWidthOnDesktop$ = ElfCombineQueries([this._mediaObserver$, this.isMediaPortrait$]).pipe(
    switchMap(([changes, isMediaPortrait]) => {
      // if the media is not portrait then this doesn't apply
      if (!isMediaPortrait) {
        return of(false);
      }

      // get the first change
      const targetChange = changes[0];

      // if the media alias is not xs then we want to limit the width
      if (targetChange.mqAlias !== 'xs') {
        return of(true);
      }

      return of(false);
    }),
  );

  // a poster (if available) can only be shown when no errors exist.
  private _shouldPosterBeDisplayed = ElfCombineQueries([this.error$, this._poster]);

  /**
   * Is there a poster for this media?
   */
  hasPoster$ = this._detailsObtained$.pipe(
    filter((details) => details),
    switchMap(() => {
      return this._shouldPosterBeDisplayed;
    }),
    switchMap(([error, poster]) => {
      if (!error && poster) {
        return of(true);
      }
      return of(false);
    }),
  );

  /**
   * The Poster Image of the Media. Returned as a CSS background image.
   */
  poster$ = this.hasPoster$.pipe(
    filter((hasPoster) => hasPoster),
    switchMap(() => {
      return this._poster;
    }),
    switchMap((poster) => {
      return of(`url("${poster}")`);
    }),
  );

  //////////////////////////////////////////////////////////////////////////////////
  // YOUTUBE PROVIDER
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * The YouTube Settings configured for the Media.
   */
  private _youTubeSettings$ = this.store.pipe(select((state) => state.youtube));

  /**
   * If the Media is YouTube, return the YouTube Settings.
   */
  youtubeSettings$ = this.configuredType$.pipe(
    switchMap((type) => {
      if (type === MediaTypes.youtube) {
        return this._youTubeSettings$;
      }
      return EMPTY;
    }),
  );

  //////////////////////////////////////////////////////////////////////////////////
  // VIMEO PROVIDER
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * The Vimeo Settings configured for the Media.
   */
  private _vimeoSettings$ = this.store.pipe(select((state) => state.vimeo));

  /**
   * If the Media is Vimeo, return the Vimeo Settings.
   */
  vimeoSettings$ = this.configuredType$.pipe(
    switchMap((type) => {
      if (type === MediaTypes.vimeo) {
        return this._vimeoSettings$;
      }
      return EMPTY;
    }),
  );

  //////////////////////////////////////////////////////////////////////////////////
  // NATIVE PROVIDER
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * The Native Video Settings configured for the Media.
   */
  private _videoSettings$ = this.store.pipe(select((state) => state.video));

  /**
   * If the Media Video is Native, return the Native Settings.
   */
  videoSettings$ = this.configuredType$.pipe(
    switchMap((type) => {
      if (type === MediaTypes.video) {
        return this._videoSettings$;
      }
      return EMPTY;
    }),
  );

  /**
   * The Native Audio Settings configured for the Media.
   */
  private _audioSettings$ = this.store.pipe(select((state) => state.audio));

  /**
   * If the Media Audio is Native, return the Native Settings.
   */
  audioSettings$ = this.configuredType$.pipe(
    switchMap((type) => {
      if (type === MediaTypes.audio) {
        return this._audioSettings$;
      }
      return EMPTY;
    }),
  );

  /**
   * If it's a Audio Source is there is a poster?
   */
  audioPoster$ = this.audioSettings$.pipe(
    switchMap((settings) => {
      if (settings?.audioposter) {
        return of(settings.audioposter);
      }
      return EMPTY;
    }),
  );

  //////////////////////////////////////////////////////////////////////////////////
  // MEDIA UI
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the media interactive which means the user can interact with it?
   */
  private _interaction$ = this.store.pipe(select((state) => state.interaction));

  /**
   * Have interactions been disabled? The opposite of interactive.
   */
  interactionDisabled$ = this._interaction$.pipe(
    switchMap((interaction) => {
      if (interaction) {
        return of(false);
      }
      return of(true);
    }),
  );

  /**
   * Should drimify custom UI be displayed?
   */
  private _displayUIOverlay$ = this.store.pipe(select((state) => state.displayUIOverlay));

  /**
   * Does the size of the component meet the minimum requirements to display the UI? For example
   * this prevents a thin audio player from being intersected by the UI.
   */
  private _displayUIMinimumSize$ = ElfCombineQueries([this._width$, this._height$]).pipe(
    switchMap(([width, height]) => {
      if (width > 179 && height > 179) {
        return of(true);
      }
      return of(false);
    }),
  );

  /**
   * Should the Media UI be displayed? This is never the case for YouTube which has it's own UI
   * at all times.
   */
  displayUIOverlay$ = ElfCombineQueries([this._displayUIOverlay$, this._displayUIMinimumSize$]).pipe(
    switchMap(([displayUIOverlay, displayUIMinimumSize]) => {
      if (displayUIOverlay && displayUIMinimumSize) {
        return of(true);
      }
      return of(false);
    }),
  );

  /**
   * Has the media previously played?
   */
  private _hasMediaPlayed$ = this.store.pipe(select((state) => state.hasMediaPlayed));

  /**
   * Should the Media UI Play Button be displayed?
   */
  displayUIPlayButton$ = ElfCombineQueries([
    this.error$,
    this._isReady$,
    this.displayUIOverlay$,
    this._hasMediaPlayed$,
    this.interactionDisabled$,
  ]).pipe(
    switchMap(([error, isReady, displayUIOverlay, hasMediaPlayed, interactionDisabled]) => {
      if (isReady && displayUIOverlay && !hasMediaPlayed) {
        if (!error && !interactionDisabled) {
          return of(true);
        }
      }
      return of(false);
    }),
  );

  /**
   * Should the Media UI enable a click zone to play the media?
   */
  displayUIControlZone$ = ElfCombineQueries([this.error$, this._isReady$, this.displayUIOverlay$, this.controls$, this._interaction$]).pipe(
    switchMap(([error, isReady, displayUIOverlay, controls, interaction]) => {
      if (!error && isReady && displayUIOverlay) {
        if (!controls && interaction) {
          return of(true);
        }
      }
      return of(false);
    }),
  );

  //////////////////////////////////////////////////////////////////////////////////
  // MEDIA MANAGER
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * By default, media will pause when the media is no longer intersecting, this
   * can be overridden with this property.
   */
  private preventPauseOnViewportExit$ = this.store.pipe(select((state) => state.preventPauseOnViewportExit));

  /**
   * When a Media Item is no longer intersecting with the viewport, should that media
   * be paused?
   */
  shouldPauseWhenNoLongerIntersecting = ElfCombineQueries([this.preventPauseOnViewportExit$, this._intersecting$]).pipe(
    switchMap(([preventMediaPauseWhenNotIntersecting, intersecting]) => {
      // if the media is intersecting
      if (intersecting === true) {
        // then it shouldn't pause
        return of(false);
      } else {
        // if the media is not intersecting
        // and specifically if the default behavior has been disabled
        if (preventMediaPauseWhenNotIntersecting === true) {
          // then no it shouldn't be paused.
          return of(false);
        }
      }

      // otherwise it should.
      return of(true);
    }),
  );

  /**
   * Pause the Media if it is not the active Media.
   */
  pauseAsNotActiveMedia$ = ElfCombineQueries([this._isReady$, this.playing$, this.mediaManagerRepository.activeMediaID$]).pipe(
    switchMap(([ready, playing, activeMediaID]) => {
      // if the media is ready and playing but the active media is a different media
      if (ready && playing?.playing && playing.mediaID !== activeMediaID) {
        // then the media should be paused
        return of(true);
      }
      return of(false);
    }),
    filter((pause) => pause),
  );
}
