import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { IDataboxTimer } from 'src/app/api/modules/core/dynamic/databoxes/timer/IDataboxTimer';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DataboxTimerModel } from './databox-timer.model';

/**
 * The Default State
 */
function initialState(): DataboxTimerModel {
  return {
    selector: 'app-databox-timer',
    template: ``,
    templateIdle: '',
    output: '',
    hoursIsUsed: true,
    minsIsUsed: true,
    secsIsUsed: true,
  };
}

/**
 * The Store used for a {@link DataboxTimerComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DataboxTimerRepository implements OnDestroy {
  /**
   * The HOUR String Template variable which is replaced by the users actual time.
   */
  private readonly HOURS = '%H%';

  /**
   * The MINUTES String Template variable which is replaced by the users actual time.
   */
  private readonly MINUTES = '%M%';

  /**
   * The SECONDS String Template variable which is replaced by the users actual time.
   */
  private readonly SECONDS = '%S%';

  /**
   * The store.
   */
  private store = createStore(
    {
      name: `databox-timer-${uniqueId()}`,
    },
    withProps<DataboxTimerModel>(initialState()),
  );

  constructor(private readonly logger: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////
  /**
   * Initialize from the configuration.
   */
  applyInitialize(configuration?: Partial<IDataboxTimer>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    let hoursIsUsed = false;
    let minsIsUsed = false;
    let secsIsUsed = false;

    // the template is used to process the numeric values into a formatted time string.
    let template = `${this.HOURS}:${this.MINUTES}:${this.SECONDS}`;
    if (configuration.template) {
      if (configuration.template.includes(this.HOURS)) {
        hoursIsUsed = true;
      }
      if (configuration.template.includes(this.MINUTES)) {
        minsIsUsed = true;
      }
      if (configuration.template.includes(this.SECONDS)) {
        secsIsUsed = true;
      }

      if (!hoursIsUsed && !minsIsUsed && !secsIsUsed) {
        this.logger.warn(
          `[DataboxTimer] applyInitialize - you must use a %H%, %M%, %S% or some combination of these in your template. The default will be used.`,
        );
        hoursIsUsed = true;
        minsIsUsed = true;
        secsIsUsed = true;
      } else {
        template = configuration.template;
      }
    }

    // template idle is shown when no acceptable values have been provided
    // or as the default state or during a reset.
    let templateIdle = `--:--:--`;
    if (configuration.templateIdle) {
      templateIdle = configuration.templateIdle;
    }

    // the output should be the templateIdle as no values are incoming yet.
    const output = templateIdle;

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.template = template;
        state.templateIdle = templateIdle;
        state.hoursIsUsed = hoursIsUsed;
        state.minsIsUsed = minsIsUsed;
        state.secsIsUsed = secsIsUsed;
        state.output = output;
      }),
    );
  }

  /**
   * Splice the data into the template
   *
   * @param seconds - the gametime in seconds which will be formatted to the template
   */
  applyUpdate(seconds?: number) {
    // get the configured data
    const { hoursIsUsed, minsIsUsed, secsIsUsed } = this.store.getValue();

    // if no seconds were provided, then do nothing
    if (typeof seconds !== 'number') {
      this.logger.info('[DataboxTimer] applyUpdate - No seconds value was provided for processing. Update ignored.');
      return;
    }

    // the timer may not be negative.
    if (seconds < 0) {
      seconds = 0;
    }

    // this will be displayed in the dom after successful processing
    let finalOutput = '';

    // process depending on the template string
    if (hoursIsUsed && minsIsUsed && secsIsUsed) {
      finalOutput = this.secondstohhmmss(seconds);
    } else if (!hoursIsUsed && minsIsUsed && secsIsUsed) {
      finalOutput = this.secondstommss(seconds);
    } else if (!hoursIsUsed && !minsIsUsed && secsIsUsed) {
      finalOutput = this.secondstoss(seconds);
    }

    // if the final output is empty, there was an issue with the logic.
    if (finalOutput.length === 0) {
      this.logger.error('[DataboxTimer] applyUpdate - The output string could not be processed and has been misconfigured.');
      return;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.output = finalOutput;
      }),
    );
  }

  /**
   * Reset the ouput to the templateIdle value.
   */
  applyReset() {
    const { templateIdle } = this.store.getValue();
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.output = templateIdle;
      }),
    );
  }

  /**
   * Convert seconds to hh:mm:ss format.
   */
  private secondstohhmmss(seconds: number) {
    // get the template from the store
    const { template } = this.store.getValue();

    // copy the template string
    let templateCopy = template.slice();

    // do the maths
    let hoursValue = '00';
    let minutesValue = '00';
    let secondsValue = '00';

    const roundedHours = Math.floor(seconds / 3600);
    const roundedMinutes = Math.floor((seconds - roundedHours * 3600) / 60);
    let roundedSeconds = seconds - roundedHours * 3600 - roundedMinutes * 60;
    roundedSeconds = Math.round(roundedSeconds * 100) / 100;

    hoursValue = roundedHours.toString();
    minutesValue = roundedMinutes.toString();
    secondsValue = roundedSeconds.toString();

    if (roundedHours < 10) {
      hoursValue = `0${hoursValue}`;
    }
    if (roundedMinutes < 10) {
      minutesValue = `0${minutesValue}`;
    }
    if (roundedSeconds < 10) {
      secondsValue = `0${secondsValue}`;
    }

    templateCopy = templateCopy.replace(this.HOURS, hoursValue);
    templateCopy = templateCopy.replace(this.MINUTES, minutesValue);
    templateCopy = templateCopy.replace(this.SECONDS, secondsValue);

    return templateCopy;
  }

  /**
   * Convert seconds to mm:ss format.
   */
  private secondstommss(seconds: number) {
    // get the template from the store
    const { template } = this.store.getValue();

    // copy the template string
    let templateCopy = template.slice();

    // do the maths
    let minutesValue = '00';
    let secondsValue = '00';

    const roundedMinutes = Math.floor(seconds / 60);
    let roundedSeconds = seconds - roundedMinutes * 60;
    roundedSeconds = Math.round(roundedSeconds * 100) / 100;

    minutesValue = roundedMinutes.toString();
    secondsValue = roundedSeconds.toString();

    if (roundedMinutes < 10) {
      minutesValue = `0${minutesValue}`;
    }
    if (roundedSeconds < 10) {
      secondsValue = `0${secondsValue}`;
    }

    templateCopy = templateCopy.replace(this.MINUTES, minutesValue);
    templateCopy = templateCopy.replace(this.SECONDS, secondsValue);

    return templateCopy;
  }

  /**
   * Convert seconds to ss format.
   */
  private secondstoss(seconds: number) {
    // get the template from the store
    const { template } = this.store.getValue();

    // copy the template string
    const templateCopy = template.slice();

    // do the maths
    let templateValue = '0';
    const roundedSeconds = Math.round(seconds * 100) / 100;
    templateValue = roundedSeconds.toString();

    // return the processed template
    return templateCopy.replace(this.SECONDS, templateValue);
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * The Final Output State.
   */
  output$ = this.store.pipe(select((state) => state.output));
}
