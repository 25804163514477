import { Injectable, OnDestroy } from '@angular/core';
import { TextAnimationTypes } from '@digitaservice/utils';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { filter, map } from 'rxjs';
import { IPluginTextEntity } from 'src/app/api/modules/core/dynamic/plugin/components/IPluginTextEntity';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { PluginTextEntityModel } from './plugin-text-entity.model';

/**
 * The Default State
 */
function initialState(): PluginTextEntityModel {
  return {
    configured: false,
    complete: false,
    text: '',
    type: TextAnimationTypes.slideInOutFromLeft,
    positionX: '50%',
    positionY: '50%',
    displayDuration: 1000,
    enter: undefined,
    exit: undefined,
  };
}

/**
 * The Store used for a {@link PluginTextEntityComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class PluginTextEntityRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `plugin-text-entity-${uniqueId()}`,
    },
    withProps<PluginTextEntityModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////
  /**
   * Initializes the store's state with an optional configuration object.
   * Merges the default state with the provided configuration, updating only the properties that are set.
   *
   * @param configuration An optional configuration object that conforms to IPluginTextEntity.
   */
  applyInitialize(configuration?: IPluginTextEntity) {
    const defaultConfig = this.store.getValue();

    // text
    let text = defaultConfig.text;
    if (configuration?.text) {
      text = configuration.text;
    }

    // display duration
    let displayDuration = defaultConfig.displayDuration;
    if (configuration?.displayDuration) {
      displayDuration = configuration.displayDuration;
    }

    // position x
    let positionX = defaultConfig.positionX;
    if (configuration?.positionX) {
      positionX = configuration.positionX;
    }

    // position y
    let positionY = defaultConfig.positionY;
    if (configuration?.positionY) {
      positionY = configuration.positionY;
    }

    // type
    let type = defaultConfig.type;
    if (configuration?.type) {
      type = configuration.type;
    }

    // enter
    let enter = defaultConfig.enter;
    if (configuration?.enter) {
      enter = configuration.enter;
    }

    // exit
    let exit = defaultConfig.exit;
    if (configuration?.exit) {
      exit = configuration.exit;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.displayDuration = displayDuration;
        state.text = text;
        state.positionX = positionX;
        state.positionY = positionY;
        state.type = type;
        state.enter = enter;
        state.exit = exit;
      }),
    );
  }

  /**
   * Marks the text animation as complete by setting the `complete` state property to true.
   */
  applyComplete() {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.complete = true;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the screen configured?
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((configured) => configured),
  );

  /**
   * The text to display.
   */
  private _text = this.store.pipe(select((state) => state.text));

  /**
   * The text to display.
   */
  text$ = ElfCombineQueries([this._configured$, this._text]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, text]) => {
      return text;
    }),
  );

  /**
   * The x position of the text entity
   */
  private _positionX$ = this.store.pipe(select((state) => state.positionX));

  /**
   * The y position of the text entity
   */
  private _positionY$ = this.store.pipe(select((state) => state.positionY));

  /**
   * The position information
   */
  position$ = ElfCombineQueries([this._configured$, this._positionX$, this._positionY$]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, x, y]) => {
      return { x, y };
    }),
  );

  /**
   * The type of animation.
   */
  private _type$ = this.store.pipe(select((state) => state.type));

  /**
   * The display duration of the text entity.
   */
  private _displayDuration$ = this.store.pipe(select((state) => state.displayDuration));

  /**
   * The enter animation settings.
   */
  private _enter$ = this.store.pipe(select((state) => state.enter));

  /**
   * The exit animation settings.
   */
  private _exit$ = this.store.pipe(select((state) => state.exit));

  /**
   * The animation data.
   */
  animationData$ = ElfCombineQueries([this._configured$, this._type$, this._displayDuration$, this._enter$, this._exit$]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, type, displayDuration, enter, exit]) => {
      return { type, displayDuration, enter, exit };
    }),
  );

  /**
   * Occurs when the animation has completed
   */
  complete$ = this.store.pipe(
    select((state) => state.complete),
    filter((complete) => complete),
  );
}
