import { Injectable } from '@angular/core';
import { IDataboxContainer } from 'src/app/api/modules/core/dynamic/databoxes/IDataboxContainer';
import { DataboxContainerRepository } from './databox-container.repository';

/**
 * Service for initializing and managing the state of a Databox Container.
 */
@Injectable()
export class DataboxContainerService {
  /**
   * Constructor
   *
   * @param databoxContainerRepository - The store responsible for managing Databox Container state.
   */
  constructor(private readonly databoxContainerRepository: DataboxContainerRepository) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the Databox Container with the provided configuration.
   *
   * @param configuration - The configuration for the Databox Container.
   */
  initialize(configuration?: IDataboxContainer) {
    this.databoxContainerRepository.applyInitialize(configuration);
  }
}
