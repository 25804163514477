import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { IDataboxProgressionIcon } from 'src/app/api/modules/core/dynamic/databoxes/progression-icon/IDataboxProgressionIcon';
import { IconStateComponent } from '../../../../../icons/components/icon-state/icon-state.component';
import { DataboxProgressionIconRepository } from './databox-progression-icon.repository';

@Component({
  selector: 'app-databox-progression-icon',
  templateUrl: './databox-progression-icon.component.html',
  styleUrls: ['./databox-progression-icon.component.scss'],
  providers: [DataboxProgressionIconRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, IconStateComponent, AsyncPipe],
})
export class DataboxProgressionIconComponent {
  private _config?: IDataboxProgressionIcon;
  /**
   * The Configuration powering the component.
   */
  @Input()
  set config(configuration: IDataboxProgressionIcon) {
    this._config = configuration;
    this.databoxProgressionIconRepository.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly databoxProgressionIconRepository: DataboxProgressionIconRepository) {}

  /**
   * Updates the component with the users progress
   *
   * @param current - the current progress of the user
   * @param total - the total amount of progress possible.
   */
  update(current: number, total?: number) {
    this.databoxProgressionIconRepository.applyUpdate(current, total);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.databoxProgressionIconRepository.applyReset();
  }
}
