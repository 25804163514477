import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { filter, map, switchMap } from 'rxjs';
import { ITable } from 'src/app/api/modules/core/dynamic/components/ITable';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { TableModel } from './table.model';

/**
 * The Default State
 */
function initialState(): TableModel {
  return {
    configured: false,
    rows: [],
    footers: null,
    headers: null,
  };
}

/**
 * The Store used for a {@link TableComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class TableRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `table-${uniqueId()}`,
    },
    withProps<TableModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: ITable) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`Table`, `configure`, `No configuration provided but this is required.`, `config`);
    }

    const rows = configuration.rows;

    const headers = configuration.headers;
    const footers = configuration.footers;

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.rows = rows;
        state.footers = footers;
        state.headers = headers;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((value) => value),
  );

  /**
   * Get the headers.
   */
  private _headers$ = this.store.pipe(select((state) => state.headers));

  /**
   * Get the rows.
   */
  private _rows$ = this.store.pipe(select((state) => state.rows));

  /**
   * Get the footers.
   */
  private _footers$ = this.store.pipe(select((state) => state.footers));

  /**
   * The Template Data
   */
  templateData$ = this._configured$.pipe(
    switchMap(() => ElfCombineQueries([this._headers$, this._rows$, this._footers$])),
    map(([headers, rows, footers]) => ({ headers, rows, footers })),
  );
}
