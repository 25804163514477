<!-- The Control Div should always exist since it is used to pause/play the media -->
<div class="control" (pointerup)="controlZonePointerUp()">
  <!-- The Poster is Optional and will only be shown when the audio is playing -->
  @if (mediaRepository.audioPoster$ | async) {
    <img
      class="poster"
      [class.hide-poster]="(mediaRepository.playing$ | async)?.playing !== true"
      [src]="mediaRepository.audioPoster$ | async"
      alt="Audio Poster"
    />
  }
</div>

<!-- The actual audio element responsible for file playback -->
<audio #media class="media" preload="auto"></audio>
