import { Injectable, OnDestroy } from '@angular/core';
import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { filter, map } from 'rxjs';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';
import { DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS } from 'src/app/app-constants';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ImageModel } from './image.model';

/**
 * The Default State
 */
function initialState(): ImageModel {
  return {
    errorIcon: {
      name: 'far:triangle-exclamation',
      size: '2x',
    },
    flex: '0 0 auto',
    hasError: false,
    width: 'auto',
    height: 'auto',
    widthValue: 0,
    heightValue: 0,
    aspect: '0',
    imageData: undefined,
    src: '',
    alt: '',
    borderRadius: undefined,
    fixedSize: undefined,
    link: undefined,
    wrapperWidth: '100%',
    hasGhost: false,
    isLoaded: false,
    isSetup: false,
    progress: 0,
    showProgress: false,
    hasDimensions: false,
    manualDisabledLoadingProgress: false,
  };
}

/**
 * The Repository used for an {@link ImageComponent}.
 */
@Injectable()
export class ImageRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `image-${uniqueId()}`,
    },
    withProps<ImageModel>(initialState()),
  );

  /**
   * Constructor
   *
   * @param mediaObserver - The media observer.
   */
  constructor(protected readonly mediaObserver: MediaObserver) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  applyInitialize(configuration?: IImage) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(
        `ImageStore`,
        `applyInitialize`,
        `No configuration provided but all images must provide a configuration.`,
        `config`,
      );
    }

    // SRC

    let src: string | undefined = undefined;
    // if there is no source then that is an error
    if (typeof configuration.src === 'string' && configuration.src.length > 0) {
      src = configuration.src;
    } else {
      throw createDigitaServiceError(
        `ImageStore`,
        `applyInitialize`,
        `No "src" property provided but all images must provide a source.`,
        `config`,
      );
    }

    // ALT

    let alt = '';
    if (typeof configuration.alt === 'string' && configuration.alt.length > 0) {
      alt = configuration.alt;
    }

    // LINK

    let link: ILink | undefined = undefined;
    if (configuration.link) {
      link = cloneDeep(configuration.link);
    }

    // BORDER RADIUS

    let borderRadius = undefined;
    if (typeof configuration.borderRadius === 'string' && configuration.borderRadius.length > 0) {
      borderRadius = configuration.borderRadius;
    }

    // WRAPPER WIDTH

    let wrapperWidth = '100%';
    if (typeof configuration.wrapperWidth === 'number' && configuration.wrapperWidth > 0) {
      wrapperWidth = `${configuration.wrapperWidth}px`;
    }

    // IMAGE DIMENSIONS

    let hasDimensions = false;
    let imageWidth: number | undefined = undefined;
    let imageHeight: number | undefined = undefined;
    let imageWidthStyle = 'auto';
    let imageHeightStyle = 'auto';
    if (
      typeof configuration.width === 'number' &&
      configuration.width > 0 &&
      typeof configuration.height === 'number' &&
      configuration.height > 0
    ) {
      hasDimensions = true;
      imageWidth = configuration.width;
      imageHeight = configuration.height;
      imageWidthStyle = `${configuration.width}px`;
      imageHeightStyle = `${configuration.height}px`;
    }

    // FIXED SIZE

    let isFixedSize = false;
    if (configuration.fixedSize === true) {
      isFixedSize = true;
    }

    // PROCESSING

    let flex = '0 0 auto';
    let hasGhost = false;
    let aspectStyle = `0`;
    let showProgress = true;

    // If the Component has explicitly disabled the loading progress.

    const { manualDisabledLoadingProgress } = this.store.value;

    // Determine if the progress spinner should be shown depending on the image size and spinner diameter
    if (hasDimensions) {
      if (manualDisabledLoadingProgress) {
        showProgress = false;
      } else {
        const progressSpinnerDiameter = DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS.diameter || 32;
        const minimalValidDimension = progressSpinnerDiameter * 2;
        if (imageWidth < minimalValidDimension || imageHeight < minimalValidDimension) {
          showProgress = false;
        }
      }
    } else {
      showProgress = false;
    }

    // See https://codepen.io/drimlike/pen/KKVJybZ
    // fixed size has different behavior from responsive images
    if (isFixedSize) {
      // note that the width and height may not always be provided and
      // if not provided, it should be auto.
      if (hasDimensions) {
        flex = `0 0 ${imageWidthStyle}`;
        hasGhost = true;
        aspectStyle = `calc(${imageHeight}/${imageWidth}*${wrapperWidth})`;
      } else {
        flex = `0 0 auto`;
        hasGhost = false;
        aspectStyle = `0`;
      }
    } else {
      // note that the width and height may not always be provided and
      // if not provided, it should be auto.
      if (hasDimensions) {
        flex = `0 1 ${imageWidthStyle}`;
        hasGhost = true;
        aspectStyle = `calc(${imageHeight}/${imageWidth}*${wrapperWidth})`;
      } else {
        flex = `0 1 auto`;
        hasGhost = false;
        aspectStyle = `0`;
      }
    }

    // UPDATE STORE

    this.store.update(
      ElfWrite((state) => {
        state.src = src;
        state.alt = alt;
        state.link = link;
        state.borderRadius = borderRadius;
        state.wrapperWidth = wrapperWidth;
        state.width = imageWidthStyle;
        state.height = imageHeightStyle;
        state.widthValue = imageWidth;
        state.heightValue = imageHeight;
        state.fixedSize = isFixedSize;
        state.flex = flex;
        state.hasGhost = hasGhost;
        state.aspect = aspectStyle;
        state.hasDimensions = hasDimensions;
        state.showProgress = showProgress;
        state.isSetup = true;
      }),
    );
  }

  /**
   * Occurs when a component's input property to disable the loading progress is set to true.
   */
  disableLoadingProgress() {
    this.store.update(
      ElfWrite((state) => {
        state.manualDisabledLoadingProgress = true;
        state.showProgress = false;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////
  // IMAGE DATA
  ////////////////////////////////////////////////////////////////////

  applyImageData(imageData?: string) {
    // if there is no image data then that is a problem
    if (!imageData) {
      throw createDigitaServiceError(
        `ImageStore`,
        `applyImageData`,
        `No image data provided but all images must provide image data.`,
        `internal`,
      );
    }

    // UPDATE STORE

    this.store.update(
      ElfWrite((state) => {
        state.imageData = imageData;
        state.isLoaded = true;
        state.progress = 100;
      }),
    );
  }

  applyProgress(progress: number) {
    this.store.update(
      ElfWrite((state) => {
        state.progress = progress;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////
  // APPLY ERROR
  ////////////////////////////////////////////////////////////////////

  applyError() {
    this.store.update(
      ElfWrite((state) => {
        state.hasError = true;
      }),
    );
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERY
  ////////////////////////////////////////////////////////////////////

  /**
   * Setup occurs before loading once a configuration has been processed.
   */
  isSetup$ = this.store.pipe(select((state) => state.isSetup));

  /**
   * The Source of the Image URI.
   */
  src$ = this.store.pipe(select((state) => state.src));

  /**
   * Does the image have a ghost?
   */
  private _hasGhost$ = this.store.pipe(select((state) => state.hasGhost));

  /**
   * Has the image loaded?
   */
  private _isLoaded$ = this.store.pipe(select((state) => state.isLoaded));

  /**
   * The border radius.
   */
  private _borderRadius$ = this.store.pipe(select((state) => state.borderRadius));

  /**
   * How does the image responsively behave?
   */
  private _flex$ = this.store.pipe(select((state) => state.flex));

  /**
   * What is the link if any?
   */
  private _link$ = this.store.pipe(select((state) => state.link));

  /**
   * Is there an error?
   */
  private _hasError$ = this.store.pipe(select((state) => state.hasError));

  /**
   * The image data of the loaded image.
   */
  private _imageData$ = this.store.pipe(select((state) => state.imageData));

  /**
   * The Error Icon to display if things go wrong.
   */
  private _errorIcon$ = this.store.pipe(select((state) => state.errorIcon));

  /**
   * Aspect of the Ghost.
   */
  private _aspect$ = this.store.pipe(select((state) => state.aspect));

  /**
   * The wrapped width.
   */
  private _wrapperWidth$ = this.store.pipe(select((state) => state.wrapperWidth));

  /**
   * The Width of the image as a number.
   */
  private _widthValue$ = this.store.pipe(select((state) => state.widthValue));

  /**
   * The Height of the image as a number.
   */
  private _heightValue$ = this.store.pipe(select((state) => state.heightValue));

  /**
   * The Alt Text of the Image.
   */
  private _alt$ = this.store.pipe(select((state) => state.alt));

  /**
   * Determine if the Media is Portrait.
   */
  private _isPortrait$ = ElfCombineQueries([this._widthValue$, this._heightValue$]).pipe(
    map(([width, height]) => width > 0 && height > 0 && height > width),
  );

  /**
   * From the Media Observer, get the current breakpoint.
   */
  private _mediaObserver$ = this.mediaObserver.asObservable().pipe(filter((changes: MediaChange[]) => changes.length > 0));

  /**
   * Using the Media Observer and the Media Portrait, determine if the Media should be limited in width on desktop to prevent
   * portrait videos being too big to fit on horizontal screens.
   */
  private _limitPortraitWidthOnDesktop$ = ElfCombineQueries([this._mediaObserver$, this._isPortrait$]).pipe(
    map(([changes, isMediaPortrait]) => isMediaPortrait && changes.length > 0 && changes[0].mqAlias !== 'xs'),
  );

  /**
   * The progress of the image loading.
   *
   * This is a number from 0 to 100.
   */
  private _progress$ = this.store.pipe(select((state) => state.progress));

  /**
   * Have dimensions been provided for the image via the JSON?
   */
  private _hasDimensions$ = this.store.pipe(select((state) => state.hasDimensions));

  /**
   * Should the progress spinner be shown?
   *
   * Progress is not shown if:
   *
   * 1. The image has dimensions but is too small to show the spinner
   * 2. The image does not have dimensions
   */
  private _showProgress$ = this.store.pipe(select((state) => state.showProgress));

  /**
   * The width of the image as a pixel value.
   */
  // private _width$ = this.store.pipe(select((state) => state.width));

  /**
   * The height of the image as a pixel value.
   */
  // private _height$ = this.store.pipe(select((state) => state.height));

  /**
   * All of the Data used in the template of the Image Component.
   */
  data$ = ElfCombineQueries([
    this.isSetup$,
    this._limitPortraitWidthOnDesktop$,
    this._isLoaded$,
    this._flex$,
    this._hasGhost$,
    this._borderRadius$,
    this._hasError$,
    this._aspect$,
    this._wrapperWidth$,
    this._errorIcon$,
    this._link$,
    this._imageData$,
    this._progress$,
    this._showProgress$,
    this._hasDimensions$,
    this._alt$,
  ]).pipe(
    map(
      ([
        isSetup,
        limitPortraitWidthOnDesktop,
        isLoaded,
        flex,
        hasGhost,
        borderRadius,
        hasError,
        aspect,
        wrapperWidth,
        errorIcon,
        link,
        imageData,
        progress,
        showProgress,
        hasDimensions,
        alt,
      ]) => {
        return {
          isSetup,
          limitPortraitWidthOnDesktop,
          isLoaded,
          flex,
          hasGhost,
          borderRadius,
          hasError,
          aspect,
          wrapperWidth,
          errorIcon,
          link,
          imageData,
          progress,
          showProgress,
          hasDimensions,
          alt,
        };
      },
    ),
  );
}
