import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { filter, map } from 'rxjs';
import { ICoreContainer } from 'src/app/api/modules/core/dynamic/ICoreContainer';
import { IAbstractComponent } from 'src/app/api/modules/shared/IAbstractComponent';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ContainerModel } from './container.model';

/**
 * The Default State
 */
function initialState(): ContainerModel {
  return {
    configured: false,
    layout: 'column',
    layoutAlign: 'space-between center',
    componentArray: [],
    componentObject: undefined,
  };
}

/**
 * The Store used for a {@link ContainerComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class ContainerRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `container-${uniqueId()}`,
    },
    withProps<ContainerModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Applies the initial configuration to the container's state. The configuration
   * must be provided, otherwise, an error is thrown. The container's state is
   * updated to reflect the provided configuration, marking it as 'configured'.
   *
   * @param configuration The configuration object for the container, adhering to the ICoreContainer interface.
   * @throws Will throw an error if the configuration is not provided.
   */
  applyInitialize(configuration?: ICoreContainer) {
    if (!configuration) {
      throw createDigitaServiceError(`Container`, `initialze`, `All containers required a configuration to be provided.`, `config`);
    }

    // Determine the layout configuration, defaulting to the store's default if not provided
    const layout = configuration.layout || initialState().layout;

    // Determine the layout alignment configuration, defaulting to the store's default if not provided
    const layoutAlign = configuration.layoutAlign || initialState().layoutAlign;

    // Deep clone the component array if provided, otherwise default to an empty array
    const componentArray: IAbstractComponent[] = configuration.componentArray ? cloneDeep(configuration.componentArray) : [];

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.layout = layout;
        state.layoutAlign = layoutAlign;
        state.componentArray = componentArray;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * An Observable that emits a boolean value indicating whether the screen is configured.
   * It emits true if the screen's `configured` state property is true.
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((configured) => configured),
  );

  ///////////////////////////////////////////////////////////////////////////
  // LAYOUT & APPEARANCE
  ///////////////////////////////////////////////////////////////////////////

  /**
   * An Observable that emits the current value of the screen's layout from the state.
   */
  private _layout$ = this.store.pipe(select((state) => state.layout));

  /**
   * An Observable that emits the current value of the screen's layout alignment from the state.
   */
  private _layoutAlign$ = this.store.pipe(select((state) => state.layoutAlign));

  /**
   * A public Observable that combines the latest values of the screen's configured status,
   * layout, and layout alignment into a single object. It only emits when the screen is configured.
   */
  templateData$ = ElfCombineQueries([this._configured$, this._layout$, this._layoutAlign$]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, layout, layoutAlign]) => {
      return { layout, layoutAlign };
    }),
  );

  ///////////////////////////////////////////////////////////////////////////
  // COMPONENTS
  ///////////////////////////////////////////////////////////////////////////

  /**
   * An Observable that emits the current array of components from the screen's state.
   */
  private _componentArray$ = this.store.pipe(select((state) => state.componentArray));

  ///////////////////////////////////////////////////////////////////////////
  // SCREEN CONFIGURATION
  ///////////////////////////////////////////////////////////////////////////

  /**
   * A public Observable that combines the latest values of the screen's configured status
   * and component array into a single object. It emits the `componentArray` only when the screen
   * is configured, ensuring that the components are only used when ready.
   */
  containerConfig$ = ElfCombineQueries([this._configured$, this._componentArray$]).pipe(
    filter(([configured]) => configured),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, componentArray]) => {
      return { componentArray };
    }),
  );
}
