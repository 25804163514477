import { Injectable } from '@angular/core';
import { IDialogMediaLock } from 'src/app/api/modules/core/components/dialog/IDialogMediaLock';
import { DialogMediaLockRepository } from './dialog-media-lock.repository';

/**
 * The Dialog Media Lock Service is responsible for managing the state of the {@link DialogMediaLockComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DialogMediaLockService {
  /**
   * Constructor
   */
  constructor(private readonly dialogMediaLockRepository: DialogMediaLockRepository) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Configure the system with the JSON configuration.
   *
   * @param configuration - the configuration to apply to the system.
   */
  applyConfiguration(configuration?: IDialogMediaLock) {
    this.dialogMediaLockRepository.applyConfiguration(configuration);
  }

  /**
   * The system has encountered an error.
   */
  applyError() {
    this.dialogMediaLockRepository.applyError();
  }

  /**
   * The system has been unlocked.
   */
  applyUnlock() {
    this.dialogMediaLockRepository.applyUnlock();
  }
}
