<div class="container" fxLayout="column" fxLayoutAlign="center center">
  @if (mediaRepository.displayUIPlayButton$ | async) {
    <div class="button">
      <mat-icon svgIcon="fas:circle-play" class="icon"></mat-icon>
    </div>
  }
  @if ((mediaRepository.playing$ | async)?.playing) {
    <div class="state" @mediaStateAnimation>
      <mat-icon class="state-icon" svgIcon="fas:circle-play"></mat-icon>
    </div>
  }
  @if (mediaRepository.paused$ | async) {
    <div class="state" @mediaStateAnimation>
      <mat-icon class="state-icon" svgIcon="fas:circle-pause"></mat-icon>
    </div>
  }
</div>
