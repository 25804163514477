import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { MediaManagerModel } from './media-manager.model';

/**
 * The Default State
 */
function initialState(): MediaManagerModel {
  return {
    activeMediaID: null,
  };
}

/**
 * Contains the Media Manager Store.
 *
 * This is used with {@link MediaManagerService} to emit it's state.
 */
@Injectable({
  providedIn: 'root',
})
export class MediaManagerRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `media-manager`,
    },
    withProps<MediaManagerModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // ACTIVE PLAYBACK
  ////////////////////////////////////////////////////////////////////

  /**
   * All Media (both streaming providers and native) have unique system IDs.
   *
   * Each time a media plays, it's unique ID is set (or removed) globally so that
   * any media which is not the active media can be paused.
   */
  applyActiveMediaID(id: string | null) {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.activeMediaID = id;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * All Media (both streaming providers and native) have unique system IDs.
   *
   * Each time a media plays, it's unique ID is set (or removed) globally so that
   * any media which is not the active media can be paused.
   */
  activeMediaID$ = this.store.pipe(select((state) => state.activeMediaID));
}
