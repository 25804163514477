import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { EMPTY, map, of, switchMap } from 'rxjs';
import { CountdownFormat, ICountdown, ICountdownLabels } from 'src/app/api/modules/core/dynamic/components/countdown/ICountdown';
import { ICountdownResponseData } from 'src/app/api/modules/core/dynamic/components/countdown/network/response/ICountdownResponseData';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { CountdownModel } from './countdown.model';
import { secondsToCountdown } from './countdown.utils';

/**
 * The Default State
 */
function initialState(): CountdownModel {
  return {
    configured: false,
    startTime: 0,
    endTime: 0,
    format: CountdownFormat.DAYS,
    onCompleteAPI: undefined,
    labels: undefined,
    initTime: 0,
    onCompleteResponse: false,
    destinationDelay: 0,
    destination: undefined,
    data: {
      remainingSeconds: 0,
      days: {
        enabled: false,
        label: undefined,
        next: {
          asNumber: 0,
          asString: ['0', '0'],
        },
        prev: {
          asNumber: 0,
          asString: ['0', '0'],
        },
      },
      hours: {
        enabled: false,
        label: undefined,
        next: {
          asNumber: 0,
          asString: ['0', '0'],
        },
        prev: {
          asNumber: 0,
          asString: ['0', '0'],
        },
      },
      minutes: {
        enabled: false,
        label: undefined,
        next: {
          asNumber: 0,
          asString: ['0', '0'],
        },
        prev: {
          asNumber: 0,
          asString: ['0', '0'],
        },
      },
      seconds: {
        enabled: false,
        label: undefined,
        next: {
          asNumber: 0,
          asString: ['0', '0'],
        },
        prev: {
          asNumber: 0,
          asString: ['0', '0'],
        },
      },
    },
  };
}

/**
 * The Store used for an {@link CountdownComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class CountdownRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `countdown-${uniqueId()}`,
    },
    withProps<CountdownModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: ICountdown) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      throw createDigitaServiceError(`Countdown`, `initialize`, `No configuration provided.`, `config`);
    }

    // format
    let format: CountdownFormat | undefined = undefined;
    if (configuration.format) {
      switch (configuration.format) {
        case CountdownFormat.DAYS:
        case CountdownFormat.HOURS:
        case CountdownFormat.MINUTES:
        case CountdownFormat.SECONDS:
          format = configuration.format;
          break;
      }
    }
    if (!format) {
      throw createDigitaServiceError(
        `Countdown`,
        `initialize`,
        `No "format" provided. This should be "days" "hours" "minutes" or "seconds".`,
        `config`,
      );
    }

    // labels
    let labels: ICountdownLabels | undefined = undefined;
    if (configuration.labels) {
      const { day, days, hour, hours, minute, minutes, second, seconds } = configuration.labels;

      [day, days, hour, hours, minute, minutes, second, seconds].forEach((label) => {
        if (typeof label !== 'string' || label.length === 0) {
          throw createDigitaServiceError(
            'Countdown',
            'initialize',
            `The "label.${label}" property must be provided otherwise no labels should be used.`,
            'config',
          );
        }
      });
    }
    labels = cloneDeep(configuration.labels);

    // start time
    const startTime = configuration.startTime;
    if (typeof startTime !== 'number' || startTime < 0) {
      throw createDigitaServiceError(
        'Countdown',
        'initialize',
        `The "startTime" property must be provided and be a number greater than 0.`,
        'config',
      );
    }

    // end time
    const endTime = configuration.endTime;
    if (typeof endTime !== 'number' || endTime < 0) {
      throw createDigitaServiceError(
        'Countdown',
        'initialize',
        `The "endTime" property must be provided and be a number greater than 0.`,
        'config',
      );
    }

    // init time
    const initTime = Math.floor(Date.now() / 1000);

    // on countdown complete API
    // Note that this can be empty if the countdown is for display purposes only.
    const onCompleteAPI = configuration.onCompleteAPI;

    // get the original data
    const { data } = this.store.getValue();
    // make the original conversion
    const originalConversion = secondsToCountdown(startTime, endTime, initTime, format, data, labels, true);

    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.format = format;
        state.labels = labels;
        state.startTime = startTime;
        state.endTime = endTime;
        state.initTime = initTime;
        state.data = originalConversion;
        state.onCompleteAPI = onCompleteAPI;
      }),
    );
  }

  /**
   * Applies a 1 second tick to the store.
   */
  applyTick() {
    const { startTime, endTime, initTime, format, data, labels } = this.store.getValue();
    const newData = secondsToCountdown(startTime, endTime, initTime, format, data, labels, false);

    this.store.update(
      ElfWrite((state) => {
        state.data = newData;
      }),
    );
  }

  /**
   * Apply the response from an API call to the store.
   *
   * @param response - the response from the server
   */
  applyCountdownCompleteResponse(response: ICountdownResponseData) {
    // if there is no response
    if (!response) {
      // do nothing
      return;
    }

    const destinationDelay = response.destinationDelay || 0;
    const destination = response.destination;

    if (!destination) {
      throw createDigitaServiceError(
        'Countdown',
        'response',
        `The "destination" property must be provided as part of the Countdown API Response.`,
        'config',
      );
    }

    this.store.update(
      ElfWrite((state) => {
        state.onCompleteResponse = true;
        state.destinationDelay = destinationDelay;
        state.destination = destination;
      }),
    );
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERY
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the countdown configured. This means that a JSON configuration has been provided and processed without error.
   */
  configured$ = this.store.pipe(select((state) => state.configured));

  // the data containing the countdown information
  private _data$ = this.store.pipe(select((state) => state.data));

  /**
   * The days section.
   */
  days$ = this._data$.pipe(map((data) => data.days));

  /**
   * The hours section
   */
  hours$ = this._data$.pipe(map((data) => data.hours));

  /**
   * The minutes section
   */
  minutes$ = this._data$.pipe(map((data) => data.minutes));

  /**
   * The seconds section
   */
  seconds$ = this._data$.pipe(map((data) => data.seconds));

  /**
   * If the remaining seconds are more than zero then the timer is active
   * otherwise the timer is not active.
   */
  timerComplete$ = this._data$.pipe(map((data) => data.remainingSeconds === 0));

  // the API end point for when the timer completes
  private _onCompleteAPI$ = this.store.pipe(select((state) => state.onCompleteAPI));

  /**
   * onTimerComplete API end point.
   *
   * Not this is optional and may not occur if the API is not configured.
   */
  onCompleteRequest$ = ElfCombineQueries([this._onCompleteAPI$, this.timerComplete$]).pipe(
    switchMap(([api, complete]) => (complete && api ? of(api) : EMPTY)),
  );

  /**
   * Has the system recieved a complete response from the server?
   */
  private _onCompleteResponse$ = this.store.pipe(select((state) => state.onCompleteResponse));

  /**
   * What is the destination delay before navigating to the destination?
   */
  private _destinationDelay$ = this.store.pipe(select((state) => state.destinationDelay));

  /**
   * What is the final destination once the timer has completed?
   */
  private _destination$ = this.store.pipe(select((state) => state.destination));

  /**
   * The destination delay and destination provided after a successful API call.
   */
  onCountdownCompleteResponse$ = ElfCombineQueries([this._onCompleteResponse$, this._destinationDelay$, this._destination$]).pipe(
    switchMap(([response, delay, destination]) => {
      if (response === true) {
        return of({ delay, destination });
      } else {
        return EMPTY;
      }
    }),
  );
}
