import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { IDataboxProgressionIcon } from 'src/app/api/modules/core/dynamic/databoxes/progression-icon/IDataboxProgressionIcon';
import { GenerateFaIcon } from 'src/app/factories/generators/icon-fa.generator';
import { GenerateIconState } from 'src/app/factories/generators/icon-state.generator';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DataboxProgressionIconModel, DataboxProgressionIconStateModel } from './databox-progression-icon.model';

/**
 * A Built in Default State
 */
function DEFAULT_STATE() {
  return GenerateFaIcon({
    name: 'far:circle',
    state: 'default',
  });
}

/**
 * A Built in Checked State
 */
function CHECKED_STATE() {
  return GenerateFaIcon({
    name: 'fas:circle-check',
    state: 'checked',
  });
}

/**
 * The default final state which will be duplicated
 */
function defaultState() {
  return GenerateIconState({
    states: [DEFAULT_STATE(), CHECKED_STATE()],
  });
}

/**
 * The Default State
 */
function initialState(): DataboxProgressionIconModel {
  return {
    selector: 'app-databox-progression-icon',
    state: defaultState(),
    states: [],
    total: 0,
  };
}

/**
 * The Store used for a {@link DataboxProgressionIconComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DataboxProgressionIconRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `databox-progression-icon-${uniqueId()}`,
    },
    withProps<DataboxProgressionIconModel>(initialState()),
  );

  constructor(private readonly logger: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initialize from the configuration.
   */
  applyInitialize(configuration?: Partial<IDataboxProgressionIcon>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    // state
    let activeState = defaultState();
    if (configuration.state?.states) {
      let hasDefault = true;
      let hasChecked = true;

      // get the states that are incoming for default and checked.
      const firstState = configuration?.state.states[0];
      const secondState = configuration?.state.states[1];

      // check that one of these states is the default.
      if (firstState.state === 'default' || secondState.state === 'default') {
        // ok
      } else {
        this.logger.warn(
          `[DataboxProgressionIcon] applyInitialize - Could not initialize databox as no provided icon state has a state name of "default". A Default will be used.`,
        );
        hasDefault = false;
      }

      // check that one of these state is the checked.
      if (firstState.state === 'checked' || secondState.state === 'checked') {
        // ok
      } else {
        this.logger.warn(
          `[DataboxProgressionIcon] applyInitialize - Could not initialize databox as no provided icon state has a state name of "checked". A Default will be used.`,
        );
        hasChecked = false;
      }

      if (hasDefault && hasChecked) {
        activeState = cloneDeep(configuration.state);
      }
    }

    // if there is a total provided, then it is used to build states
    let total = 0;
    if (typeof configuration.total === 'number') {
      total = configuration.total;
    }

    // generate the states if applicable
    const states: DataboxProgressionIconStateModel[] = [];
    if (total > 0) {
      for (let i = 0; i < total; i++) {
        const newState: DataboxProgressionIconStateModel = cloneDeep(activeState);
        newState.checked = false;
        states.push(newState);
      }
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.state = activeState;
        state.states = states;
        state.total = total;
      }),
    );
  }

  /**
   * Updates the component with the users progress.
   *
   * @param current - the current progress of the user
   * @param updateTotal - the total amount of progress possible, this is optional.
   */
  applyUpdate(current: number, updateTotal?: number): void {
    // get the configured data
    const { state, states, total } = this.store.getValue();

    let newStates: DataboxProgressionIconStateModel[] = cloneDeep(states);
    let newTotal = total;

    // if the total has been updated and is different then recreate the states array
    if (typeof updateTotal === 'number' && newStates.length !== updateTotal) {
      newStates = [];
      newTotal = 0;
      for (let i = 0; i < updateTotal; i++) {
        const newState: DataboxProgressionIconStateModel = cloneDeep(state);
        newState.checked = false;
        newStates.push(newState);
        newTotal++;
      }
    }

    // if there is a current
    if (typeof current === 'number') {
      // if the current state has been provided, then update the states
      for (let i = 0; i < newStates.length; i++) {
        if (i < current) {
          newStates[i].checked = true;
        } else {
          newStates[i].checked = false;
        }
      }
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.total = newTotal;
        state.states = newStates;
      }),
    );
  }

  /**
   * Reset the ouput to the templateIdle value.
   */
  applyReset() {
    const { states } = this.store.getValue();

    const newStates = cloneDeep(states);

    for (let i = 0; i < newStates.length; i++) {
      newStates[i].checked = false;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.states = newStates;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * The Final Output State.
   */
  states$ = this.store.pipe(select((state) => state.states));
}
