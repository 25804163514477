/* eslint-disable @angular-eslint/no-output-native */

import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { EMPTY, Subscription, catchError, switchMap, take, tap } from 'rxjs';
import { IMedia } from 'src/app/api/modules/core/dynamic/components/IMedia';
import { FaIconComponent } from '../../../../icons/components/fa-icon/fa-icon.component';
import { DomIntersectionObserverDirective } from '../../../directives/dom-intersection-observer.directive';
import { MediaNativeAudioComponent } from './media-native-audio/media-native-audio.component';
import { MediaNativeVideoComponent } from './media-native-video/media-native-video.component';
import { MediaUIComponent } from './media-ui/media-ui.component';
import { MediaVimeoComponent } from './media-vimeo/media-vimeo.component';
import { MediaYouTubeComponent } from './media-youtube/media-youtube.component';
import { mediaReadyAnimation } from './media.animations';
import { MediaRepository } from './media.repository';
import { MediaService } from './media.service';
import { MediaTypes } from './media.types';

/**
 * The Media Component is used to wrap multiple types of media.
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  providers: [MediaService, MediaRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [mediaReadyAnimation],
  standalone: true,
  imports: [
    MatProgressBar,
    FlexModule,
    FaIconComponent,
    DomIntersectionObserverDirective,
    MediaYouTubeComponent,
    MediaVimeoComponent,
    MediaNativeVideoComponent,
    MediaNativeAudioComponent,
    MediaUIComponent,
    AsyncPipe,
  ],
})
export class MediaComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // @INPUTS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Play the Media.
   */
  @Input() set play(value: boolean) {
    if (value === true) {
      this.playMedia();
    } else {
      this.pauseMedia();
    }
  }

  /**
   * Pause the Media.
   */
  @Input() set pause(value: boolean) {
    if (value === true) {
      this.pauseMedia();
    } else {
      this.playMedia();
    }
  }

  /**
   * Seek the Media at a specific time.
   *
   * @param seconds - the time in seconds to play the media.
   */
  @Input() set seek(seconds: number) {
    if (typeof seconds === 'number' && seconds > 0) {
      this.seekMediaTo(seconds, true, false);
    }
  }

  /**
   * Play the Media at a specific time in seconds.
   *
   * @param seconds - the time in seconds to play the media.
   */
  @Input() set playAt(seconds: number) {
    if (typeof seconds === 'number' && seconds > 0) {
      this.seekMediaTo(seconds, true, true);
    }
  }

  /**
   * Mute the Media.
   */
  @Input() set mute(value: boolean) {
    if (value === true) {
      this.muteMedia();
    } else {
      this.unMuteMedia();
    }
  }

  /**
   * Set the media volume.
   *
   * @param value - the volume to set the media to (0-100).
   */
  @Input() set volume(value: number) {
    if (typeof value === 'number' && value >= 0 && value <= 100) {
      this.setMediaVolume(value);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // @OUTPUTS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the media player ready?
   */
  @Output() ready = new EventEmitter<void>();

  /**
   * Is the media player playing?
   */
  @Output() playing = new EventEmitter<void>();

  /**
   * Is the media player paused?
   */
  @Output() paused = new EventEmitter<void>();

  /**
   * Is the media player ended?
   */
  @Output() ended = new EventEmitter<void>();

  /**
   * The Media player current time in seconds.
   */
  @Output() currentTime = new EventEmitter<number>();

  /**
   *  The Media player duration in seconds.
   */
  @Output() duration = new EventEmitter<number>();

  /**
   * The Media player progress in percentage from 0 - 100.
   */
  @Output() progress = new EventEmitter<number>();

  /**
   * Occurs when the Media has experienced an error.
   */
  @Output() error = new EventEmitter<void>();

  /**
   * Is the Media Source portrait?
   */
  @Output() isPortrait = new EventEmitter<boolean>(false);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONFIG
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The Configuration
   */
  private _config?: IMedia;
  @Input() set config(configuration: IMedia) {
    this._config = configuration;
    this.mediaService.applyConfiguration(configuration);
  }
  get config(): IMedia {
    return this._config;
  }

  /**
   * All Players must be configured initially before they can be used.
   */
  private configurationSubscription?: Subscription;

  /**
   * Holds all subscriptions for teardown.
   */
  private subscriptions?: Subscription;

  /**
   * Constructor
   *
   * @param mediaService - a reference to the media service.
   * @param mediaRepository - a reference to the media query.
   */
  constructor(
    private readonly mediaService: MediaService,
    public readonly mediaRepository: MediaRepository,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle Hook
   */
  ngOnInit() {
    // initialize
    this.initialize();
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
    this.subscriptions?.unsubscribe();
  }

  /**
   * Initialize the Video Player.
   *
   * This is split to it's own function so that it's possible to retry the sequence.
   */
  private initialize() {
    // destroy any existing subscriptions
    this.configurationSubscription?.unsubscribe();
    this.subscriptions?.unsubscribe();

    // prepare the subscriptions
    this.subscriptions = new Subscription();

    // listen out for if the media is portrait, this is required
    // so that users of the Media Object can determine if special
    // layout should be taken.
    const portrait = this.mediaRepository.isMediaPortrait$.subscribe((isPortrait) => {
      this.isPortrait.emit(isPortrait);
    });
    this.subscriptions.add(portrait);

    // listen out for if the media throws an error
    const error = this.mediaRepository.error$.subscribe((error) => {
      if (error === true) {
        this.error.emit();
      }
    });
    this.subscriptions.add(error);

    // listen out for when the application is configured via JSON so we know the type
    this.configurationSubscription = this.mediaRepository.configuredType$
      .pipe(
        // we only need to do this once
        take(1),
        // return the correct initialize sequence based on the type
        switchMap((type) => {
          switch (type) {
            case MediaTypes.youtube:
              return this.initializeYouTube();
            case MediaTypes.vimeo:
              return this.initializeVimeo();
            case MediaTypes.video:
              return this.initializeNativeVideo();
            case MediaTypes.audio:
              return this.initializeNativeAudio();
          }
        }),
        catchError((error: Error) => {
          // if an error occurred then get rid of this subscription
          this.configurationSubscription?.unsubscribe();

          // and inform the store of the error
          this.mediaService.applyError(error?.message);

          // and return an empty observable
          return EMPTY;
        }),
      )
      .subscribe(() => {
        // this subscription is no longer required
        this.configurationSubscription?.unsubscribe();

        // we can consider the system initialized at this point
        this.initialized();
      });
  }

  /**
   * Initialize the YouTube Sequence.
   *
   *    1. Load the player (only once globally)
   *    2. Get the configuration
   *    3. Get the video details from the OEmbed API
   */
  private initializeYouTube() {
    return this.mediaService.applyYouTubePlayerLoad().pipe(
      take(1),
      switchMap(() => {
        return this.mediaRepository.youtubeSettings$;
      }),
      switchMap((configuration) => {
        return this.mediaService.retrieveYouTubeVideoDetails(configuration.videoId);
      }),
      catchError((error: HttpErrorResponse) => {
        this.mediaService.applyError(error?.message);
        return EMPTY;
      }),
      tap((details) => {
        this.mediaService.applyYouTubeVideoDetails(details);
      }),
    );
  }

  /**
   * Initialize the Vimeo Sequence.
   *
   *    1. Load the player (only once globally)
   *    2. Get the configuration
   *    3. Get the video details from the OEmbed API
   */
  private initializeVimeo() {
    return this.mediaService.applyVimeoPlayerLoad().pipe(
      take(1),
      switchMap(() => {
        return this.mediaRepository.vimeoSettings$;
      }),
      switchMap((configuration) => {
        return this.mediaService.applyRetrieveVimeoVideoDetails(configuration.id, configuration.url);
      }),
      catchError((error: HttpErrorResponse) => {
        this.mediaService.applyError(error?.message);
        return EMPTY;
      }),
      tap((details) => {
        this.mediaService.applyVimeoVideoDetails(details);
      }),
    );
  }

  /**
   * Initialize the Video Sequence.
   *
   * This is easy because all details have already been provided in the configuration
   */
  private initializeNativeVideo() {
    return this.mediaRepository.videoSettings$.pipe(
      take(1),
      tap(() => {
        this.mediaService.applyNativeVideoDetails();
      }),
    );
  }

  /**
   * Initialize the Audio Sequence.
   *
   * This is easy because all details have already been provided in the configuration
   */
  private initializeNativeAudio() {
    return this.mediaRepository.audioSettings$.pipe(
      take(1),
      tap(() => {
        this.mediaService.applyNativeAudioDetails();
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // INITIALIZED
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * When the system has been configured, and loaded the required providers and video information (where applicable)
   * then the system is considered initialized and is ready to create the player.
   */
  private initialized() {
    // listen out for when the media provider is ready and emit the ready event
    const ready = this.mediaRepository.ready$.subscribe(() => {
      this.ready.emit();
    });
    this.subscriptions.add(ready);

    // listen out for when the media is playing and emit the playing event
    const playing = this.mediaRepository.playing$.subscribe((data) => {
      if (data.playing) {
        // if the data is playing, inform the global media manager of this ID.
        this.mediaService.applyActiveMedia(data.mediaID);
        this.playing.emit();
      }
    });
    this.subscriptions.add(playing);

    // listen out for when the media is paused and emit the paused event
    const paused = this.mediaRepository.paused$.subscribe((paused) => {
      if (paused) {
        this.paused.emit();
      }
    });
    this.subscriptions.add(paused);

    // listen out for the currentTime and emit the currentTime event
    const currentTime = this.mediaRepository.currentTime$.subscribe((currentTime) => {
      this.currentTime.emit(currentTime);
    });
    this.subscriptions.add(currentTime);

    // listen out for the duration and emit the duration event
    const duration = this.mediaRepository.duration$.subscribe((duration) => {
      this.duration.emit(duration);
    });
    this.subscriptions.add(duration);

    // listen out for the progress and emit the progress event
    // this is a percentage based on the current time and duration
    const progress = this.mediaRepository.progress$.subscribe((progress) => {
      this.progress.emit(progress);
    });
    this.subscriptions.add(progress);

    // listen out for when the media is ended and emit the ended event
    const ended = this.mediaRepository.ended$.subscribe((ended) => {
      if (ended) {
        this.ended.emit();
      }
    });
    this.subscriptions.add(ended);

    // listen out for when the global playing media is not this media
    const pauseIfNotActiveMedia = this.mediaRepository.pauseAsNotActiveMedia$.subscribe(() => {
      this.pauseMedia();
    });
    this.subscriptions.add(pauseIfNotActiveMedia);

    // // pause when not intersecting
    const pauseAsNotIntersecting$ = this.mediaRepository.shouldPauseWhenNoLongerIntersecting.subscribe((shouldPause) => {
      if (shouldPause) {
        this.pauseMedia();
      }
    });
    this.subscriptions.add(pauseAsNotIntersecting$);

    // finally this component has initialized
    this.mediaService.applyInitialized();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // API CONTROLS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Play the Media.
   */
  playMedia() {
    this.mediaService.play();
  }

  /**
   * Pause the Media.
   */
  pauseMedia() {
    this.mediaService.pause();
  }

  /**
   * Seek the Media at a specific time.
   *
   * @param seconds - The time in seconds to seek to.
   * @param allowSeekAhead - Whether the player is allowed to make a new request to the server if it determines that the
   * current playback position is too far ahead of the next buffered position. This value defaults to true.
   * @param play - Whether the player should play the video after seeking to the given time.
   */
  seekMediaTo(seconds: number, allowSeekAhead = true, play = false) {
    this.mediaService.seekTo(seconds, allowSeekAhead, play);
  }

  /**
   * Mute the media.
   */
  muteMedia() {
    this.mediaService.mute();
  }

  /**
   * Unmute the media.
   */
  unMuteMedia() {
    this.mediaService.unMute();
  }

  /**
   *  Set the media volume.
   *
   * @param volume - a number between 0 and 100
   */
  setMediaVolume(volume: number) {
    if (typeof volume === 'number' && volume >= 0 && volume <= 100) {
      this.mediaService.volume(volume);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // TEMPLATE METHODS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Is this media intersecting with the viewport?
   *
   * @param intersecting - true if so, false if not.
   */
  isIntersecting(intersecting: boolean) {
    // inform the store of the intersection state
    this.mediaService.applyIsIntersecting(intersecting);
  }
}
