import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IIconState } from 'src/app/api/modules/icons/IIconState';
import { FaIconComponent } from '../fa-icon/fa-icon.component';
import { IconStateRepository } from './icon-state.repository';

/**
 * IconState shows a single Icon from a list of icons as a state container.
 *
 * For example imagine you have an icon "lights-on" and "lights-off". These are
 * provided as states,  You can set what state is shown by the state properties of
 * the icon.
 *
 * ie: `iconState.setState('off') // will show the icon with the matching state "off".
 */
@Component({
  selector: 'app-icon-state',
  templateUrl: './icon-state.component.html',
  styleUrls: ['./icon-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IconStateRepository],
  standalone: true,
  imports: [FaIconComponent, AsyncPipe],
})
export class IconStateComponent {
  /**
   * Constructor
   */
  constructor(public readonly componentRepository: IconStateRepository) {}

  /**
   * The Config as provided to the element
   */
  @Input()
  set config(configuration: IIconState) {
    this.componentRepository.initialize(configuration);
  }

  /**
   * The state to be in
   */
  @Input()
  set state(state: string) {
    this.componentRepository.state(state);
  }
}
