import { Injectable } from '@angular/core';
import { IMediaLockable } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { MediaLockableRepository } from './media-lockable.repository';

/**
 * The Media Lockable Service is responsible for managing the state of the {@link MediaLockableComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaLockableService {
  /**
   * Constructor
   */
  constructor(private readonly mediaLockableRepository: MediaLockableRepository) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Configure the system with the JSON configuration.
   *
   * @param configuration - the configuration to apply to the system.
   */
  applyConfiguration(configuration?: IMediaLockable) {
    this.mediaLockableRepository.applyConfiguration(configuration);
  }

  /**
   * When the Media starts to play then the system is initialized.
   */
  applyInitialize() {
    this.mediaLockableRepository.applyInitialize();
  }

  /**
   * The system has encountered an error.
   */
  applyError() {
    this.mediaLockableRepository.applyError();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STATES
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The Media is ready to play.
   */
  applyMediaIsReady() {
    this.mediaLockableRepository.applyMediaIsReady();
  }

  /**
   * The total duration of the Media in seconds
   *
   * @param duration - the duration of the Media in seconds
   */
  applyDuration(duration: number) {
    if (typeof duration === 'number') {
      this.mediaLockableRepository.applyDuration(duration);
    }
  }

  /**
   * The current time of the Media in seconds.
   *
   * @param currentTime - the current time of the Media in seconds
   */
  applyCurrentTime(currentTime: number) {
    if (typeof currentTime === 'number') {
      this.mediaLockableRepository.applyCurrentTime(currentTime);
    }
  }

  /**
   * Is the Media in Portrait Mode?
   *
   * @param isPortrait - is the Media in Portrait Mode?
   */
  applyMediaIsPortrait(isPortrait: boolean) {
    if (typeof isPortrait === 'boolean') {
      this.mediaLockableRepository.applyMediaIsPortrait(isPortrait);
    }
  }
}
