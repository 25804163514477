/* eslint-disable @typescript-eslint/no-unused-vars */

import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import { ButtonComponent } from '../../dynamic/components/button/button.component';
import { ContainerComponent } from '../../dynamic/containers/container/container.component';
import { DialogContentRepository } from './dialog-content.repository';
import { DialogContentService } from './dialog-content.service';

/**
 * Content Dialogs are used generically
 *
 * They have a title, a `Container` and a positive and negative optional button.
 *
 * See [IDialogContent]{@link IDialogContent}
 */
@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
  providers: [DialogContentService, DialogContentRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    ContainerComponent,
    MatDialogActions,
    FlexModule,
    // There is a circular dependency here, so we need to use forwardRef
    forwardRef(() => ButtonComponent),
    AsyncPipe,
  ],
})
export class DialogContentComponent {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONFIG
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The Configuration
   */
  private _config?: IDialogContent;
  @Input()
  set config(configuration: IDialogContent) {
    this._config = configuration;
    this.dialogContentService.applyConfiguration(configuration);
  }
  get config(): IDialogContent {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(
    private dialogRef: MatDialogRef<DialogContentComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: IDialogContent,
    private readonly dialogContentService: DialogContentService,
    public readonly dialogContentRepository: DialogContentRepository,
  ) {
    this.config = data;
  }

  /**
   * Occurs when the user clicks on the optional negative button.
   */
  onNegativeClick(e: MouseEvent) {
    this.dialogRef.close(false);
  }

  /**
   * Occurs when the user clicks on the optional positive button.
   */
  onPositiveClick(e: MouseEvent) {
    this.dialogRef.close(true);
  }
}
