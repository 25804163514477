import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { EMPTY, delay, of, switchMap } from 'rxjs';
import { IDialogMediaLock } from 'src/app/api/modules/core/components/dialog/IDialogMediaLock';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DialogMediaLockModel } from './dialog-media-lock.model';

/**
 * The Default State
 */
function initialState(): DialogMediaLockModel {
  return {
    configured: false,
    header: undefined,
    lock: undefined,
    footer: undefined,
    error: false,
    unlocked: false,
    settings: {},
  };
}

/**
 * The Store used for a {@link DialogMediaLockComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DialogMediaLockRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `dialog-media-lock-${uniqueId()}`,
    },
    withProps<DialogMediaLockModel>(initialState()),
  );

  constructor(private readonly loggerService: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IDialogMediaLock) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`DialogMediaLock`, `applyConfiguration`, `No configuration provided but this is required.`, `config`);
    }

    // the header
    const header = configuration.header;

    // if there is no lock then that is an error
    const lock = configuration.lock;
    if (!lock) {
      throw createDigitaServiceError(
        `DialogMediaLock`,
        `applyConfiguration`,
        `No "lock" property provided but this is required.`,
        `config`,
      );
    }

    // the footer
    const footer = configuration.footer;
    if (!footer) {
      throw createDigitaServiceError(
        `DialogMediaLock`,
        `applyConfiguration`,
        `No "footer" property provided but this is required.`,
        `config`,
      );
    }
    if (!footer.negativeButton) {
      throw createDigitaServiceError(
        `DialogMediaLock`,
        `applyConfiguration`,
        `No "footer.negativeButton" property provided but this is required.`,
        `config`,
      );
    }
    if (footer.positiveButton) {
      throw createDigitaServiceError(
        `DialogMediaLock`,
        `applyConfiguration`,
        `Do not use "footer.positiveButton" property for this dialog type. Use "footer.negativeButton" instead.`,
        `config`,
      );
    }

    // the settings
    const settings = configuration.settings;

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.header = header;
        state.lock = lock;
        state.footer = footer;
        state.settings = settings;
      }),
    );
  }

  /**
   * An error Occurred.
   */
  applyError() {
    this.loggerService.error(`Media Error - Unlocked`);
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.unlocked = true;
        state.error = true;
      }),
    );
  }

  /**
   * Occurs when the Media Lock is unlocked.
   */
  applyUnlock() {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.unlocked = true;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  configured$ = this.store.pipe(select((state) => state.configured));

  /**
   * The Header Configuration.
   */
  header$ = this.store.pipe(select((state) => state.header));

  /**
   * What is the Media Lock Configuration?
   */
  lock$ = this.store.pipe(select((state) => state.lock));

  /**
   * Has an error occurred?
   */
  error$ = this.store.pipe(select((state) => state.error));

  /**
   * Is the Media Unlocked?
   */
  private _unlocked$ = this.store.pipe(
    select((state) => state.unlocked),
    delay(500),
  );

  /**
   * The Footer Configuration.
   */
  footer$ = this.store.pipe(select((state) => state.footer));

  /**
   * The Footer Button Configuration becomes avilaible once the Media has been unlocked.
   *
   * Assuming there is no errors.
   */
  button$ = ElfCombineQueries([this._unlocked$, this.error$, this.footer$]).pipe(
    switchMap(([unlocked, error, footer]) => {
      if (unlocked || error) {
        return of(footer.negativeButton);
      }
      return EMPTY;
    }),
  );
}
