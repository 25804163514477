import { Injectable, OnDestroy } from '@angular/core';
import { MediaChange } from '@ngbracket/ngx-layout';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { filter, map } from 'rxjs';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { IButtonContainer } from 'src/app/api/modules/core/dynamic/containers/IButtonContainer';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ButtonContainerModel } from './button-container.model';

/**
 * The Default State
 */
function initialState(): ButtonContainerModel {
  return {
    configured: false,
    layout: 'column',
    layoutAlign: 'start stretch',
    isStacked: true,
    mediaAlias: null,
    componentArray: [],
    buttonSizing: undefined,
    preferredButtonSizing: undefined,
    preferredLayout: undefined,
    buttonCount: 0,
  };
}

/**
 * The Store used for a {@link ButtonContainerComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class ButtonContainerRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `button-container-${uniqueId()}`,
    },
    withProps<ButtonContainerModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initiailize a button store
   */
  initialize(configuration?: IButtonContainer) {
    // if there is no configuration then that is an issue
    if (!configuration) {
      throw createDigitaServiceError('ButtonContainer', 'initialize', 'You must supply a configuration to a button container.', 'config');
    }

    // if there are no buttons for the button container than that is an issue
    if (!configuration.componentArray || configuration.componentArray.length === 0) {
      throw createDigitaServiceError(
        'ButtonContainer',
        'initialize',
        'You must supply atleast one button to a button container.',
        'config',
      );
    }

    // check that all objects in the component array have a selector of type "app-button" or that is an error
    for (let i = 0; i < configuration.componentArray.length; i++) {
      const component = configuration.componentArray[i];

      if (component.selector !== 'app-button') {
        throw createDigitaServiceError(
          'ButtonContainer',
          'initialize',
          'All components in the component array must be of `"selector": "app-button"`',
          'config',
        );
      }
    }

    // preferred layout
    let preferredLayout: 'column' | 'row' | 'row-reverse' | undefined = undefined;
    if (configuration.layout) {
      preferredLayout = configuration.layout;
    }

    // preferred button sizing
    let preferredButtonSizing: 'fixedSize' | 'fullSize' | undefined = undefined;
    if (configuration.buttonSizing) {
      preferredButtonSizing = configuration.buttonSizing;
    }

    // component array
    let componentArray: IButton[] = [];
    if (configuration.componentArray) {
      componentArray = cloneDeep(configuration.componentArray);
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.preferredButtonSizing = preferredButtonSizing;
        state.preferredLayout = preferredLayout;
        state.componentArray = componentArray;
        state.buttonCount = configuration.componentArray.length;
      }),
    );

    // process
    this.processState();
  }

  /**
   * Initiailize a button store
   */
  mediaChange(change: MediaChange) {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.mediaAlias = change.mqAlias;
      }),
    );

    // process
    this.processState();
  }

  /**
   * Takes the current configuration and uses that information to process the state
   */
  private processState() {
    let layout: 'row' | 'row-reverse' | 'column' = 'column';
    let layoutAlign = 'start stretch';
    let isStacked = true;
    let buttonSizing: 'fixedSize' | 'fullSize' | undefined = undefined;
    let validRow = false;

    const { preferredButtonSizing, preferredLayout, buttonCount, mediaAlias } = this.store.getValue();

    if (mediaAlias) {
      validRow = false;

      // first we will try to select the configurations preferred choice if it is a row or row-reverse
      if (preferredLayout === 'row' || preferredLayout === 'row-reverse') {
        // the user wants a row or row-reverse, but has more than 2 buttons, it cannot be a row and must remain a column
        if (buttonCount < 3) {
          // we should deal with responsiveness here too since you cannot have a row on small screens
          if (mediaAlias !== 'xs') {
            layout = preferredLayout;
            layoutAlign = 'space-between center';
            isStacked = false;
            validRow = true;
          }
        }
      }

      // if no valid row was created then we must have a column.
      if (validRow === false) {
        // if there is 1 button it has a different layout
        if (buttonCount === 1) {
          isStacked = false;
          buttonSizing = preferredButtonSizing;
        }
      }
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.layout = layout;
        state.isStacked = isStacked;
        state.layoutAlign = layoutAlign;
        state.buttonSizing = buttonSizing;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the system configured?
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((configured) => configured),
  );

  /**
   * The layout of the container is determined at runtime.
   */
  private layout$ = this.store.pipe(select((state) => state.layout));

  /**
   * The layout align of the container is determined at runtime.
   */
  private layoutAlign$ = this.store.pipe(select((state) => state.layoutAlign));

  /**
   * What sizing mode is applied to the buttons?
   */
  private _buttonSizing$ = this.store.pipe(select((state) => state.preferredButtonSizing));

  /**
   * Are buttons fixed size?
   */
  private _isFixedSize$ = this._buttonSizing$.pipe(map((sizingMode) => sizingMode === 'fixedSize'));

  /**
   * Are buttons full size?
   */
  private _isFullSize$ = this._buttonSizing$.pipe(map((sizingMode) => sizingMode === 'fullSize'));

  /**
   * Contains all layout data.
   */
  templateData$ = ElfCombineQueries([this._configured$, this.layout$, this.layoutAlign$, this._isFixedSize$, this._isFullSize$]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, layout, layoutAlign, isFixedSize, isFullSize]) => {
      return {
        layout,
        layoutAlign,
        isFixedSize,
        isFullSize,
      };
    }),
  );

  /**
   * Is the buttons stacked in their containers?
   */
  isStacked$ = this.store.pipe(select((state) => state.isStacked));

  /**
   * The component array containing the buttons.
   */
  private _componentArray$ = this.store.pipe(select((state) => state.componentArray));

  /**
   * The component array.
   */
  componentArray$ = ElfCombineQueries([this._configured$, this._componentArray$]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, componentArray]) => {
      return componentArray;
    }),
  );
}
