import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { ISocialSharingButton } from 'src/app/api/modules/core/dynamic/components/ISocialSharingButton';
import { IFaIcon } from 'src/app/api/modules/icons/fa/IFaIcon';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { SocialSharingButtonModel } from './social-sharing-button.model';

/**
 * The Default State
 */
function initialState(): SocialSharingButtonModel {
  return {
    configured: false,
    platform: 'facebook',
    platformStyle: 'facebook',
    platformIcon: {
      name: 'fab:facebook-f',
    },
    link: undefined,
    snackbar: undefined,
    type: 'share',
  };
}

/**
 * The Store used for a {@link SocialSharingButtonComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class SocialSharingButtonRepository implements OnDestroy {
  /**
   * The platforms.
   */
  static readonly platforms: Record<
    string,
    {
      style: string;
      icon: IFaIcon;
    }
  > = {
    blogger: {
      style: 'blogger',
      icon: {
        name: 'fab:blogger-b',
      },
    },
    buffer: {
      style: 'buffer',
      icon: {
        name: 'fab:buffer',
      },
    },
    copy: {
      style: 'copy',
      icon: {
        name: 'fas:copy',
      },
    },
    diaspora: {
      style: 'diaspora',
      icon: {
        name: 'fab:diaspora',
      },
    },
    digg: {
      style: 'digg',
      icon: {
        name: 'fab:digg',
      },
    },
    facebook: {
      style: 'facebook',
      icon: {
        name: 'fab:facebook-f',
      },
    },
    'facebook-messenger': {
      style: 'facebook-messenger',
      icon: {
        name: 'fab:facebook-messenger',
      },
    },
    flipboard: {
      style: 'flipboard',
      icon: {
        name: 'fab:flipboard',
      },
    },
    instagram: {
      style: 'instagram',
      icon: {
        name: 'fab:instagram',
      },
    },
    line: {
      style: 'line',
      icon: {
        name: 'fab:line',
      },
    },
    linkedin: {
      style: 'linkedin',
      icon: {
        name: 'fab:linkedin-in',
      },
    },
    pinterest: {
      style: 'pinterest',
      icon: {
        name: 'fab:pinterest-p',
      },
    },
    pocket: {
      style: 'pocket',
      icon: {
        name: 'fab:get-pocket',
      },
    },
    qq: {
      style: 'qq',
      icon: {
        name: 'fab:qq',
      },
    },
    reddit: {
      style: 'reddit',
      icon: {
        name: 'fab:reddit-alien',
      },
    },
    renren: {
      style: 'renren',
      icon: {
        name: 'fab:renren',
      },
    },
    sinaweibo: {
      style: 'sinaweibo',
      icon: {
        name: 'fab:weibo',
      },
    },
    skype: {
      style: 'skype',
      icon: {
        name: 'fab:skype',
      },
    },
    sms: {
      style: 'sms',
      icon: {
        name: 'fas:comment-sms',
      },
    },
    telegram: {
      style: 'telegram',
      icon: {
        name: 'fab:telegram',
      },
    },
    tiktok: {
      style: 'tiktok',
      icon: {
        name: 'fab:tiktok',
      },
    },
    tumblr: {
      style: 'tumblr',
      icon: {
        name: 'fab:tumblr',
      },
    },
    twitch: {
      style: 'twitch',
      icon: {
        name: 'fab:twitch',
      },
    },
    twitter: {
      style: 'twitter',
      icon: {
        name: 'fab:x-twitter',
      },
    },
    viber: {
      style: 'viber',
      icon: {
        name: 'fab:viber',
      },
    },
    vk: {
      style: 'vk',
      icon: {
        name: 'fab:vk',
      },
    },
    whatsapp: {
      style: 'whatsapp',
      icon: {
        name: 'fab:whatsapp',
      },
    },
    xing: {
      style: 'xing',
      icon: {
        name: 'fab:xing',
      },
    },
    youtube: {
      style: 'youtube',
      icon: {
        name: 'fab:youtube',
      },
    },
  };

  /**
   * The store.
   */
  private store = createStore(
    {
      name: `social-sharing-button-${uniqueId()}`,
    },
    withProps<SocialSharingButtonModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: ISocialSharingButton) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`SocialSharingButton`, `configure`, `No configuration provided but this is required.`, `config`);
    }

    if (!configuration.platform) {
      throw createDigitaServiceError(`SocialSharingButton`, `configure`, `No "platform" provided but this is required.`, `config`);
    }

    const platformDetails = SocialSharingButtonRepository.platforms[configuration.platform];

    if (!platformDetails) {
      throw createDigitaServiceError(
        `SocialSharingButton`,
        `configure`,
        `The platform (${configuration.platform}) is not recognized.`,
        `config`,
      );
    }

    const platformStyle = platformDetails.style;
    const platformIcon = platformDetails.icon;

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.link = configuration.link;
        state.snackbar = configuration.snackbar;
        state.platform = configuration.platform;
        state.platformStyle = platformStyle;
        state.platformIcon = platformIcon;
      }),
    );
  }

  /**
   * Initializes the store with the provided configuration.
   *
   * @param type - The type of sharing button
   */
  applyType(type: 'share' | 'homepage' = 'share') {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.type = type;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((value) => value),
  );

  /**
   * The Platform Configured by the user.
   */
  private _platform$ = this.store.pipe(select((state) => state.platform));

  /**
   * The Icon of the Platform.
   */
  private _platformStyle$ = this.store.pipe(select((state) => state.platformStyle));

  /**
   * The Style of the Platform
   */
  private _platformIcon$ = this.store.pipe(select((platform) => platform.platformIcon));

  /**
   * The type of Social Sharing.
   */
  private _type$ = this.store.pipe(select((state) => state.type));

  /**
   * Is there a Link to Share.
   */
  link$ = this.store.pipe(select((state) => state.link));

  /**
   * The Snackbar Configuration.
   */
  snackbar$ = this.store.pipe(select((state) => state.snackbar));

  /**
   * Copy to Clipboard
   */
  copyToClipboard$ = combineLatest([this.link$, this._platform$, this.snackbar$]).pipe(
    map(([link, platform, snackbar]) => {
      return {
        link,
        platform,
        snackbar,
      };
    }),
  );

  /**
   * The Template Data
   */
  templateData$ = this._configured$.pipe(
    switchMap(() => ElfCombineQueries([this.link$, this._platformStyle$, this._type$, this._platformIcon$])),
    map(([link, platformStyle, type, platformIcon]) => ({ link, platformStyle, type, platformIcon })),
  );
}
