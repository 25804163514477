import { Injectable } from '@angular/core';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';
import { ImageLoaderService } from '../../../services/image-loader.service';
import { ImageRepository } from './image.repository';

/**
 * The Image Service is used by each instance of an {@link ImageComponent}.
 */
@Injectable()
export class ImageService {
  /**
   * Constructor
   */
  constructor(
    private readonly imageLoaderService: ImageLoaderService,
    private readonly imageRepository: ImageRepository,
  ) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initialize the Image Store from an {@link IImage} json configuration.
   *
   * @param configuration - the imagine configuration provided by the server.
   */
  initialize(configuration?: IImage) {
    this.imageRepository.applyInitialize(configuration);
  }

  ////////////////////////////////////////////////////////////////////
  // IMAGE LOADING
  ////////////////////////////////////////////////////////////////////

  /**
   * Instruct the Image Loader Service to load the following URL.
   *
   * @param src - the url of the image to load
   */
  imageLoadingRequest(src: string) {
    return this.imageLoaderService.loadImageToBlob(src);
  }

  /**
   * The Component can disable Loading Progress manually.
   */
  disableLoadingProgress() {
    this.imageRepository.disableLoadingProgress();
  }

  ////////////////////////////////////////////////////////////////////
  // IMAGE DATA
  ////////////////////////////////////////////////////////////////////

  imageData(imageData: string) {
    this.imageRepository.applyImageData(imageData);
  }

  ////////////////////////////////////////////////////////////////////
  // ERROR
  ////////////////////////////////////////////////////////////////////

  error() {
    this.imageRepository.applyError();
  }
}
