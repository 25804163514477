import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { EMPTY, delay, of, switchMap } from 'rxjs';
import { IMediaLock } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { MediaLockModel } from './media-lock.model';

/**
 * The Default State
 */
function initialState(): MediaLockModel {
  return {
    configured: false,
    error: false,
    unlocked: false,
    lock: undefined,
    button: undefined,
  };
}

/**
 * The Store used for a {@link MediaLockComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaLockRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `media-lock-${uniqueId()}`,
    },
    withProps<MediaLockModel>(initialState()),
  );

  constructor(
    private readonly loggerService: LoggerService,
    protected readonly mediaObserver: MediaObserver,
  ) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IMediaLock) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`MediaLock`, `configure`, `No configuration provided but this is required.`, `config`);
    }

    // if there is no lock then that is an error
    const lock = configuration.lock;
    if (!lock) {
      throw createDigitaServiceError(`MediaLock`, `configure`, `No "lock" property provided but this is required.`, `config`);
    }

    // if there is no button then that is an error
    const button = configuration.button;
    if (!button) {
      throw createDigitaServiceError(`MediaLock`, `configure`, `No "button" property provided but this is required.`, `config`);
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.button = button;
        state.lock = lock;
      }),
    );
  }

  /**
   * An error Occurred.
   */
  applyError() {
    this.loggerService.error(`Media Error - Unlocked`);
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.unlocked = true;
        state.error = true;
      }),
    );
  }

  /**
   * Occurs when the Media Lock is unlocked.
   */
  applyUnlock() {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.unlocked = true;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  configured$ = this.store.pipe(select((state) => state.configured));

  /**
   * What is the Media Lock Configuration?
   */
  lock$ = this.store.pipe(select((state) => state.lock));

  /**
   * Has an error occurred?
   */
  error$ = this.store.pipe(select((state) => state.error));

  /**
   * Is the Media Unlocked?
   */
  private _unlocked$ = this.store.pipe(
    select((state) => state.unlocked),
    delay(500),
  );

  /**
   * The Button Configuration.
   */
  private _button$ = this.store.pipe(select((state) => state.button));

  /**
   * The Button Configuration becomes avilaible once the Media has been unlocked.
   *
   * Assuming there is no errors.
   */
  button$ = ElfCombineQueries([this._unlocked$, this.error$, this._button$]).pipe(
    switchMap(([unlocked, error, button]) => {
      if (unlocked || error) {
        return of(button);
      }
      return EMPTY;
    }),
  );
}
