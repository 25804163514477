@if (pluginRepository.templateData$ | async; as templateData) {
  <div id="container">
    <!-- The loading indicator is diplayed during loading. -->
    @if (templateData.displayLoading) {
      <div id="loading-container">
        <div id="loading" fxLayout="column" fxLayoutAlign="center center">
          <mat-spinner id="loading-spinner"></mat-spinner>
        </div>
      </div>
    }
    <!-- The content is displayed after loading.-->
    <div
      class="content"
      [@fadeIn]="templateData.displayContent ? 'visible' : 'hidden'"
      [style.position]="templateData.displayContent ? 'static' : 'relative'"
    >
      @if (pluginRepository.databox$ | async) {
        <app-databox-container #databox [config]="pluginRepository.databox$ | async"></app-databox-container>
      }
      <div class="stack" [style.height]="templateData.contentHeight" [class.mobile-full-width]="templateData.fullWidth">
        <app-plugin-text #text></app-plugin-text>
        <iframe id="iframe" #iframe> </iframe>
      </div>
    </div>
  </div>
}
