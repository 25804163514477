import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { delay, filter, Subscription } from 'rxjs';
import { ILeaderboardEntry } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboardEntry';
import { FaIconComponent } from '../../../../../icons/components/fa-icon/fa-icon.component';
import { LeaderboardEntryRepository } from './leaderboard-entry.repository';

@Component({
  selector: 'tr[app-leaderboard-entry]',
  templateUrl: './leaderboard-entry.component.html',
  styleUrls: ['./leaderboard-entry.component.scss'],
  animations: [],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FaIconComponent, AsyncPipe],
  providers: [LeaderboardEntryRepository],
})
export class LeaderboardEntryComponent implements AfterViewInit, OnDestroy {
  /**
   * The Config as provided to the element
   */
  @Input() set config(configuration: ILeaderboardEntry) {
    this.leaderboardEntryRepository.applyInitialize(configuration);
  }

  /**
   * The Header Count
   */
  @Input() set headerCount(count: number) {
    this.leaderboardEntryRepository.applyHeaderCount(count);
  }

  /**
   * Used for teardown
   */
  private hasImportantChangeSubscription?: Subscription;

  /**
   * Constructor
   */
  constructor(protected readonly leaderboardEntryRepository: LeaderboardEntryRepository) {}

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // inform the repository that the component has been rendered
    this.leaderboardEntryRepository.applyRendered();

    // listen out for when an important change has occurred
    this.hasImportantChangeSubscription = this.leaderboardEntryRepository.hasImportantChange$
      .pipe(
        filter((hasImportantChange) => hasImportantChange),
        delay(1000),
      )
      .subscribe(() => {
        this.leaderboardEntryRepository.appyHighlightComplete();
      });
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.hasImportantChangeSubscription?.unsubscribe();
  }
}
