import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, map, switchMap } from 'rxjs';
import { ISocialSharing } from 'src/app/api/modules/core/dynamic/components/ISocialSharing';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { SocialSharingModel } from './social-sharing.model';

/**
 * The Default State
 */
function initialState(): SocialSharingModel {
  return {
    configured: false,
    componentArray: [],
    type: 'share',
  };
}

/**
 * The Store used for a {@link SocialSharingComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class SocialSharingRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `social-sharing-${uniqueId()}`,
    },
    withProps<SocialSharingModel>(initialState()),
  );

  /**
   * Constructor
   *
   * @param deviceDetector - The device detector service.
   * @param logger - The logger service.
   */
  constructor(
    private readonly deviceDetector: DeviceDetectorService,
    private readonly logger: LoggerService,
  ) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: ISocialSharing) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`SocialSharing`, `configure`, `No configuration provided but this is required.`, `config`);
    }

    // if there is no component array then return
    if (!configuration.componentArray || !configuration.componentArray.length) {
      return;
    }

    let filteredSharingData = cloneDeep(configuration.componentArray);

    //  some social entities only support homepages
    filteredSharingData = filteredSharingData.filter((shareData) => {
      switch (shareData.platform) {
        case 'twitch':
        case 'tiktok':
          if (configuration.type === 'share') {
            this.logger.warn(`[Social Sharing] - the ${shareData.platform} platform does not support content sharing.`);
            return false;
          }
      }
      return true;
    });

    // some social entries don't support desktop
    if (!this.deviceDetector.isMobile()) {
      filteredSharingData = filteredSharingData.filter((shareData) => {
        switch (shareData.platform) {
          case 'instagram':
          case 'sms':
          case 'viber':
          case 'facebook-messenger':
            // homepage types are always allowed though since they are just links
            if (configuration.type !== 'homepage') {
              return false;
            }
        }
        return true;
      });
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.componentArray = filteredSharingData;
        state.type = configuration.type || 'share';
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((value) => value),
  );

  /**
   * The entries of the Social Sharing.
   */
  private _componentArray$ = this.store.pipe(select((state) => state.componentArray));

  /**
   * The type of Social Sharing.
   */
  private _type$ = this.store.pipe(select((state) => state.type));

  /**
   * Is the Social Sharing a Homepage.
   */
  private _isHomepage$ = this._type$.pipe(map((type) => type === 'homepage'));

  /**
   * Is the Social Sharing a Share.
   */
  private _isShare$ = this._type$.pipe(map((type) => type === 'share'));

  /**
   * The Template Data
   */
  templateData$ = this._configured$.pipe(
    switchMap(() => ElfCombineQueries([this._componentArray$, this._type$, this._isHomepage$, this._isShare$])),
    map(([componentArray, type, isHomepage, isShare]) => ({ componentArray, type, isHomepage, isShare })),
  );
}
