import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { selectAllEntities, setEntitiesMap, withEntities } from '@ngneat/elf-entities';
import { cloneDeep, uniqueId } from 'lodash-es';
import { EMPTY, map, of, switchMap } from 'rxjs';
import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { ILeaderboard, ILeaderboardFilter } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboard';
import { ILeaderboardCell } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboardCell';
import { ILeaderboardEntry } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboardEntry';
import { ILeaderboardDataResponse } from 'src/app/api/modules/core/dynamic/leaderboard/network/ILeaderboardDataResponse';
import { IPollingScheduleData } from 'src/app/api/modules/core/network/IPollingScheduleData';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { LeaderboardModel } from './leaderboard.model';

/**
 * The Default State
 */
function initialState(): LeaderboardModel {
  return {
    isLoading: true,
    headers: [],
    schedule: undefined,
    title: undefined,
    isDebug: false,
    filters: [],
    activeFilters: {},
  };
}

/**
 * The Store used for an {@link LeaderboardComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class LeaderboardRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `leaderboard-${uniqueId()}`,
    },
    withProps<LeaderboardModel>(initialState()),
    withEntities<ILeaderboardEntry>(),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyInitialize(configuration?: Partial<ILeaderboard>) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`Leaderboard`, `applyInitialize`, `No configuration provided but this is required.`, `config`);
    }

    // filters
    let filters: ILeaderboardFilter[] | undefined = undefined;
    const activeFilters: Record<string, string> = {};
    if (configuration.filters && configuration.filters.length > 0) {
      filters = cloneDeep(configuration.filters);
      for (let i = 0; i < filters.length; i++) {
        const targetFilter = filters[i];
        if (typeof targetFilter.key !== 'string' || targetFilter.key.length === 0) {
          throw createDigitaServiceError(
            `Leaderboard`,
            `initialize`,
            `The "key" property provided is not a string or is empty but a key is required for all filters.`,
            `config`,
          );
        }
        if (!targetFilter.options || targetFilter.options.length === 0) {
          throw createDigitaServiceError(
            `Leaderboard`,
            `initialize`,
            `The "options" property provided is not an array or is empty but options are required for all filters.`,
            `config`,
          );
        }
        if (!targetFilter.selected || targetFilter.selected.length === 0) {
          throw createDigitaServiceError(
            `Leaderboard`,
            `initialize`,
            `The "selected" property must be provided for each filter.`,
            `config`,
          );
        }
        let foundSelected = false;
        for (let j = 0; j < targetFilter.options.length; j++) {
          const option = targetFilter.options[j];
          if (option.value === targetFilter.selected) {
            foundSelected = true;
            break;
          }
        }
        if (!foundSelected) {
          throw createDigitaServiceError(
            `Leaderboard`,
            `initialize`,
            `The "selected" property provided is not in the list of options but must match one of their values.`,
            `config`,
          );
        }

        activeFilters[targetFilter.key] = targetFilter.selected;
      }
    }

    // title
    let title: ITextRich | undefined = undefined;
    if (configuration.title) {
      title = cloneDeep(configuration.title);
    }

    // headers
    let headers: ILeaderboardCell[] = [];
    if (configuration.headers) {
      if (configuration.headers.length === 0) {
        throw createDigitaServiceError(
          `Leaderboard`,
          `applyInitialize`,
          `No headers were provided but at least one header is required.`,
          `config`,
        );
      }
      headers = cloneDeep(configuration.headers);
    } else {
      throw createDigitaServiceError(`Leaderboard`, `applyInitialize`, `No headers were provided but are required.`, `config`);
    }

    // schedule
    let schedule: IPollingScheduleData;
    if (configuration.schedule) {
      schedule = cloneDeep(configuration.schedule);
      if (!schedule.interval) {
        schedule.interval = 0;
      }
      if (!schedule.onDataAPI) {
        throw createDigitaServiceError(
          `Leaderboard`,
          `applyInitialize`,
          `The "schedule.onDataAPI" property was not provided but is required.`,
          `config`,
        );
      }
    }

    // deprecated
    if (configuration.belongsToUserColor) {
      console.warn(
        `[Leaderboard]: The "belongsToUserColor" property is deprecated. Use "theme.custom.--ds-leaderboard-cell-user-highlight" instead.`,
      );
    }
    if (configuration.changeHighlightColor) {
      console.warn(
        `[Leaderboard]: The "changeHighlightColor" property is deprecated. Use "theme.custom.--ds-leaderboard-cell-change-highlight" instead.`,
      );
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.filters = filters;
        state.activeFilters = activeFilters;
        state.title = title;
        state.headers = headers;
        state.schedule = schedule;
      }),
    );
  }

  /**
   * Is the Leaderboard in Debug Mode?
   *
   * @param isDebug - true if debug mode is enabled, false otherwise.
   */
  applyIsDebug(isDebug: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.isDebug = isDebug;
      }),
    );
  }

  /**
   * Applies a the latest selected filter.
   *
   * @param filters - the filters to apply
   */
  applyUpdateFilters(filters?: Record<string, string>) {
    const { schedule } = this.store.getValue();

    const newSchedule = cloneDeep(schedule);
    newSchedule.interval = 0;

    this.store.update(
      ElfWrite((state) => {
        state.activeFilters = filters;
        state.schedule = newSchedule;
      }),
    );
  }

  /**
   * Updates the leaderboard from the polling system.
   *
   * @param response - the information returned from the polling request.
   */
  applyLeaderboardUpdateData(response: ILeaderboardDataResponse) {
    // if there is no response then do nothing
    const { title, entities } = this.store.getValue();

    if (!response) {
      return;
    }

    // process the schedule
    let newSchedule: IPollingScheduleData | undefined = undefined;
    if (response.schedule) {
      if (typeof response.schedule.interval === 'number' && typeof response.schedule.onDataAPI === 'string') {
        newSchedule = cloneDeep(response.schedule);
      }
    }

    // process the entries but only if not animating
    let newEntries = cloneDeep(entities);
    if (response.data) {
      newEntries = {};
      response.data.forEach((entry) => {
        newEntries[entry.id] = cloneDeep(entry);
        if (entry.belongsToUser && entry.changed) {
          entry.changed = false;
        }
      });
    }

    // process the title
    let newTitle = cloneDeep(title);
    if (response.title) {
      newTitle = cloneDeep(response.title);
    }

    this.store.update(
      ElfWrite((state) => {
        state.schedule = newSchedule;
        state.title = newTitle;
        state.isLoading = false;
      }),
      setEntitiesMap(newEntries),
    );
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERY
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the leaderboard loading data?
   */
  private _isLoading$ = this.store.pipe(select((state) => state.isLoading));

  /**
   * Retrieve the entries data for the leaderboard.
   */
  private _entries$ = this.store.pipe(selectAllEntities());

  /**
   * Show the loading indicator if the system is loading and there are no entries.
   */
  private _showLoadingIndicator$ = ElfCombineQueries([this._isLoading$, this._entries$]).pipe(
    map(([isLoading, entries]) => isLoading && (!entries || entries.length === 0)),
  );

  /**
   * Show the leaderboard.
   */
  private _showLeaderboard$ = this._showLoadingIndicator$.pipe(map((showLoadingIndicator) => !showLoadingIndicator));

  /**
   * What is the Leaderboard Title should it exist?
   */
  private _title$ = this.store.pipe(select((state) => state.title));

  /**
   * Does the Leaderboard have a Title?
   */
  private _hasTitle$ = this._title$.pipe(map((title) => !!title));

  /**
   * Retrieve the headers data for the leaderboard.
   */
  private _headers$ = this.store.pipe(select((state) => state.headers));

  // get the schedule
  private _schedule$ = this.store.pipe(select((state) => state.schedule));

  // get the debug mode
  private _isDebug$ = this.store.pipe(select((state) => state.isDebug));

  /**
   * The filters property.
   */
  private _filters$ = this.store.pipe(select((state) => state.filters));

  /**
   * The active filters property.
   */
  activeFilters$ = this.store.pipe(select((state) => state.activeFilters));

  /**
   * Does the system have filters?
   */
  hasFilters$ = this._filters$.pipe(map((filters) => filters?.length > 1));

  /**
   * Combine the schedule, debug mode, and animation state.
   */
  scheduleSettings$ = ElfCombineQueries([this._schedule$, this._isDebug$, this.activeFilters$]).pipe(
    switchMap(([schedule, isDebug, filters]) => {
      // Ignore if schedule is undefined, or in debug mode, or if onDataAPI is not set.
      if (!schedule || isDebug || !schedule.onDataAPI) {
        return EMPTY;
      }

      // Set interval to a minimum of 1000ms if not a valid positive number.
      const onInterval = Math.max(schedule.interval || 1000, 1000);

      // Return the necessary details for further processing.
      return of({
        filters,
        onDataAPI: schedule.onDataAPI,
        onInterval,
      });
    }),
  );

  /**
   * The Template Data
   */
  templateData$ = ElfCombineQueries([
    this._isLoading$,
    this._showLoadingIndicator$,
    this._showLeaderboard$,
    this._hasTitle$,
    this._title$,
    this._filters$,
    this._headers$,
    this._entries$,
  ]).pipe(
    map(([isLoading, showLoadingIndicator, showLeaderboard, hasTitle, title, filters, headers, entries]) => {
      return {
        isLoading,
        showLoadingIndicator,
        showLeaderboard,
        hasTitle,
        title,
        filters,
        headers,
        entries,
      };
    }),
  );
}
