/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { LoggerService } from '@angular-ru/cdk/logger';
import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import {
  MatAnchor,
  MatButton,
  MatFabAnchor,
  MatFabButton,
  MatIconAnchor,
  MatIconButton,
  MatMiniFabAnchor,
  MatMiniFabButton,
} from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { AppService } from '../../../services/application/application.service';
import { DialogService } from '../../../services/dialog.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ButtonRepository } from './button.repository';
import { ButtonService } from './button.service';

/**
 * The Majority of Buttons (and all JSON based buttons and links), use {@link ButtonComponent}.
 *
 * See {@link IButton}
 */
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [ButtonService, ButtonRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatAnchor,
    MatIcon,
    MatIconAnchor,
    MatFabAnchor,
    MatMiniFabAnchor,
    MatButton,
    MatIconButton,
    MatFabButton,
    MatMiniFabButton,
    AsyncPipe,
  ],
})
export class ButtonComponent implements OnDestroy {
  /**
   * The configuration powering this component.
   *
   * See {@link IButton}
   */
  private _config?: IButton;
  @Input()
  set config(configuration: IButton) {
    this._config = configuration;
    this.buttonService.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Is the button disabled?
   *
   * See {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button}
   */
  private _disabled = false;
  @Input()
  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
    this.buttonService.applyDisabled(isDisabled);
  }
  get disabled() {
    return this._disabled;
  }

  /**
   * If this button belongs to a form, provide the string name of that form
   * so that `onSubmit` may be triggered.
   *
   * See {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button}
   */
  private _form?: string;
  @Input()
  set form(formName: string) {
    this._form = formName;
    this.buttonService.applyForm(formName);
  }
  get form() {
    return this._form;
  }

  /**
   * Override the `click` method.
   */
  @Output() buttonClick = new EventEmitter<MouseEvent>();

  /**
   * Buttons are able to open Dialogs. Any open dialogs are stored here.
   */
  private dialog?: MatDialogRef<any>;

  /**
   * The Subscription is used for all Observable Paths and stored for Teardown.
   */
  private subscription?: Subscription;

  /**
   * Constructor
   */
  constructor(
    private readonly appService: AppService,
    private readonly dialogService: DialogService,
    private readonly loggerService: LoggerService,
    private readonly snackbarService: SnackbarService,
    private readonly clipboard: Clipboard,
    private readonly buttonService: ButtonService,
    public readonly buttonRepository: ButtonRepository,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    // kill the subscription
    this.subscription?.unsubscribe();

    // kill any dialog
    this.dialog?.close(false);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // TEMPLATE EVENTS
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * onClick acts as an interceptor to the native button click, since dialogs
   * may be confirmation (such as "do you want to leave?"). The button click action
   * may be separated. Meaning if they select "No", the outer system doesn't recieve
   * the original click.
   *
   * @param event - the native event dispatched from the button
   */
  onClick(event: MouseEvent) {
    // register the input
    this.appService.registerFirstInteraction();

    // cancel any existing button subscription
    this.subscription?.unsubscribe();

    // gather the requirements for this button
    this.subscription = this.buttonRepository.clickAction$
      .pipe(
        // we only need one of these
        take(1),
      )
      .subscribe((data) => {
        // this is no longer required
        this.subscription?.unsubscribe();

        // there are multiple pathways for the button click depending on the action
        const { type, hasDialog, hasFeature, isDisabled, hasClickToCloseFeature, hasCopyToClipboardFeature, hasPrintFeature } = data;

        // if the button is disabled then nothing will happen
        if (isDisabled) {
          this.loggerService.info(`[Button] - The button is disabled. No interaction will occur.`);
        } else {
          // if the type is a link
          if (type === 'link') {
            // it can be processed
            this.processLink(event);
          } else {
            // if the type is a button then it can be several things
            if (hasDialog) {
              // it can be processed for dialog
              this.processDialog(event);
            } else if (hasFeature) {
              // it can be processed for feature, but what type?
              if (hasClickToCloseFeature) {
                // it can be processed for click to close feature
                this.processClickToCloseFeature(event);
              }
              if (hasCopyToClipboardFeature) {
                // it can be processed for copy to clipboard feature
                this.processCopyToClipboardFeature(event);
              }
              if (hasPrintFeature) {
                // it can be processed for print feature
                this.processPrintFeature(event);
              }
            } else {
              // it can be processed for a standard click
              this.processButton(event);
            }
          }
        }
      });
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // LINKING
  /////////////////////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the button is configured as type `link` which means the user is
   * navigating to a URL.
   *
   * @param event - the native event dispatched from the button
   */
  private processLink(event: MouseEvent) {
    // kill any existing subscription
    this.subscription?.unsubscribe();

    // prevent any default behavior on the event
    event.preventDefault();

    // get the information about the link and navigate to it
    this.subscription = this.buttonRepository.link$.pipe(take(1)).subscribe((link) => {
      // kill the subscription
      this.subscription?.unsubscribe();

      // navigate to the link
      this.appService.openLink(link);
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // DIALOG
  /////////////////////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the button is configured as type `button` and has a dialog.
   *
   * @param event - the native event raised from the button.
   */
  private processDialog(event: MouseEvent) {
    // cancel any existing subscription
    this.subscription?.unsubscribe();

    // get the dialog information
    this.subscription = this.buttonRepository.dialog$
      .pipe(
        // we only need one
        take(1),
        // switch to a new observable for the dialog when it closes
        switchMap((dialog) => {
          this.dialog = this.dialogService.openDialog(dialog);
          return this.dialog.afterClosed();
        }),
        // we only need one close event
        take(1),
      )
      .subscribe((result) => {
        // kill the subscription
        this.subscription?.unsubscribe();

        // the dialog is nulled
        this.dialog = undefined;

        // if the result is true proceed with the event
        // otherwise the user clicked on negative and nothing
        // else will occur.
        if (result) {
          // pass the event to the click action
          this.processButton(event);
        }
      });
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // FEATURES
  /////////////////////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the button is configured as type `button` and has a clickToClose feature.
   *
   * @param event - the native event raised from the button.
   */
  private processClickToCloseFeature(event: MouseEvent) {
    // kill any existing subscription
    this.subscription?.unsubscribe();

    // close
    this.appService.registerClose();
  }

  /**
   * Occurs when the button is configured as type `button` and has a copyToClipboard feature.
   *
   * @param event - the native event raised from the button.
   */
  private processCopyToClipboardFeature(event: MouseEvent) {
    // kill any existing subscription
    this.subscription?.unsubscribe();

    // prevent any defaults
    event.preventDefault();
    event.stopImmediatePropagation();

    // get the information about the feature
    this.subscription = this.buttonRepository.copyToClipboard$.pipe(take(1)).subscribe((copyToClipboard) => {
      // kill the subscription
      this.subscription?.unsubscribe();

      // copy to clipboard logic will optionally show a complete snackbar
      const pending = this.clipboard.beginCopy(copyToClipboard.data);
      let remainingAttempts = 5;
      const attempt = () => {
        const result = pending.copy();
        if (!result && --remainingAttempts) {
          document.defaultView.setTimeout(attempt);
        } else {
          pending.destroy();
          if (copyToClipboard.snackbar) {
            this.snackbarService.open(copyToClipboard.snackbar);
          }
        }
      };
      attempt();
    });
  }

  /**
   * Occurs when the button is configured as type `button` and has a print feature.
   *
   * @param event - the native event raised from the button.
   */
  private processPrintFeature(event: MouseEvent) {
    if (this.document?.defaultView) {
      this.document.defaultView.print();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // CLICK
  /////////////////////////////////////////////////////////////////////////////////////

  /**
   * If the type is button and it has no features or dialog (or the dialog has complete)
   * then we can dispatch the click event.
   *
   * @param event - the native event raised from the button.
   */
  private processButton(event: MouseEvent) {
    // cancel any existing subscription
    this.subscription?.unsubscribe();

    // emit the click event
    this.buttonClick.emit(event);
  }
}
