@if (socialSharingRepository.templateData$ | async; as templateData) {
  <!-- When a social component is displayting a list of share buttons -->
  @if (templateData.isShare) {
    @if (templateData.componentArray; as componentArray) {
      <div fxLayout="row wrap" fxLayoutGap="4px grid" fxLayoutAlign="center center">
        @for (component of componentArray; track component.platform) {
          <app-social-sharing-button [config]="component" type="share"></app-social-sharing-button>
        }
      </div>
    }
  }
  <!-- When a social component is displaying a list of homepage buttons -->
  @if (templateData.isHomepage) {
    @if (templateData.componentArray; as componentArray) {
      <div fxLayout="row wrap" fxLayoutGap="4px grid" fxLayoutAlign="space-between stretch">
        @for (component of componentArray; track component.platform) {
          <app-social-sharing-button [config]="component" type="homepage" fxFlex="auto"></app-social-sharing-button>
        }
      </div>
    }
  }
}
