import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { CountdownSegments } from '../countdown.model';
import { countdownFlipBottomAnimation, countdownFlipTopAnimation } from './countdown-section.animations';

/**
 * A Countdown Section is used by a {@link CountdownComponent} to display a single digit from 0-9.
 */
@Component({
  selector: 'app-countdown-section',
  templateUrl: './countdown-section.component.html',
  styleUrls: ['./countdown-section.component.scss'],
  animations: [countdownFlipTopAnimation, countdownFlipBottomAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule],
})
export class CountdownSectionComponent {
  /**
   * The configuration
   */
  @Input() config?: CountdownSegments;
}
