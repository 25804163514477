@if (pluginTextRepository.entities$ | async; as entities) {
  <div>
    @for (entity of entities | keyvalue; track trackById($index, entity)) {
      <app-plugin-text-entity
        [config]="entity.value"
        [id]="entity.key"
        (animationCompleted)="entityCompleted($event)"
      ></app-plugin-text-entity>
    }
  </div>
}
