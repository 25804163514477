@if (buttonContainerRepository.templateData$ | async; as templateData) {
  <div
    [fxLayout]="templateData.layout"
    [fxLayoutAlign]="templateData.layoutAlign"
    [class.fixed-size]="templateData.isFixedSize"
    [class.full-size]="templateData.isFullSize"
  >
    <ng-template appDynamicContent></ng-template>
  </div>
}
