@if (leaderboardRepository.templateData$ | async; as templateData) {
  <!-- Show the loading indicator -->
  @if (templateData.showLoadingIndicator) {
    <div class="loading-container" fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner mode="indeterminate"></mat-spinner>
      </div>
    </div>
  }

  <!-- Show the actual leaderboard -->
  @if (templateData.showLeaderboard) {
    <div class="mat-mdc-table-scroller">
      <!-- The title is optional-->
      @if (templateData.hasTitle) {
        <app-text-rich [config]="templateData.title"></app-text-rich>
      }
      <!-- Adds Material Select for optional Filters -->
      @if (form) {
        <form [formGroup]="form" fxLayout.xs="column" fxLayout.gt-xs="row" fxLayoutAlign="space-between center">
          @for (filter of templateData.filters; track filter.key) {
            <mat-form-field class="field">
              @if (filter.label) {
                <mat-label>{{ filter.label }}</mat-label>
              }
              <mat-select [formControlName]="filter.key" [compareWith]="compareWithFn">
                @for (option of filter.options; track option.value) {
                  <mat-option [value]="option.value">
                    {{ option.viewValue }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        </form>
      }
      <!-- The leaderboard itself -->
      <table class="mat-mdc-table">
        <thead>
          <tr>
            @for (item of templateData.headers; track $index) {
              <th>
                <div class="cell-flex">
                  @if (item?.icon) {
                    <app-fa-icon [config]="item.icon"></app-fa-icon>
                  }
                  @if (item?.text) {
                    <span class="mat-body-medium"> {{ item.text }}</span>
                  }
                </div>
              </th>
            }
          </tr>
        </thead>
        <tbody>
          @for (item of templateData.entries; track trackBy($index, item)) {
            <tr
              app-leaderboard-entry
              class="leaderboard-entry"
              [ngStyle]="{
                '--entry-delay': $index * 100 + 'ms',
              }"
              [config]="item"
              [headerCount]="templateData.headers?.length"
            ></tr>
          }
        </tbody>
      </table>
    </div>
  }
}
