/**
 * The default palette.
 *
 * This should match the `src\styles\theme\m3-theme.scss` file.
 *
 * Just ask GPT to convert the SASS Palette to a JSON format.
 */
export const DefaultThemePalette: PalettesSchema = {
  primary: {
    '0': '#000000',
    '10': '#21005e',
    '20': '#390094',
    '25': '#4500b0',
    '30': '#5200ce',
    '35': '#5f00ec',
    '40': '#6b24f9',
    '50': '#8454ff',
    '60': '#9c7aff',
    '70': '#b59cff',
    '80': '#cebdff',
    '90': '#e8ddff',
    '95': '#f5eeff',
    '98': '#fdf7ff',
    '99': '#fffbff',
    '100': '#ffffff',
  },
  secondary: {
    '0': '#000000',
    '10': '#00201f',
    '20': '#003735',
    '25': '#004341',
    '30': '#00504d',
    '35': '#005d59',
    '40': '#006a66',
    '50': '#008581',
    '60': '#00a29c',
    '70': '#00bfb9',
    '80': '#15ddd6',
    '90': '#4ffaf2',
    '95': '#b0fffa',
    '98': '#e3fffc',
    '99': '#f2fffd',
    '100': '#ffffff',
  },
  tertiary: {
    '0': '#000000',
    '10': '#400015',
    '20': '#660026',
    '25': '#7b002f',
    '30': '#900039',
    '35': '#a60042',
    '40': '#ba0c4d',
    '50': '#dd3065',
    '60': '#ff4d7d',
    '70': '#ff869e',
    '80': '#ffb2be',
    '90': '#ffd9de',
    '95': '#ffecee',
    '98': '#fff8f7',
    '99': '#fffbff',
    '100': '#ffffff',
  },
  neutral: {
    '0': '#000000',
    '4': '#060b11',
    '6': '#0a111a',
    '10': '#101c2b',
    '12': '#14202f',
    '17': '#1f2b3a',
    '20': '#253141',
    '22': '#293545',
    '24': '#2e3a4a',
    '25': '#303c4c',
    '30': '#3c4858',
    '35': '#475364',
    '40': '#535f70',
    '50': '#6c788a',
    '60': '#8592a4',
    '70': '#a0acbf',
    '80': '#bbc7db',
    '87': '#cfdbef',
    '90': '#d7e3f8',
    '92': '#dfe9fb',
    '94': '#e6eefe',
    '95': '#eaf1ff',
    '96': '#eff4ff',
    '98': '#f8f9ff',
    '99': '#fdfcff',
    '100': '#ffffff',
  },
  'neutral-variant': {
    '0': '#000000',
    '10': '#1d1929',
    '20': '#322e3f',
    '25': '#3e394b',
    '30': '#494456',
    '35': '#555062',
    '40': '#615b6f',
    '50': '#7a7488',
    '60': '#948da2',
    '70': '#afa8be',
    '80': '#cbc3d9',
    '90': '#e7dff6',
    '95': '#f5eeff',
    '98': '#fdf7ff',
    '99': '#fffbff',
    '100': '#ffffff',
  },
  error: {
    '0': '#000000',
    '10': '#410002',
    '20': '#690005',
    '25': '#7e0007',
    '30': '#93000a',
    '35': '#a80710',
    '40': '#ba1a1a',
    '50': '#de3730',
    '60': '#ff5449',
    '70': '#ff897d',
    '80': '#ffb4ab',
    '90': '#ffdad6',
    '95': '#ffedea',
    '98': '#fff8f7',
    '99': '#fffbff',
    '100': '#ffffff',
  },
};

////////////////////////////////////////////////////////////////////////////////////////
// DO NOT MODIFY BELOW THIS LINE
////////////////////////////////////////////////////////////////////////////////////////

/**
 * The Interface representing a palette entry.
 */
type Palette = Record<string, string>;

/**
 * The Interface representing all Palettes.
 */
export interface PalettesSchema {
  primary: Palette;
  secondary: Palette;
  tertiary: Palette;
  neutral: Palette;
  'neutral-variant': Palette;
  error: Palette;
}
