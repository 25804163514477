/* eslint-disable @typescript-eslint/no-explicit-any */

import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { IButtonContainer } from 'src/app/api/modules/core/dynamic/containers/IButtonContainer';
import { DynamicContentDirective } from 'src/app/modules/shared/directives/dynamic-content/dynamic-content.directive';
import { ContainerFactoryArray } from '../container/container.factory';
import { ButtonContainerRepository } from './button-container.repository';
import { ButtonContainerService } from './button-container.service';

@Component({
  selector: 'app-button-container',
  templateUrl: './button-container.component.html',
  styleUrls: ['./button-container.component.scss'],
  providers: [ButtonContainerService, ButtonContainerRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, DynamicContentDirective, AsyncPipe],
})
export class ButtonContainerComponent implements OnDestroy, AfterViewInit {
  /**
   * The Directive marked to place content dynamically
   */
  @ViewChild(DynamicContentDirective, { static: false }) contentContainer: DynamicContentDirective;

  /**
   * The Screen Configuration
   */
  private _config: IButtonContainer;
  @Input()
  set config(configuration: IButtonContainer) {
    this._config = configuration;
    this.buttonContainerService.initialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Adds a Stacked Class to the host when a column mode is used
   */
  @HostBinding('class.isStacked') get isStacked() {
    if (this.isStackedEnabled) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Hostbinding doesn't accept an observable so we need a property
   * for stacking.
   */
  private isStackedEnabled = false;

  /**
   * Any active descriptions to be destroyed
   */
  private subscriptions = new Subscription();

  /**
   * A Reference to all dynamically created components
   *
   * Used for destroying them or finding them
   */
  private dynamicComponentRefs: ComponentRef<any>[] = [];

  /**
   * Override Constructor
   */
  constructor(
    protected readonly cd: ChangeDetectorRef,
    protected readonly mediaObserver: MediaObserver,
    protected readonly buttonContainerService: ButtonContainerService,
    public readonly buttonContainerRepository: ButtonContainerRepository,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // listen for media changes
    const media = this.mediaObserver
      .asObservable()
      .pipe(
        filter((changes) => changes.length > 0),
        map((changes) => changes[0]),
      )
      .subscribe((change) => {
        this.buttonContainerService.mediaChange(change);
      });
    this.subscriptions.add(media);

    // listen for stack changes
    const stacked = this.buttonContainerRepository.isStacked$.subscribe((val) => {
      this.isStackedEnabled = val;
      this.cd.markForCheck();
    });
    this.subscriptions.add(stacked);

    // process the screen configuration
    const componentSubscription = this.buttonContainerRepository.componentArray$
      .pipe(
        // configure the components
        tap((componentArray) => {
          // create the components
          this.dynamicComponentRefs = this.createComponentsFromArray(componentArray);
        }),
      )
      .subscribe(() => {
        // call change detection
        this.cd.detectChanges();
      });
    this.subscriptions.add(componentSubscription);
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    // destroy all components
    this.dynamicComponentRefs.forEach((item) => {
      item.destroy();
    });
    // clear the array
    this.dynamicComponentRefs = [];

    // unsubscribe from all subscriptions
    this.subscriptions?.unsubscribe();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // PROCESS
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Creates Dynamic Components from an Array.
   *
   * Intended to be overridden.
   *
   * @param componentArray - the component array.
   */
  private createComponentsFromArray(componentArray: IButtonContainer['componentArray']): ComponentRef<any>[] {
    return ContainerFactoryArray(this.contentContainer.viewContainerRef, componentArray);
  }
}
