import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { IDataboxLivesIcon } from 'src/app/api/modules/core/dynamic/databoxes/lives-icon/IDataboxLivesIcon';
import { FaIconComponent } from '../../../../../icons/components/fa-icon/fa-icon.component';
import { DataboxLivesIconRepository } from './databox-lives.repository';

@Component({
  selector: 'app-databox-lives-icon',
  templateUrl: './databox-lives-icon.component.html',
  styleUrls: ['./databox-lives-icon.component.scss'],
  providers: [DataboxLivesIconRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, FaIconComponent, AsyncPipe],
})
export class DataboxLivesIconComponent {
  private _config?: IDataboxLivesIcon;
  /**
   * The Configuration powering the component.
   */
  @Input()
  set config(configuration: IDataboxLivesIcon) {
    this._config = configuration;
    this.databoxLivesIconRepository.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly databoxLivesIconRepository: DataboxLivesIconRepository) {}

  /**
   * Updates the component with the users lives.
   *
   * @param livesRemaining - the amount of lives remaining.
   */
  update(livesRemaining: number) {
    this.databoxLivesIconRepository.applyUpdate(livesRemaining);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.databoxLivesIconRepository.applyUpdate(0);
  }
}
