<!-- The container isn't rendered but an angular placeholder for data -->
@if (imageRepository.data$ | async; as data) {
  <!-- Once Configured, the root level div is always displayed and is responsive -->
  @if (data.isSetup) {
    <div [class.limit-portrait-width-on-desktop]="data.limitPortraitWidthOnDesktop">
      <div
        [@imageGhostExitAnimation]="data.isLoaded"
        [fxFlex]="data.flex"
        [class.ghost]="data.hasGhost"
        [style.border-radius]="data.borderRadius"
        [class.ghostless-error]="!data.hasGhost && data.hasError"
      >
        <div [style.padding-top]="data.aspect" [style.width]="data.wrapperWidth">
          <!-- Show the Progress Spinner if suitable and if not loaded whilst providing the progress -->
          @if (data.showProgress && !data.isLoaded) {
            <mat-progress-spinner
              @progressSpinnerfadeInOut
              class="progress-spinner"
              mode="determinate"
              [value]="data.progress"
            ></mat-progress-spinner>
          }
          <!-- If there is an error -->
          @if (data.hasError) {
            <app-fa-icon class="ghost-icon" [config]="data.errorIcon"></app-fa-icon>
          }
          <!-- If there is no error, the image has loaded, and it DOES NOT HAVE a link -->
          @if (!data.hasError && data.isLoaded && !data.link && data.imageData) {
            <img #img [style.border-radius]="data.borderRadius" [attr.src]="data.imageData" [alt]="data.alt" />
          }
          <!-- If there is no error, the image has loaded, and it has a link -->
          @if (!data.hasError && data.isLoaded && data.link && data.imageData) {
            <app-abstract-link [config]="data.link">
              <img #img [style.border-radius]="data.borderRadius" [attr.src]="data.imageData" [alt]="data.alt" />
            </app-abstract-link>
          }
        </div>
      </div>
    </div>
  }
}
