import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { IDataboxHighScore } from 'src/app/api/modules/core/dynamic/databoxes/highscore/IDataboxHighScore';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DataboxHighScoreModel } from './databox-highscore.model';

/**
 * The Default State
 */
function initialState(): DataboxHighScoreModel {
  return {
    selector: 'app-databox-highscore',
    template: '%SCORE%',
    templateSingular: '%SCORE%',
    templateIdle: '-',
    output: '-',
  };
}

/**
 * The Store used for a {@link DataboxHighScoreComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DataboxHighScoreRepository implements OnDestroy {
  /**
   * The Score String Template variable which is replaced by the users actual score.
   */
  private readonly SCORE = '%SCORE%';

  /**
   * The store.
   */
  private store = createStore(
    {
      name: `databox-highscore-${uniqueId()}`,
    },
    withProps<DataboxHighScoreModel>(initialState()),
  );

  constructor(private readonly logger: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initialize from the configuration.
   */
  applyInitialize(configuration?: Partial<IDataboxHighScore>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    // the template is used to process the numeric values into a formatted score string.
    let template = `${this.SCORE}`;
    if (configuration.template) {
      if (configuration.template.includes(this.SCORE)) {
        template = configuration.template;
      } else {
        this.logger.warn(
          `[DataboxHighScore] applyInitialize - The 'template' must contain a substring of "${this.SCORE}". The default will be used.`,
        );
      }
    }

    // the templateSingular is used to process the numeric values into a formatted score string.
    let templateSingular = `${this.SCORE}`;
    if (configuration.templateSingular) {
      if (configuration.templateSingular.includes(this.SCORE)) {
        templateSingular = configuration.templateSingular;
      } else {
        this.logger.warn(
          `[DataboxHighScore] applyInitialize - The 'templateSingular' must contain a substring of "${this.SCORE}". The default will be used.`,
        );
      }
    }

    // template idle is shown when no acceptable values have been provided
    // or as the default state or during a reset.
    let templateIdle = `-`;
    if (configuration.templateIdle) {
      templateIdle = configuration.templateIdle;
    }

    // the output should be the templateIdle as no values are incoming yet.
    const output = templateIdle;

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.template = template;
        state.templateSingular = templateSingular;
        state.templateIdle = templateIdle;
        state.output = output;
      }),
    );
  }

  /**
   * Splice the data into the template
   *
   * @param score - The score of the user.
   */
  applyUpdate(score?: number) {
    // you can't update the score if there is no value provided
    if (typeof score !== 'number') {
      return;
    }

    // get the configured data
    const { template, templateSingular } = this.store.getValue();

    // the final output
    let finalOutput = '';

    // if there is a score of 1
    if (score === 1) {
      // then the singular template is used.
      finalOutput = templateSingular.slice();
    } else {
      // otherwise the template is used.
      finalOutput = template.slice();
    }

    // the final output is the chosen template with the score replaced.
    finalOutput = finalOutput.replace(this.SCORE, score.toString());

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.output = finalOutput;
      }),
    );
  }

  /**
   * Reset the ouput to the templateIdle value.
   */
  applyReset() {
    const { templateIdle } = this.store.getValue();

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.output = templateIdle;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * The Final Output State.
   */
  output$ = this.store.pipe(select((state) => state.output));
}
