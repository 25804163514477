import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { filter, map, switchMap } from 'rxjs';
import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { TextRichModel } from './text-rich.model';

/**
 * The Default State
 */
function initialState(): TextRichModel {
  return {
    configured: false,
    data: '',
    type: `none`,
  };
}

/**
 * The Store used for a {@link TextRichComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class TextRichRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `text-rich-${uniqueId()}`,
    },
    withProps<TextRichModel>(initialState()),
  );

  /**
   * Constructor
   *
   * @param logger - The logger service.
   */
  constructor(private readonly logger: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: ITextRich) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`TextRich`, `configure`, `No configuration provided but this is required.`, `config`);
    }

    // the type
    let type = configuration.type || `none`;

    // DEPRECATED: the old types will be removed in future
    switch (type) {
      case `mat-display-4`:
        this.logger.warn(`[TextRich] - The type "mat-display-4" is deprecated and should be replaced with "display-large".`);
        type = `display-large`;
        break;
      case `mat-display-3`:
        this.logger.warn(`[TextRich] - The type "mat-display-3" is deprecated and should be replaced with "display-medium".`);
        type = `display-medium`;
        break;
      case `mat-display-2`:
        this.logger.warn(`[TextRich] - The type "mat-display-2" is deprecated and should be replaced with "display-small".`);
        type = `display-small`;
        break;
      case `mat-display-1`:
        this.logger.warn(`[TextRich] - The type "mat-display-1" is deprecated and should be replaced with "headline-large".`);
        type = `headline-large`;
        break;
      case `mat-headline`:
        this.logger.warn(`[TextRich] - The type "mat-headline" is deprecated and should be replaced with "headline-small".`);
        type = `headline-small`;
        break;
      case `mat-title`:
        this.logger.warn(`[TextRich] - The type "mat-title" is deprecated and should be replaced with "title-large".`);
        type = `title-large`;
        break;
      case `mat-subheading-2`:
        this.logger.warn(`[TextRich] - The type "mat-subheading-2" is deprecated and should be replaced with "title-small".`);
        type = `title-small`;
        break;
      case `mat-subheading-1`:
        this.logger.warn(`[TextRich] - The type "mat-subheading-1" is deprecated and should be replaced with "title-medium".`);
        type = `title-medium`;
        break;
      case `mat-body-1`:
        this.logger.warn(`[TextRich] - The type "mat-body-1" is deprecated and should be replaced with "body-large".`);
        type = `body-large`;
        break;
      case `mat-body-2`:
        this.logger.warn(`[TextRich] - The type "mat-body-2" is deprecated and should be replaced with "body-medium".`);
        type = `body-medium`;
        break;
      case `mat-body-strong`:
        this.logger.warn(`[TextRich] - The type "mat-body-strong" is deprecated and should be replaced with "label-large".`);
        type = `label-large`;
        break;
      case `mat-caption`:
        this.logger.warn(`[TextRich] - The type "mat-caption" is deprecated and should be replaced with "body-small".`);
        type = `body-small`;
        break;
      case `mat-small`:
        this.logger.warn(`[TextRich] - The type "mat-small" is deprecated and should be replaced with "body-small".`);
        type = `body-small`;
        break;
      case `mat-h1`:
        this.logger.warn(`[TextRich] - The type "mat-h1" is deprecated and should be replaced with "display-large".`);
        type = `display-large`;
        break;
      case `mat-h2`:
        this.logger.warn(`[TextRich] - The type "mat-h2" is deprecated and should be replaced with "display-medium".`);
        type = `display-medium`;
        break;
      case `mat-h3`:
        this.logger.warn(`[TextRich] - The type "mat-h3" is deprecated and should be replaced with "display-medium".`);
        type = `display-medium`;
        break;
      case `mat-h4`:
        this.logger.warn(`[TextRich] - The type "mat-h4" is deprecated and should be replaced with "display-small".`);
        type = `display-small`;
        break;
    }

    if (type) {
      switch (type) {
        case `display-large`:
        case `display-medium`:
        case `display-small`:
        case `headline-large`:
        case `headline-medium`:
        case `headline-small`:
        case `title-large`:
        case `title-medium`:
        case `title-small`:
        case `body-large`:
        case `body-medium`:
        case `body-small`:
        case `label-large`:
        case `label-medium`:
        case `label-small`:
        case `none`:
          break;
      }
    }

    // the data
    let data = '';
    if (typeof configuration.data === 'string') {
      data = configuration.data;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.type = type;
        state.data = data;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((configured) => configured),
  );

  /**
   * Get the configured type
   */
  private _type$ = this.store.pipe(select((state) => state.type));

  /**
   * Get the class name depending on the type.
   */
  private _classType$ = this._type$.pipe(
    map((type) => {
      if (type === 'none') {
        return '';
      }
      return `mat-${type}`;
    }),
  );

  /**
   * Get the data.
   */
  private _data$ = this.store.pipe(select((state) => state.data));

  /**
   * The Template Data
   */
  templateData$ = this._configured$.pipe(
    switchMap(() => ElfCombineQueries([this._classType$, this._data$])),
    map(([classType, data]) => {
      return {
        classType,
        data,
      };
    }),
  );
}
