import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { Options as ConfettiOptions } from 'canvas-confetti';
import { cloneDeep, uniqueId } from 'lodash-es';
import { filter, switchMap, take, timer } from 'rxjs';
import { IShellFGEffectsConfettis } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfettis';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ShellFGEffectsConfettisModel } from './shell-fg-effects-confettis.model';

/**
 * The estimated duration of the confetti effect.
 *
 * This will change depending on the configuration.
 */
export const ESTIMATED_CONFETTIS_EFFECT_DURATION = 5000;

// the default options for confetti on the Left
function defaultOptionsLeft(): ConfettiOptions {
  return {
    angle: 45,
    colors: ['#26ccff', '#a25afd', '#ff5e7e', '#88ff5a', '#fcff42', '#ffa62d', '#ff36ff'],
    decay: 0.9,
    disableForReducedMotion: false,
    drift: 1,
    gravity: 1,
    origin: {
      x: 0,
      y: 0.5,
    },
    particleCount: 50,
    scalar: 1,
    shapes: ['square', 'circle'],
    spread: 45,
    startVelocity: 60,
    ticks: 500,
    zIndex: 100,
  };
}

// the default options for confetti on the Right
function defaultOptionsRight(): ConfettiOptions {
  return {
    angle: 135,
    colors: ['#26ccff', '#a25afd', '#ff5e7e', '#88ff5a', '#fcff42', '#ffa62d', '#ff36ff'],
    decay: 0.9,
    disableForReducedMotion: false,
    drift: -1,
    gravity: 1,
    origin: {
      x: 1,
      y: 0.5,
    },
    particleCount: 50,
    scalar: 1,
    shapes: ['square', 'circle'],
    spread: 45,
    startVelocity: 60,
    ticks: 500,
    zIndex: 100,
  };
}

/**
 * The Default State
 */
function initialState(): ShellFGEffectsConfettisModel {
  return {
    ready: false,
    complete: false,
    delayMS: 0,
    effectID: '',
    effect: {
      left: defaultOptionsLeft(),
      right: defaultOptionsRight(),
    },
  };
}

/**
 * The Store used for a {@link ShellFGEffectsConfettisComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class ShellFGEffectsConfettisRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `shell-fg-effects-confettis-${uniqueId()}`,
    },
    withProps<ShellFGEffectsConfettisModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: IShellFGEffectsConfettis) {
    // if there is no configuration, then throw an error
    if (!configuration) {
      throw createDigitaServiceError(
        `ShellFGEffectsConfettis`,
        `initialize`,
        `The configuration is required but was not provided.`,
        `internal`,
      );
    }

    // process the configurations
    const newOptionsLeft = cloneDeep(defaultOptionsLeft());
    this.processConfig(configuration.left, newOptionsLeft);

    const newOptionsRight = cloneDeep(defaultOptionsRight());
    this.processConfig(configuration.right, newOptionsRight);

    // extract the id
    const effectID = configuration.effectID;
    if (!effectID) {
      throw createDigitaServiceError(`ShellFGEffectsConfettis`, `initialize`, `The effectID is required but was not provided.`, `internal`);
    }

    // the delay in milliseconds before the confetti effect starts
    let delayMS = 0;
    if (typeof configuration.delayMS === 'number') {
      if (configuration.delayMS >= 0) {
        delayMS = configuration.delayMS;
      }
    }

    // updat the state
    this.store.update(
      ElfWrite((state) => {
        state.ready = true;
        state.delayMS = delayMS;
        state.effectID = effectID;
        state.effect = {
          left: newOptionsLeft,
          right: newOptionsRight,
        };
      }),
    );
  }

  /**
   * Occurs when the confettis effect has completed.
   */
  applyComplete() {
    this.store.update(
      ElfWrite((state) => {
        state.complete = true;
      }),
    );
  }

  /**
   * Applies incoming configuration to a default options object.
   *
   * @param configuration - The configuration to process.
   * @param newOptions - The options to update.
   */
  private processConfig(configuration: ConfettiOptions, newOptions: ConfettiOptions) {
    if (!configuration) {
      return;
    }

    if (typeof configuration.angle === 'number') {
      newOptions.angle = configuration.angle;
    }

    if (configuration.colors && configuration.colors.length > 0) {
      newOptions.colors = configuration.colors;
    }

    if (typeof configuration.decay === 'number') {
      newOptions.decay = configuration.decay;
    }

    if (configuration.disableForReducedMotion === true) {
      newOptions.disableForReducedMotion = configuration.disableForReducedMotion;
    }

    if (typeof configuration.drift === 'number') {
      newOptions.drift = configuration.drift;
    }

    if (typeof configuration.gravity === 'number') {
      newOptions.gravity = configuration.gravity;
    }

    if (configuration.origin) {
      if (typeof configuration.origin.x === 'number') {
        if (configuration.origin.x >= 0 && configuration.origin.x <= 1) {
          newOptions.origin.x = configuration.origin.x;
        }
      }

      if (typeof configuration.origin.y === 'number') {
        if (configuration.origin.y >= 0 && configuration.origin.y <= 1) {
          newOptions.origin.y = configuration.origin.y;
        }
      }
    }

    if (typeof configuration.particleCount === 'number') {
      newOptions.particleCount = configuration.particleCount;
    }

    if (typeof configuration.scalar === 'number') {
      newOptions.scalar = configuration.scalar;
    }

    if (configuration.shapes && configuration.shapes.length > 0) {
      const validShapes = configuration.shapes
        .filter((shape): shape is 'square' | 'circle' | 'star' => typeof shape === 'string')
        .every((shape) => ['circle', 'square', 'star'].includes(shape));
      if (validShapes) {
        newOptions.shapes = configuration.shapes;
      }
    }

    if (typeof configuration.spread === 'number') {
      newOptions.spread = configuration.spread;
    }

    if (typeof configuration.startVelocity === 'number') {
      newOptions.startVelocity = configuration.startVelocity;
    }

    if (typeof configuration.ticks === 'number') {
      newOptions.ticks = configuration.ticks;
    }

    if (typeof configuration.zIndex === 'number') {
      newOptions.zIndex = configuration.zIndex;
    }
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the effect ready (has the configuration been processed).
   */
  private ready$ = this.store.pipe(select((state) => state.ready));

  /**
   * The delay in milliseconds before the confetti effect starts.
   */
  private delayMS$ = this.store.pipe(select((state) => state.delayMS));

  /**
   * The ID of the effect.
   */
  private effectID$ = this.store.pipe(select((state) => state.effectID));

  /**
   * The options to use for the confetti effect.
   */
  private effect$ = this.store.pipe(select((state) => state.effect));

  /**
   * Is the effect complete?
   */
  private complete$ = this.store.pipe(select((state) => state.complete));

  /**
   * The configuration for the confetti effect.
   */
  confettisConfig$ = this.ready$.pipe(
    filter((ready) => ready),
    switchMap(() => this.delayMS$),
    switchMap((delayMS) => timer(delayMS)),
    switchMap(() => this.effect$),
    take(1),
  );

  /**
   * When the confetti is completed, this will emit the effectID.
   */
  completeConfig$ = this.complete$.pipe(
    filter((complete) => complete),
    switchMap(() => this.effectID$),
    take(1),
  );
}
