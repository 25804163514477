import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { IPromoCode } from 'src/app/api/modules/core/dynamic/components/IPromoCode';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { PromoCodeModel } from './promo-code.model';

/**
 * The Default State
 */
function initialState(): PromoCodeModel {
  return {
    text: '',
  };
}

/**
 * The Store used for an {@link PromoCodeComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class PromoCodeRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `promo-code-${uniqueId()}`,
    },
    withProps<PromoCodeModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyInitialize(configuration?: IPromoCode) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`PromoCode`, `applyInitialize`, `No configuration provided but this is required.`, `config`);
    }

    // text
    if (!configuration.text || configuration.text.length === 0) {
      throw createDigitaServiceError(`PromoCodeStore`, `applyInitialize`, `No "text" property provided but this is required.`, `config`);
    }

    // the text
    const text = configuration.text;

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.text = text;
      }),
    );
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERY
  ////////////////////////////////////////////////////////////////////

  /**
   * The Text of the Promocode
   */
  text$ = this.store.pipe(select((state) => state.text));
}
