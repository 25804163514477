import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { filter, map, switchMap } from 'rxjs';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DialogContentModel } from './dialog-content.model';

/**
 * The Default State
 */
function initialState(): DialogContentModel {
  return {
    configured: false,
    header: undefined,
    content: undefined,
    footer: undefined,
    settings: {},
  };
}

/**
 * The Store used for a {@link DialogContentComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DialogContentRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `dialog-content-${uniqueId()}`,
    },
    withProps<DialogContentModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////
  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IDialogContent) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(`DialogContent`, `applyConfiguration`, `No configuration provided but this is required.`, `config`);
    }

    // the header
    const header = configuration.header;

    // the content
    const content = configuration.content;

    // the footer
    const footer = configuration.footer;

    // the settings
    const settings = configuration.settings;

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.header = header;
        state.content = content;
        state.footer = footer;
        state.settings = settings;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((value) => value),
  );

  /**
   * The Header Configuration.
   */
  private _header$ = this.store.pipe(select((state) => state.header));

  /**
   * The Content Configuration.
   */
  private _content$ = this.store.pipe(select((state) => state.content));

  /**
   * The Footer Configuration.
   */
  private _footer$ = this.store.pipe(select((state) => state.footer));

  /**
   * The Template Data
   */
  templateData$ = this._configured$.pipe(
    switchMap(() => ElfCombineQueries([this._header$, this._content$, this._footer$])),
    map(([header, content, footer]) => ({ header, content, footer })),
  );
}
