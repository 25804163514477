import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { map } from 'rxjs';
import { IDynamicTheme } from 'src/app/api/modules/core/components/theme/IDynamicTheme';
import { DefaultThemePalette } from 'src/app/api/modules/core/components/theme/palettes/MaterialPalette';
import { GenerateDrimifyCustomTokens } from 'src/app/api/modules/core/components/theme/tokens/DrimifyTokens';
import { GenerateMaterialColorSystemTokens } from 'src/app/api/modules/core/components/theme/tokens/MaterialColorTokens';
import { GenerateMaterialTypographySystemTokens } from 'src/app/api/modules/core/components/theme/tokens/MaterialTypographyTokens';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DynamicThemeModel } from './theme.model';

/**
 * The Default State
 */
function initialState(): DynamicThemeModel {
  return {
    darkMode: false,
    palette: DefaultThemePalette,
    typography: undefined,
    themeTypography: undefined,
    themeLight: undefined,
    themeDark: undefined,
  };
}

/**
 * The Store used for the Dynamic Theme.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class DynamicThemeRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `dynamic-theme`,
    },
    withProps<DynamicThemeModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Configures the Theme State.
   */
  applyConfiguration(configuration?: IDynamicTheme) {
    const { darkMode, palette, typography, custom } = this.store.getValue();

    let newDarkMode = darkMode;
    if (configuration?.darkMode === true || configuration?.darkMode === false) {
      newDarkMode = configuration.darkMode;
    }

    let newPalette = palette;
    if (configuration?.palette) {
      newPalette = cloneDeep(configuration.palette);
    }

    let newTypography = typography;
    if (configuration?.typography) {
      newTypography = cloneDeep(configuration.typography);
    }

    let newCustom = custom;
    if (configuration?.custom) {
      newCustom = cloneDeep(configuration.custom);
    }

    const colorTokens = GenerateMaterialColorSystemTokens(newPalette);
    const typographyTokens = GenerateMaterialTypographySystemTokens(newTypography);
    const customTokens = GenerateDrimifyCustomTokens(newCustom);

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.darkMode = newDarkMode;
        state.palette = newPalette;
        state.typography = newTypography;
        state.themeLight = colorTokens.light;
        state.themeDark = colorTokens.dark;
        state.themeTypography = typographyTokens;
        state.custom = customTokens;
      }),
    );
  }

  /**
   * Toggle Dark Mode.
   */
  toggleDarkMode() {
    const { darkMode } = this.store.getValue();

    this.store.update(
      ElfWrite((state) => {
        state.darkMode = !darkMode;
      }),
    );
  }

  /**
   * Set Dark Mode.
   *
   * @param darkMode - true for Dark Mode, false for Light Mode
   */
  setDarkMode(darkMode: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.darkMode = darkMode;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the Dark Mode enabled?
   */
  isDarkMode$ = this.store.pipe(select((state) => state.darkMode));

  /**
   * The Light Theme
   */
  private _themeLight$ = this.store.pipe(select((state) => state.themeLight));

  /**
   * The Dark Theme
   */
  private _themeDark$ = this.store.pipe(select((state) => state.themeDark));

  /**
   * The Typography Theme
   */
  private _themeTypography$ = this.store.pipe(select((state) => state.themeTypography));

  /**
   * The Drimify Theme
   */
  private _themeDrimify$ = this.store.pipe(select((state) => state.custom));

  /**
   * The Data for Themes
   */
  theme$ = ElfCombineQueries([this.isDarkMode$, this._themeLight$, this._themeDark$, this._themeTypography$, this._themeDrimify$]).pipe(
    map(([darkMode, light, dark, typography, drimify]) => {
      return {
        darkMode,
        light,
        dark,
        typography,
        drimify,
      };
    }),
  );
}
