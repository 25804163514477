<!--
The Layout of the div is determined by the configuration direction which is column by default.

A Template is provided ready to inject with DigitaService Content.
-->
@if (containerRepository.templateData$ | async; as templateData) {
  <div class="container-flex" [fxLayout]="templateData.layout" [fxLayoutAlign]="templateData.layoutAlign">
    <ng-template appDynamicContent></ng-template>
  </div>
}
