import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';

/**
 * Generates a JSON Object that can be used to construct a new TextRich Component
 *
 * This is used primarily within Plugins to convert a IFrame Message into an Angular
 * Component.
 *
 * @param config - The Partial Configuration for a TextRich
 */
export const GenerateTextRich = (config: Partial<ITextRich> = {}): ITextRich => {
  // the default rich text
  const defaults: ITextRich = {
    selector: 'app-text-rich',
    type: 'body-medium',
    data: ``,
  };

  return {
    ...defaults,
    ...config,
  };
};
