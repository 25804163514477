import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { ISocialSharing } from 'src/app/api/modules/core/dynamic/components/ISocialSharing';
import { SocialSharingButtonComponent } from '../social-sharing-button/social-sharing-button.component';
import { SocialSharingRepository } from './social-sharing.repository';

/**
 * The Social Sharing Component is responsible for displaying a group of social buttons or links.
 *
 * It can behave in 2 ways:
 *
 * 1. It displays a row of `link` to homepages such as `https://twitter.com/Twitter`
 * 2. It displays a row of `share` buttons to actually post content.
 *
 * You cannot mix and match these within the same container.
 */
@Component({
  selector: 'app-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.scss'],
  providers: [SocialSharingRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, SocialSharingButtonComponent, AsyncPipe],
})
export class SocialSharingComponent {
  /**
   * The Input Configuration
   */
  private _config: ISocialSharing;
  @Input()
  set config(value: ISocialSharing) {
    this._config = value;
    this.socialSharingRepository.applyConfiguration(value);
  }
  get config(): ISocialSharing {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly socialSharingRepository: SocialSharingRepository) {}
}
