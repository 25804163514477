/* eslint-disable @typescript-eslint/no-unused-vars */

import { IFrameEventCreateDialogData, IFrameEventCreateDialogLink, IFrameEventCreateDialogLinkData } from '@digitaservice/utils';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { ICoreComponent } from 'src/app/api/modules/core/dynamic/ICoreComponent';
import { ICoreContainer } from 'src/app/api/modules/core/dynamic/ICoreContainer';
import { createDigitaServiceError } from 'src/app/app-error';
import { SupportedIconNames } from 'src/app/modules/icons/services/icon.service';
import { GenerateDialogContent } from '../generators/dialog-content.generator';
import { ConvertPluginToImage } from './image.convert';
import { ConvertPluginToMedia } from './media.converter';
import { ConvertPluginToTextRich } from './text-rich.converter';

/**
 * Converts a given icon name from supported icon names into a button configuration for use in a Plugin Dialog. The style of the
 * button will be a fab.
 *
 * @param iconName - The name of the icon to convert into a button configuration. Must be one of the supported icon names.
 * @param isNegativeChoice - If true, the button will be styled as a negative choice.
 * @returns The button configuration object if a valid icon name is provided; otherwise, null.
 */
const ConvertPluginToButtonIcon = (iconName: SupportedIconNames, isNegativeChoice = false) => {
  // if there is an icon name
  if (iconName) {
    // create a suitable button for this icon
    const button: IButton = {
      selector: 'app-button',
      label: null,
      iconPrefix: {
        name: iconName,
      },
      type: 'button',
      style: 'fab',
      link: null,
    };

    return button;
  }
  return null;
};

/**
 * Converts a given string into a button configuration for use in a Plugin Dialog. The style of the button will be a flat button.
 *
 * @param label - The string of the text to use as a label in a button configuration.
 * @param isNegativeChoice - If true, the button will be styled as a negative choice.
 * @returns The button configuration object if a valid icon name is provided; otherwise, null.
 */
const ConvertPluginToButtonText = (label: string, isNegativeChoice = false) => {
  // if there is a label
  if (label) {
    // create a suitable button for this label
    const button: IButton = {
      selector: 'app-button',
      label: label,
      type: 'button',
      link: null,
    };

    // if isNegativeChoice is true
    if (isNegativeChoice) {
      button.style = 'stroked';
    } else {
      button.style = 'flat';
    }

    return button;
  }
  return null;
};

/**
 * Converts a given icon name from supported icon names into a link configuration for use in a Plugin Dialog. The style of the
 * button will be a fab.
 *
 * @param iconName - The name of the icon to convert into a button configuration. Must be one of the supported icon names.
 * @param link - The link to use as a link in a button configuration.
 * @param isNegativeChoice - If true, the button will be styled as a negative choice.
 * @returns The button configuration object if a valid icon name is provided; otherwise, null.
 */
const ConvertPluginToLinkIcon = (iconName: SupportedIconNames, link: IFrameEventCreateDialogLinkData, isNegativeChoice = false) => {
  // if there is a icon name and link
  if (iconName && link) {
    // create a suitable button for this icon
    const button: IButton = {
      selector: 'app-button',
      label: null,
      iconPrefix: {
        name: iconName,
      },
      type: 'link',
      style: 'fab',
      link,
    };

    // if this is not a negative choice, set the color to primary
    if (!isNegativeChoice) {
      // this is not used since color was removed
      // button.color = 'primary';
    }

    return button;
  }

  return null;
};

/**
 * Converts a given string into a link configuration for use in a Plugin Dialog. The style of the button will be a flat button.
 *
 * @param label - The string of the text to use as a label in a button configuration.
 * @param link - The link to use as a link in a button configuration.
 * @param isNegativeChoice - If true, the button will be styled as a negative choice.
 * @returns The button configuration object if a valid icon name is provided; otherwise, null.
 */
const ConvertPluginToLinkText = (label: string, link: IFrameEventCreateDialogLinkData, isNegativeChoice = false) => {
  // if there is a label and link
  if (label && link) {
    // create a suitable button for this label
    const button: IButton = {
      selector: 'app-button',
      label,
      type: 'link',
      link,
    };

    if (isNegativeChoice) {
      button.style = 'stroked';
    } else {
      button.style = 'flat';
    }

    return button;
  }

  return null;
};

/**
 * Converts a supported icon name to a button configuration object as part of Plugin Dialog Events.
 *
 * This function takes a string (text or icon name) or a IFrameEventCreateDialogLink object and converts
 * it into a button configuration object for use in the app.
 *
 * @param iconName - A string or a IFrameEventCreateDialogLink object.
 * @param isNegativeChoice - If true, the button will be styled as a negative choice.
 * @returns An object representing the button configuration if the icon name is provided; otherwise, null.
 */
const ConvertPluginDialogButtonToType = (incoming: string | IFrameEventCreateDialogLink, isNegativeChoice = false) => {
  // if there is no incoming data, return null
  if (!incoming) {
    return null;
  }

  // if the button is a string
  if (typeof incoming === 'string') {
    // if the incoming button contains a ":" then it's an icon
    if (incoming.indexOf(':') === -1) {
      return ConvertPluginToButtonText(incoming, isNegativeChoice);
    } else {
      return ConvertPluginToButtonIcon(incoming as SupportedIconNames, isNegativeChoice);
    }
  } else {
    // if the incoming button contains a ":" then it's an icon
    if (incoming.label.indexOf(':') === -1) {
      return ConvertPluginToLinkText(incoming.label, incoming.link, isNegativeChoice);
    } else {
      return ConvertPluginToLinkIcon(incoming.label as SupportedIconNames, incoming.link, isNegativeChoice);
    }
  }
};

/**
 * The Inner IFrame Page of a Plugin may emit a FrameDialogEvent, that is a simpler API which is then converted into a real dialog
 *
 * @param incoming - the data dispatched from the inner plugin iframe page.
 */
export const ConvertPluginToDialog = (incoming: IFrameEventCreateDialogData) => {
  // if there is no incoming data, throw an error
  if (!incoming) {
    throw createDigitaServiceError(`DialogConverter`, `ConvertPluginToDialog`, `No data recieved`, `internal`);
  }
  // if there is no button or buttons property, throw an error
  if (!incoming.button && !incoming.buttons) {
    throw createDigitaServiceError(`DialogConverter`, `ConvertPluginToDialog`, `No "button" or "buttons" properties recieved`, `config`);
  }
  // if there is a buttons property, throw an error
  if (!incoming.button && incoming.buttons && !incoming.buttons.positive && !incoming.buttons.negative) {
    throw createDigitaServiceError(
      `DialogConverter`,
      `ConvertPluginToDialog`,
      `The "buttons" object has no "positive" or "negative" properties but this is required.`,
      `config`,
    );
  }

  // extract properties
  const title = incoming.title;

  // process the buttons
  const buttons: IButton[] = [];

  // if the button object is specificied
  if (incoming.button) {
    buttons.push(ConvertPluginDialogButtonToType(incoming.button));
  } else {
    buttons.push(ConvertPluginDialogButtonToType(incoming.buttons.positive));
    buttons.push(ConvertPluginDialogButtonToType(incoming.buttons.negative, true));
  }

  // all components will go here.
  let contentComponents: ICoreComponent[] | undefined = undefined;

  // only create an array if there is incoming information
  if (incoming.image || incoming.media || incoming.description) {
    contentComponents = [];
  }

  // extract content
  if (incoming.image) {
    const image = ConvertPluginToImage(incoming.image);
    if (image) {
      contentComponents.push(image);
    }
  }

  if (incoming.media) {
    const media = ConvertPluginToMedia(incoming.media);
    if (media) {
      contentComponents.push(media);
    }
  }

  if (incoming.description) {
    const desc = ConvertPluginToTextRich(incoming.description);
    if (desc) {
      contentComponents.push(desc);
    }
  }

  // create a core container to hold the content of the dialog (if applicable)
  // this is required to avoid creating a core container with no actual content
  let content: ICoreContainer | undefined = undefined;
  if (contentComponents) {
    content = {
      selector: 'app-container',
      layout: 'column',
      layoutAlign: 'space-around center',
      componentArray: contentComponents,
    };
  }

  // finally create the dialog with all of the processed data
  const dialog = GenerateDialogContent({
    header: title,
    content,
    footer: {
      positiveButton: buttons[0],
      negativeButton: buttons[1] || undefined,
      layout: buttons.length === 1 ? 'column' : 'row-reverse',
      layoutAlign: buttons.length === 1 ? 'center center' : 'space-between center',
    },
    settings: {
      disableClose: incoming.scrim ? false : true,
    },
  });

  return dialog;
};
