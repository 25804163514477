import { IFaIcon } from 'src/app/api/modules/icons/fa/IFaIcon';

/**
 * Generates an Icon JSON Object
 *
 * @param config - the partial configuration
 */
export const GenerateFaIcon = (config: Partial<IFaIcon> = {}): IFaIcon => {
  const defaults: IFaIcon = {
    name: 'fas:skull-crossbones',
  };

  return {
    ...defaults,
    ...config,
  };
};
