import { animation, sequence, useAnimation } from '@angular/animations';
import { ANIMATION_DISPLAY_DURATION } from '../AnimationConsts';
import { IAnimationCompoundOptions } from '../IAnimationCompoundOptions';
import { rollInAnimation, rollOutAnimation } from '../parts/roll.animation';

export const rollInOutCompoundAnimation = (options: Partial<IAnimationCompoundOptions>) => {
  const enter = rollInAnimation(options.enter);
  const exit = rollOutAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};
