/* eslint-disable @typescript-eslint/no-unused-vars */

import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { EMPTY, interval } from 'rxjs';
import { finalize, first, switchMap, takeWhile } from 'rxjs/operators';
import { ISocialSharingButton } from 'src/app/api/modules/core/dynamic/components/ISocialSharingButton';
import { FaIconComponent } from '../../../../icons/components/fa-icon/fa-icon.component';
import { AbstractLinkComponent } from '../../../components/abstract/abstract-link/abstract-link.component';
import { SnackbarService } from '../../../services/snackbar.service';
import { SocialSharingButtonRepository } from './social-sharing-button.repository';

/**
 * The SocialSharingButtonComponent binds the URLs/Platform/Icon/Styles for a specific social network provider.
 *
 * This is done to reduce the end bundle size and to simplify the JSON data.
 */
@Component({
  selector: 'app-social-sharing-button',
  templateUrl: './social-sharing-button.component.html',
  styleUrls: ['./social-sharing-button.component.scss'],
  providers: [SocialSharingButtonRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AbstractLinkComponent, FlexModule, NgClass, ExtendedModule, FaIconComponent, AsyncPipe],
})
export class SocialSharingButtonComponent {
  /**
   * The Input Config as supplied by JSON
   */
  private _config?: ISocialSharingButton;
  @Input()
  set config(value: ISocialSharingButton) {
    this._config = value;
    this.socialSharingButtonRepository.applyConfiguration(value);
  }
  get config() {
    return this._config;
  }

  /**
   * Set by the Social Sharing component that this button belongs to
   * depending on what type of group is being created.
   *
   * The default is "share"
   */
  private _type: 'share' | 'homepage' = 'share';
  @Input()
  set type(value: 'share' | 'homepage') {
    this._type = value;
    this.socialSharingButtonRepository.applyType(value);
  }
  get type() {
    return this._type;
  }

  /**
   * Constructor
   */
  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly clipboard: Clipboard,
    public readonly socialSharingButtonRepository: SocialSharingButtonRepository,
  ) {}

  /**
   * Occurs when the component is clicked.
   *
   * @param e - the event dispatched from an abstract link
   */
  onClicked(e: MouseEvent) {
    this.socialSharingButtonRepository.copyToClipboard$
      .pipe(
        first(),
        switchMap((data) => {
          if (data?.link?.href && data?.platform === 'copy') {
            const pending = this.clipboard.beginCopy(data.link.href);
            let remainingAttempts = 5;

            return interval(100).pipe(
              takeWhile(() => --remainingAttempts > 0 && !pending.copy()),
              finalize(() => {
                pending.destroy();
                if (data.snackbar) {
                  this.snackbarService.open(data.snackbar);
                }
              }),
            );
          } else {
            return EMPTY;
          }
        }),
      )
      .subscribe();
  }
}
