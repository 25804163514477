import { PalettesSchema } from '../palettes/MaterialPalette';

/**
 * All Material Color Tokens.
 *
 * This is the only place where you should list Material Color Tokens in Javascript.
 *
 * Utility methods generate the Typescript Interface from this list.
 */
export const MaterialColorTokenKeys = {
  '--sys-background': undefined,
  '--sys-on-background': undefined,
  '--sys-primary': undefined,
  '--sys-on-primary': undefined,
  '--sys-primary-container': undefined,
  '--sys-on-primary-container': undefined,
  '--sys-primary-fixed': undefined,
  '--sys-primary-fixed-dim': undefined,
  '--sys-on-primary-fixed': undefined,
  '--sys-on-primary-fixed-variant': undefined,
  '--sys-secondary': undefined,
  '--sys-on-secondary': undefined,
  '--sys-secondary-container': undefined,
  '--sys-on-secondary-container': undefined,
  '--sys-secondary-fixed': undefined,
  '--sys-secondary-fixed-dim': undefined,
  '--sys-on-secondary-fixed': undefined,
  '--sys-on-secondary-fixed-variant': undefined,
  '--sys-tertiary': undefined,
  '--sys-on-tertiary': undefined,
  '--sys-tertiary-container': undefined,
  '--sys-on-tertiary-container': undefined,
  '--sys-tertiary-fixed': undefined,
  '--sys-tertiary-fixed-dim': undefined,
  '--sys-on-tertiary-fixed': undefined,
  '--sys-on-tertiary-fixed-variant': undefined,
  '--sys-error': undefined,
  '--sys-on-error': undefined,
  '--sys-error-container': undefined,
  '--sys-on-error-container': undefined,
  '--sys-surface-dim': undefined,
  '--sys-surface': undefined,
  '--sys-surface-bright': undefined,
  '--sys-surface-container-lowest': undefined,
  '--sys-surface-container-low': undefined,
  '--sys-surface-container': undefined,
  '--sys-surface-container-high': undefined,
  '--sys-surface-container-highest': undefined,
  '--sys-on-surface': undefined,
  '--sys-on-surface-variant': undefined,
  '--sys-outline': undefined,
  '--sys-outline-variant': undefined,
  '--sys-inverse-surface': undefined,
  '--sys-inverse-on-surface': undefined,
  '--sys-inverse-primary': undefined,
  '--sys-scrim': undefined,
  '--sys-shadow': undefined,
  '--sys-surface-tint': undefined,
  '--sys-surface-variant': undefined,
} as const;

////////////////////////////////////////////////////////////////////////////////////////
// DO NOT MODIFY BELOW THIS LINE
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Generates System Tokens from the provided Palettes.
 */
export const GenerateMaterialColorSystemTokens = (palettes: PalettesSchema) => {
  const dark: MaterialColorTokens = {
    '--sys-background': palettes.neutral['6'],
    '--sys-error': palettes.error['80'],
    '--sys-error-container': palettes.error['30'],
    '--sys-inverse-on-surface': palettes.neutral['20'],
    '--sys-inverse-primary': palettes.primary['40'],
    '--sys-inverse-surface': palettes.neutral['90'],
    '--sys-on-background': palettes.neutral['90'],
    '--sys-on-error': palettes.error['20'],
    '--sys-on-error-container': palettes.error['90'],
    '--sys-on-primary': palettes.primary['20'],
    '--sys-on-primary-container': palettes.primary['90'],
    '--sys-on-primary-fixed': palettes.primary['10'],
    '--sys-on-primary-fixed-variant': palettes.primary['30'],
    '--sys-on-secondary': palettes.secondary['20'],
    '--sys-on-secondary-container': palettes.secondary['90'],
    '--sys-on-secondary-fixed': palettes.secondary['10'],
    '--sys-on-secondary-fixed-variant': palettes.secondary['30'],
    '--sys-on-surface': palettes.neutral['90'],
    '--sys-on-surface-variant': palettes['neutral-variant']['90'],
    '--sys-on-tertiary': palettes.tertiary['20'],
    '--sys-on-tertiary-container': palettes.tertiary['90'],
    '--sys-on-tertiary-fixed': palettes.tertiary['10'],
    '--sys-on-tertiary-fixed-variant': palettes.tertiary['30'],
    '--sys-outline': palettes['neutral-variant']['60'],
    '--sys-outline-variant': palettes['neutral-variant']['30'],
    '--sys-primary': palettes.primary['80'],
    '--sys-primary-container': palettes.primary['30'],
    '--sys-primary-fixed': palettes.primary['90'],
    '--sys-primary-fixed-dim': palettes.primary['80'],
    '--sys-scrim': palettes.neutral['0'],
    '--sys-secondary': palettes.secondary['80'],
    '--sys-secondary-container': palettes.secondary['30'],
    '--sys-secondary-fixed': palettes.secondary['90'],
    '--sys-secondary-fixed-dim': palettes.secondary['80'],
    '--sys-shadow': palettes.neutral['0'],
    '--sys-surface': palettes.neutral['6'],
    '--sys-surface-bright': palettes.neutral['24'],
    '--sys-surface-container': palettes.neutral['12'],
    '--sys-surface-container-high': palettes.neutral['17'],
    '--sys-surface-container-highest': palettes.neutral['24'],
    '--sys-surface-container-low': palettes.neutral['10'],
    '--sys-surface-container-lowest': palettes.neutral['4'],
    '--sys-surface-dim': palettes.neutral['6'],
    '--sys-surface-tint': palettes.primary['80'],
    '--sys-surface-variant': palettes['neutral-variant']['30'],
    '--sys-tertiary': palettes.tertiary['80'],
    '--sys-tertiary-container': palettes.tertiary['30'],
    '--sys-tertiary-fixed': palettes.tertiary['90'],
    '--sys-tertiary-fixed-dim': palettes.tertiary['80'],
  };

  const light: MaterialColorTokens = {
    '--sys-background': palettes.neutral['98'],
    '--sys-error': palettes.error['40'],
    '--sys-error-container': palettes.error['90'],
    '--sys-inverse-on-surface': palettes.neutral['95'],
    '--sys-inverse-primary': palettes.primary['80'],
    '--sys-inverse-surface': palettes.neutral['20'],
    '--sys-on-background': palettes.neutral['10'],
    '--sys-on-error': palettes.error['100'],
    '--sys-on-error-container': palettes.error['10'],
    '--sys-on-primary': palettes.primary['100'],
    '--sys-on-primary-container': palettes.primary['10'],
    '--sys-on-primary-fixed': palettes.primary['10'],
    '--sys-on-primary-fixed-variant': palettes.primary['30'],
    '--sys-on-secondary': palettes.secondary['100'],
    '--sys-on-secondary-container': palettes.secondary['10'],
    '--sys-on-secondary-fixed': palettes.secondary['10'],
    '--sys-on-secondary-fixed-variant': palettes.secondary['30'],
    '--sys-on-surface': palettes.neutral['10'],
    '--sys-on-surface-variant': palettes['neutral-variant']['30'],
    '--sys-on-tertiary': palettes.tertiary['100'],
    '--sys-on-tertiary-container': palettes.tertiary['10'],
    '--sys-on-tertiary-fixed': palettes.tertiary['10'],
    '--sys-on-tertiary-fixed-variant': palettes.tertiary['30'],
    '--sys-outline': palettes['neutral-variant']['50'],
    '--sys-outline-variant': palettes['neutral-variant']['80'],
    '--sys-primary': palettes.primary['40'],
    '--sys-primary-container': palettes.primary['90'],
    '--sys-primary-fixed': palettes.primary['90'],
    '--sys-primary-fixed-dim': palettes.primary['80'],
    '--sys-scrim': palettes.neutral['0'],
    '--sys-secondary': palettes.secondary['40'],
    '--sys-secondary-container': palettes.secondary['90'],
    '--sys-secondary-fixed': palettes.secondary['90'],
    '--sys-secondary-fixed-dim': palettes.secondary['80'],
    '--sys-shadow': palettes.neutral['0'],
    '--sys-surface': palettes.neutral['98'],
    '--sys-surface-bright': palettes.neutral['98'],
    '--sys-surface-container': palettes.neutral['94'],
    '--sys-surface-container-high': palettes.neutral['92'],
    '--sys-surface-container-highest': palettes.neutral['90'],
    '--sys-surface-container-low': palettes.neutral['96'],
    '--sys-surface-container-lowest': palettes.neutral['100'],
    '--sys-surface-dim': palettes.neutral['87'],
    '--sys-surface-tint': palettes.primary['40'],
    '--sys-surface-variant': palettes['neutral-variant']['90'],
    '--sys-tertiary': palettes.tertiary['40'],
    '--sys-tertiary-container': palettes.tertiary['90'],
    '--sys-tertiary-fixed': palettes.tertiary['90'],
    '--sys-tertiary-fixed-dim': palettes.tertiary['80'],
  };

  return {
    light,
    dark,
  };
};

/**
 * Helper typescript syntax for mapping keys from a constant to a type.
 */
type MappedInterface<T> = {
  [K in keyof T]?: string;
};

/**
 * Generated Interface for the Material Color Tokens.
 */
export interface MaterialColorTokens extends Partial<MappedInterface<typeof MaterialColorTokenKeys>> {}
