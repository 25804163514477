import { Pipe, PipeTransform } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * Converts a DigitaService Name into a FontAwesome one.
 *
 * For example `far:bars` would become `['far', 'bars']`
 */
@Pipe({
  name: 'faIconName',
  standalone: true,
})
export class FaIconNamePipe implements PipeTransform {
  transform(value: string): IconProp {
    return value.split(':') as IconProp;
  }
}
