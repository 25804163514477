import { animate, animation, keyframes, style } from '@angular/animations';
import { ANIMATION_DELAY, ANIMATION_DURATION, ANIMATION_TRANSLATION } from '../AnimationConsts';
import { IAnimationOptions } from '../IAnimationOptions';

export interface IRollAnimationOptions extends IAnimationOptions {
  /**
   * Angle - number of degrees from which to start animation.
   *
   * Default 120
   */
  degrees?: number;

  /**
   * Translate, possible units: px, %, em, rem, vw, vh
   *
   * Default: 100px
   */
  translate?: string;
}

export const rollInAnimation = (options: Partial<IRollAnimationOptions> = {}) => {
  return animation([
    animate(
      `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
      keyframes([
        style({
          visibility: 'visible',
          opacity: 0,
          transform: `translate3d(${options.translate || -ANIMATION_TRANSLATION}px, 0, 0) rotate3d(0, 0, 1, ${options.degrees || -120}deg)`,
          easing: 'ease',
          offset: 0,
        }),
        style({ opacity: 1, transform: 'translate3d(0, 0, 0) rotate3d(0, 0, 1, 0deg)', easing: 'ease-out', offset: 1 }),
      ]),
    ),
  ]);
};

export const rollOutAnimation = (options: Partial<IRollAnimationOptions>) => {
  return animation([
    animate(
      `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
      keyframes([
        style({ opacity: 1, transform: 'translate3d(0, 0, 0) rotate3d(0, 0, 1, 0deg)', easing: 'ease-out', offset: 0 }),
        style({
          opacity: 0,
          transform: `translate3d(${options.translate || ANIMATION_TRANSLATION}px, 0, 0) rotate3d(0, 0, 1, ${options.degrees || 120}deg)`,
          easing: 'ease',
          offset: 1,
        }),
      ]),
    ),
  ]);
};
