import { animate, animation, style } from '@angular/animations';
import { ANIMATION_DELAY, ANIMATION_DURATION } from '../AnimationConsts';
import { IAnimationOptions } from '../IAnimationOptions';

export interface IAnimationTranslateYTo extends IAnimationOptions {
  /**
   * A CSS  String to which the element will move on the y axis relative to where it is now.
   */
  y: string;
}

export const translateYToAnimation = (options: Partial<IAnimationTranslateYTo> = {}) => {
  return animation([
    animate(
      `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
      style({
        transform: `translateY(${options.y})`,
        easing: options.ease || 'ease-out',
      }),
    ),
  ]);
};

export interface IAnimationTranslateXTo extends IAnimationOptions {
  /**
   * A CSS  String to which the element will move on the x axis relative to where it is now.
   */
  x: string;
}

export const translateXToAnimation = (options: Partial<IAnimationTranslateXTo> = {}) => {
  return animation([
    animate(
      `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
      style({
        transform: `translateX(${options.x})`,
        easing: options.ease || 'ease-out',
      }),
    ),
  ]);
};

export interface IAnimationResizeWidthTo extends IAnimationOptions {
  /**
   * A CSS Width String to which the element will grow or shrink to this
   * target width;
   */
  width: string;
}

export const resizeWidthToAnimation = (options: Partial<IAnimationResizeWidthTo> = {}) => {
  return animation([
    animate(
      `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
      style({
        width: options.width,
        easing: options.ease || 'ease-out',
      }),
    ),
  ]);
};

export interface IAnimationResizeHeightTo extends IAnimationOptions {
  /**
   * A CSS Height String to which the element will grow or shrink to this
   * target height;
   */
  height: string;
}

export const resizeHeightToAnimation = (options: Partial<IAnimationResizeHeightTo> = {}) => {
  return animation([
    animate(
      `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
      style({
        height: options.height,
        easing: options.ease || 'ease-out',
      }),
    ),
  ]);
};

export interface IAnimationFadeTo extends IAnimationOptions {
  /**
   * A number detailing the opacity
   */
  opacity: number;
}

export const fadeToAnimation = (options: Partial<IAnimationFadeTo> = {}) => {
  return animation([
    animate(
      `${options.duration || ANIMATION_DURATION}ms ${options.delay || ANIMATION_DELAY}ms`,
      style({
        opacity: options.opacity,
        easing: options.ease || 'ease-out',
      }),
    ),
  ]);
};
