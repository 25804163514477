import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { IDataboxAttemptsIcon } from 'src/app/api/modules/core/dynamic/databoxes/attempts-icon/IDataboxAttemptsIcon';
import { GenerateFaIcon } from 'src/app/factories/generators/icon-fa.generator';
import { GenerateIconState } from 'src/app/factories/generators/icon-state.generator';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DataboxAttemptsIconModel, DataboxAttemptsIconStateModel } from './databox-attempts-icon.model';

/**
 * A Built in Default State
 */
const DEFAULT_STATE = GenerateFaIcon({
  name: 'far:circle',
  state: 'default',
});

/**
 * A Built in Positive State
 */
const POSITIVE_STATE = GenerateFaIcon({
  name: 'fas:circle-check',
  state: 'positive',
});

/**
 * A Built in Negative State
 */
const NEGATIVE_STATE = GenerateFaIcon({
  name: 'fas:circle-xmark',
  state: 'negative',
});

/**
 * The default final state which will be duplicated
 */
function defaultState() {
  return GenerateIconState({
    states: [DEFAULT_STATE, POSITIVE_STATE, NEGATIVE_STATE],
  });
}

/**
 * The Default State
 */
function initialState(): DataboxAttemptsIconModel {
  return {
    selector: 'app-databox-attempts-icon',
    state: defaultState(),
    states: [],
  };
}

/**
 * The Store used for a {@link DataboxAttemptsIconComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DataboxAttemptsIconRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `databox-attempts-icon-${uniqueId()}`,
    },
    withProps<DataboxAttemptsIconModel>(initialState()),
  );

  constructor(private readonly logger: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initialize from the configuration.
   */
  applyInitialize(configuration?: Partial<IDataboxAttemptsIcon>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    // state
    let activeState = defaultState();
    if (configuration.state?.states) {
      let hasDefault = true;

      // ensure one of the states is default.
      for (let i = 0; i < configuration.state.states.length; i++) {
        const targetState = configuration.state.states[i];
        if (targetState.state === 'default') {
          hasDefault = true;
        }
      }

      // if there is no default then that is a problem.
      if (!hasDefault) {
        this.logger.warn(
          `[DataboxAttemptsIcon] applyInitialize - you must supply a state with the name of "default" to one of your icons.`,
        );
      }

      // if there is only a single state then that is useless
      if (configuration.state.states.length === 1) {
        this.logger.warn(`[DataboxAttemptsIcon] applyInitialize - you only have provided one state... kind of useless`);
      }

      if (hasDefault) {
        activeState = cloneDeep(configuration.state);
      }
    }

    // starting states
    const states: DataboxAttemptsIconStateModel[] = [];
    if (configuration.startingState) {
      for (let i = 0; i < configuration.startingState.length; i++) {
        const targetState = configuration.startingState[i];
        states.push({
          ...cloneDeep(activeState),
          stateName: targetState,
        });
      }
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.state = activeState;
        state.states = states;
      }),
    );
  }

  /**
   * Updates the component with the users attempts.
   *
   * @param stateList - The new state.
   */
  applyUpdate(stateList: string[]) {
    // get the configured data
    const { state, states } = this.store.getValue();

    // if there is no state list then that is an issue.
    if (!stateList) {
      return;
    }

    // if there is an update total then the state is being regenerated
    let newStates: DataboxAttemptsIconStateModel[];

    // if the incoming state list is the same length as the configured states then we can just update the states
    if (states.length === stateList.length) {
      // then states can be cloned
      newStates = cloneDeep(states);
    } else {
      // otherwise states need recreated
      newStates = [];
      for (let i = 0; i < stateList.length; i++) {
        const targetState = stateList[i];
        newStates.push({
          ...cloneDeep(state),
          stateName: targetState,
        });
      }
    }

    // finally the states can be updated according to the incoming stateList
    for (let i = 0; i < stateList.length; i++) {
      const targetState = newStates[i];
      const targetStateName = stateList[i];
      targetState.stateName = targetStateName;
    }

    // the store can be updated
    this.store.update(
      ElfWrite((state) => {
        state.states = newStates;
      }),
    );
  }

  /**
   * Reset the ouput to the templateIdle value.
   */
  applyReset() {
    const { states } = this.store.getValue();

    const newStates = cloneDeep(states);

    for (let i = 0; i < newStates.length; i++) {
      newStates[i].stateName = 'default';
    }

    // the store can be updated
    this.store.update(
      ElfWrite((state) => {
        state.states = newStates;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * The Final Output State.
   */
  states$ = this.store.pipe(select((state) => state.states));
}
