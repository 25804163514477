import { Injectable } from '@angular/core';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { ICountdown } from 'src/app/api/modules/core/dynamic/components/countdown/ICountdown';
import { ICountdownResponseData } from 'src/app/api/modules/core/dynamic/components/countdown/network/response/ICountdownResponseData';
import { LinkService } from '../../../services/link.service';
import { DigitaServiceAPIService } from '../../../services/network/digitaservice-api.service';
import { CountdownRepository } from './countdown.repository';

/**
 * The Countdown Service is used with the {@link CountdownComponent}.
 */
@Injectable()
export class CountdownService {
  /**
   * Constructor
   */
  constructor(
    private readonly apiService: DigitaServiceAPIService,
    private readonly linkService: LinkService,
    private readonly countdownRepository: CountdownRepository,
  ) {}

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: ICountdown) {
    this.countdownRepository.applyInitialize(configuration);
  }

  /**
   * Tick the timer forward by one second.
   */
  applyTick() {
    this.countdownRepository.applyTick();
  }

  /**
   * Apply the response from the server to the store.
   *
   * @param response - the response from the server
   */
  applyCountdownCompleteResponse(response: ICountdownResponseData) {
    this.countdownRepository.applyCountdownCompleteResponse(response);
  }

  /**
   * Inform the server that a countdown has completed.
   *
   * @param onCompleteAPI - the end point to call when the timer completes
   */
  requestCountdownComplete(onCompleteAPI: string) {
    return this.apiService.data<ICountdownResponseData>(onCompleteAPI);
  }

  /**
   * Navigates to the provided destination.
   *
   * @param destination - the destination to navigate to
   */
  navigateToDestination(destination: ILink) {
    this.linkService.openLink(destination);
  }
}
