<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<!-- Waits to be pushed a templateData -->
@if (buttonRepository.templateData$ | async; as templateData) {
  <!-- If the Button is a Link start a switch statement -->
  @if (templateData.type === 'link') {
    @switch (templateData.style) {
      <!-- Link Mat Button -->
      @case ('basic') {
        <a
          mat-button
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          }
        </a>
      }
      @case ('raised') {
        <a
          mat-raised-button
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          }
        </a>
      }
      @case ('flat') {
        <a
          mat-flat-button
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          }
        </a>
      }
      @case ('stroked') {
        <a
          mat-stroked-button
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          }
        </a>
      }
      @case ('icon') {
        <a
          mat-icon-button
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasIconPrefix) {
            <mat-icon [svgIcon]="templateData.iconPrefixName"></mat-icon>
          }
        </a>
      }
      @case ('fab') {
        <a
          mat-fab
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
          [extended]="templateData.extended"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          } @else {
            @if (templateData.hasIconPrefix) {
              <mat-icon [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
          }
        </a>
      }
      @case ('mini-fab') {
        <a
          mat-mini-fab
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasIconPrefix) {
            <mat-icon [svgIcon]="templateData.iconPrefixName"></mat-icon>
          }
        </a>
      }
    }
  }
  <!-- Button Types use a <button> tag to execute local JS -->
  @else {
    @switch (templateData.style) {
      @case ('basic') {
        <button
          mat-button
          [attr.form]="templateData.form"
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          }
        </button>
      }
      @case ('raised') {
        <button
          mat-raised-button
          [attr.form]="templateData.form"
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          }
        </button>
      }
      @case ('flat') {
        <button
          mat-flat-button
          [attr.form]="templateData.form"
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          }
        </button>
      }
      @case ('stroked') {
        <button
          mat-stroked-button
          [attr.form]="templateData.form"
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          }
        </button>
      }
      @case ('icon') {
        <button
          mat-icon-button
          [attr.form]="templateData.form"
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasIconPrefix) {
            <mat-icon [svgIcon]="templateData.iconPrefixName"></mat-icon>
          }
        </button>
      }
      @case ('fab') {
        <button
          mat-fab
          [attr.form]="templateData.form"
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
          [extended]="templateData.extended"
        >
          @if (templateData.hasLabel) {
            @if (templateData.hasIconPrefix) {
              <mat-icon class="icon-prefix" [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
            {{ templateData.label }}
            @if (templateData.hasIconPostfix) {
              <mat-icon class="icon-postfix" [svgIcon]="templateData.iconPostfixName" iconPositionEnd></mat-icon>
            }
          } @else {
            @if (templateData.hasIconPrefix) {
              <mat-icon [svgIcon]="templateData.iconPrefixName"></mat-icon>
            }
          }
        </button>
      }
      @case ('mini-fab') {
        <button
          mat-mini-fab
          [attr.form]="templateData.form"
          [color]="templateData.color"
          [class.mat-button-textured]="templateData.isTextured"
          (click)="onClick($event)"
          [disabled]="templateData.disabled"
          [tabIndex]="templateData.tabIndex"
        >
          @if (templateData.hasIconPrefix) {
            <mat-icon [svgIcon]="templateData.iconPrefixName"></mat-icon>
          }
        </button>
      }
    }
  }
}
