import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { map } from 'rxjs';
import { IDataboxLivesIcon } from 'src/app/api/modules/core/dynamic/databoxes/lives-icon/IDataboxLivesIcon';
import { GenerateFaIcon } from 'src/app/factories/generators/icon-fa.generator';
import { ElfWrite } from 'src/app/util/ElfWrite';

/**
 * A Built in Default State
 */
function DEFAULT_ICON() {
  return GenerateFaIcon({
    name: 'fas:heart',
    state: 'default',
  });
}

/**
 * The Default State
 */
function initialState(): Required<IDataboxLivesIcon> {
  return {
    selector: 'app-databox-lives-icon',
    icon: DEFAULT_ICON(),
    lives: 0,
  };
}

/**
 * The Store used for a {@link DataboxLivesIconComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DataboxLivesIconRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `databox-lives-icon-${uniqueId()}`,
    },
    withProps<Required<IDataboxLivesIcon>>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Applies the incoming configuration to the model
   */
  applyInitialize(configuration?: Partial<IDataboxLivesIcon>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      return;
    }

    let lives = 0;
    if (typeof configuration.lives === 'number' && configuration.lives >= 0) {
      lives = configuration.lives;
    }

    let icon = cloneDeep(DEFAULT_ICON());
    if (configuration.icon) {
      icon = cloneDeep(configuration.icon);
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.lives = lives;
        state.icon = icon;
      }),
    );
  }

  /**
   * When the users lives updates
   */
  applyUpdate(livesRemaining: number) {
    if (typeof livesRemaining !== 'number' || livesRemaining < 0) {
      return;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.lives = livesRemaining;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * What icon has been configured?
   */
  icon$ = this.store.pipe(select((state) => state.icon));

  /**
   * How many lives are remaining?
   */
  lives$ = this.store.pipe(
    select((state) => state.lives),
    map((lives) => lives.toString()),
  );
}
