import { animation, sequence, useAnimation } from '@angular/animations';
import { ANIMATION_DISPLAY_DURATION } from '../AnimationConsts';
import { IAnimationCompoundOptions } from '../IAnimationCompoundOptions';
import { bounceInAnimation, bounceOutAnimation } from '../parts/bounce.animation';
import { ICommonAnimation } from '../parts/common.animation';

export const bounceInOutCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enterData: ICommonAnimation = {
    startOpacity: 0,
    ...options.enter,
  };
  const enter = bounceInAnimation(enterData);

  const exitData: ICommonAnimation = {
    endOpacity: 0,
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  };
  const exit = bounceOutAnimation(exitData);

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};
