@if (mediaLockableRepository.configured$ | async) {
  <div class="container" [class.limit-portrait-width-on-desktop]="mediaLockableRepository.limitPortraitWidthOnDesktop$ | async">
    @if (mediaLockableRepository.media$ | async; as media) {
      @if (media) {
        <app-media
          class="media"
          [class.disable-pointer]="mediaLockableRepository.initialized$ | async"
          [config]="media"
          (ready)="onMediaReady()"
          (playing)="onMediaPlaying()"
          (currentTime)="onMediaCurrentTime($event)"
          (duration)="onMediaDuration($event)"
          (error)="onMediaError()"
          (isPortrait)="onMediaIsPortrait($event)"
        ></app-media>
      }
    }
    <!-- Display a Progress Bar representing how long the UI is locked for -->
    @if (mediaLockableRepository.shouldDisplayProgress$ | async) {
      <mat-progress-bar
        class="progress disable-pointer"
        [class.hide-progress]="(mediaLockableRepository.initialized$ | async) !== true"
        mode="determinate"
        [value]="mediaLockableRepository.progress$ | async"
      ></mat-progress-bar>
    }
  </div>
}
