<!-- If a configuration exists-->
@if (dialogMediaLockRepository.configured$ | async) {
  <div>
    <!-- The ng-container is not rendered but structural only to hold the async pipe -->
    @if (dialogMediaLockRepository.header$ | async; as header) {
      @if (header) {
        <h2 mat-dialog-title>{{ header }}</h2>
      }
    }
    <!-- Set the content -->
    <mat-dialog-content>
      <!-- The ng-container is not rendered but structural only to hold the async pipe -->
      @if (dialogMediaLockRepository.lock$ | async; as lock) {
        @if (lock) {
          <app-media-lockable class="lock" [config]="lock" (unlocked)="mediaLockUnlocked()" (error)="mediaLockError()"></app-media-lockable>
        }
      }
    </mat-dialog-content>
    <!-- Set the Actions -->
    @if (dialogMediaLockRepository.footer$ | async; as footer) {
      <mat-dialog-actions [fxLayout]="footer.layout || 'row'" [fxLayoutAlign]="footer.layoutAlign || 'center center'">
        <!-- The ng-container is not rendered but structural only to hold the async pipe -->
        @if (dialogMediaLockRepository.button$ | async; as button) {
          @if (button) {
            <app-button class="ui-button" @dialogMediaLockButtonAnimation [config]="button" (click)="onNegativeClick()"></app-button>
          }
        }
      </mat-dialog-actions>
    }
  </div>
}
