@if (config) {
  <div class="group" fxLayout="row" fxLayoutGap="4px">
    @for (segment of config.next.asString; track $index; let i = $index) {
      <div class="segment">
        <div class="display">
          <div class="display-top">{{ config.next.asString[i] }}</div>
          <div class="display-bottom">{{ config.prev.asString[i] }}</div>
          <div class="overlay">
            <div class="overlay-top" [@countdownFlipTopAnimation]="config.next.asString[i]">{{ config.next.asString[i] }}</div>
            <div class="overlay-bottom" [@countdownFlipBottomAnimation]="config.next.asString[i]">{{ config.next.asString[i] }}</div>
          </div>
        </div>
      </div>
    }
  </div>
}
@if (config?.label) {
  <div class="label">{{ config.label }}</div>
}
