import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { IPluginTextEntity } from 'src/app/api/modules/core/dynamic/plugin/components/IPluginTextEntity';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { PluginTextModel } from './plugin-text.model';

/**
 * The Default State
 */
function initialState(): PluginTextModel {
  return {
    entities: {},
  };
}

/**
 * The Store used for a {@link TextRichComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class PluginTextRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `plugin-text-${uniqueId()}`,
    },
    withProps<PluginTextModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Adds a new entity to the store or updates an existing one.
   * @param id The unique identifier for the entity.
   * @param config The configuration object for the entity, conforming to IPluginTextEntity.
   */
  add(id: string, config: IPluginTextEntity) {
    if (!id || !config) {
      return;
    }

    // Update the store with the new entities object
    this.store.update(
      ElfWrite((state) => {
        state.entities[id] = config;
      }),
    );
  }

  /**
   * Removes an entity from the store.
   * @param id The unique identifier for the entity to be removed.
   */
  remove(id?: string) {
    if (!id) {
      return;
    }

    // Update the store with the new entities object, reflecting the removal
    this.store.update(
      ElfWrite((state) => {
        delete state.entities[id];
      }),
    );
  }

  /**
   * Clears all entities from the store.
   */
  clear() {
    // Update the store with an empty entities object
    this.store.update(
      ElfWrite((state) => {
        state.entities = {};
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Selects the entities from the store.
   */
  entities$ = this.store.pipe(select((state) => state.entities));
}
