@if (faIconRepository.templateData$ | async; as templateData) {
  <fa-icon
    [size]="templateData.size"
    [fixedWidth]="templateData.fixedWidth"
    [style.color]="templateData.color"
    [pull]="templateData.pull"
    [rotate]="templateData.rotate"
    [flip]="templateData.flip"
    [icon]="templateData.name | faIconName"
    [mask]="templateData.mask"
    [transform]="templateData.transform"
  ></fa-icon>
}
