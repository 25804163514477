@if (socialSharingButtonRepository.templateData$ | async; as templateData) {
  @if (templateData.link; as link) {
    <app-abstract-link [config]="link" (click)="onClicked($event)">
      <div fxLayoutAlign="center center" [ngClass]="templateData.platformStyle" [class.homepage]="templateData.type === 'homepage'">
        @if (templateData.platformIcon; as icon) {
          <app-fa-icon [config]="icon"></app-fa-icon>
        }
      </div>
    </app-abstract-link>
  }
}
