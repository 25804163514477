import { Injectable } from '@angular/core';
import { ICoreContainer } from 'src/app/api/modules/core/dynamic/ICoreContainer';
import { ContainerRepository } from './container.repository';

/**
 * Service for initializing the state of a container component. It interfaces with the
 * ContainerStore to apply configuration settings that determine how the container component
 * should be rendered and behave within the application.
 *
 * This service acts as a mediator between the container component and the underlying store,
 * ensuring that state initialization is encapsulated within a service layer.
 */
@Injectable()
export class ContainerService {
  /**
   * Instantiates the ContainerService with a reference to the ContainerStore.
   * This store is where the container state is held and managed.
   *
   * @param containerRepository The ContainerStore instance that will be manipulated by this service.
   */
  constructor(private readonly containerRepository: ContainerRepository) {}

  /**
   * Initializes the container with a given configuration. This method is responsible for
   * invoking the store's initialization logic with the provided configuration object.
   * The configuration is expected to conform to the ICoreContainer interface, which should
   * define the necessary properties and structure for container initialization.
   *
   * @param configuration Optional configuration object for the container. If provided,
   *                      it is used to configure the container's initial state. If omitted,
   *                      this will throw an error.
   */
  initialize(configuration?: ICoreContainer) {
    this.containerRepository.applyInitialize(configuration);
  }
}
