import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { ITextRich } from 'src/app/api/modules/core/dynamic/components/ITextRich';
import { SafeHtmlPipe } from '../../../../safe-pipes/pipes/safe.html';
import { TextRichRepository } from './text-rich.repository';

/**
 * The Text Rich Component is used to bind raw HTMLDomStrings to a Component.
 */
@Component({
  selector: 'app-text-rich',
  templateUrl: './text-rich.component.html',
  styleUrls: ['./text-rich.component.scss'],
  providers: [TextRichRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, ExtendedModule, AsyncPipe, SafeHtmlPipe],
})
export class TextRichComponent {
  /**
   * The configuration required for this object to function
   */
  private _config: ITextRich;
  @Input() set config(configuration: ITextRich) {
    this._config = configuration;
    this.repository.applyConfiguration(configuration);
  }
  get config(): ITextRich {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly repository: TextRichRepository) {}
}
