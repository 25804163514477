import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription, filter, switchMap, tap } from 'rxjs';
import { ILeaderboard, ILeaderboardFilterOptions } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboard';
import { ILeaderboardEntry } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboardEntry';
import { ILeaderboardDataResponse } from 'src/app/api/modules/core/dynamic/leaderboard/network/ILeaderboardDataResponse';
import { FaIconComponent } from '../../../../icons/components/fa-icon/fa-icon.component';
import { TextRichComponent } from '../text-rich/text-rich.component';
import { leaderboardEntryBelongsToAnimation, leaderboardEntryLifecycleAnimation } from './animation/leaderboard.animation';
import { LeaderboardEntryComponent } from './components/leaderboard-entry.component';
import { LeaderboardRepository } from './leaderboard.repository';
import { LeaderboardService } from './leaderboard.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
  providers: [LeaderboardService, LeaderboardRepository],
  animations: [leaderboardEntryLifecycleAnimation, leaderboardEntryBelongsToAnimation],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FlexModule,
    MatProgressSpinner,
    TextRichComponent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    FaIconComponent,
    LeaderboardEntryComponent,
    NgStyle,
    NgClass,
    AsyncPipe,
  ],
})
export class LeaderboardComponent implements OnDestroy, OnInit, AfterViewInit {
  /**
   * The Config as provided to the element
   */
  private _config?: ILeaderboard;
  @Input()
  set config(configuration: ILeaderboard) {
    this._config = configuration;
    if (configuration) {
      this.leaderboardService.applyInitialize(configuration);
    }
  }
  get config() {
    return this._config;
  }

  /**
   * In Debug Mode, network calls are not made, this is used with the demo.
   */
  private _debug = false;
  @Input()
  set debug(isDebug: boolean) {
    this._debug = isDebug;
    this.leaderboardService.applyIsDebug(isDebug);
  }
  get debug() {
    return this._debug;
  }

  // used for teardown
  private pollingSubscription?: Subscription;
  private filterSubscription?: Subscription;

  // used to power the optional material select filter
  form?: FormGroup;

  /**
   * Constructor
   */
  constructor(
    private readonly leaderboardService: LeaderboardService,
    public readonly leaderboardRepository: LeaderboardRepository,
    private readonly fb: FormBuilder,
  ) {}

  //////////////////////////////////////////////////////////////////////////
  // Life Cycle
  //////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle
   */
  ngOnInit() {
    // we need to create a form control as soon as possible
    this.filterSubscription = this.leaderboardRepository.hasFilters$
      .pipe(
        // only proceed if filters are enabled
        filter((hasFilters) => hasFilters),
        // get the active filters
        switchMap(() => this.leaderboardRepository.activeFilters$),
        // create the form from the filters
        tap((activeFilters) => {
          this.form = this.fb.group(activeFilters);
        }),
        // listen for when the form value changes
        switchMap(() => {
          return this.form.valueChanges;
        }),
      )
      .subscribe((filters) => {
        // update the store with the latest filters
        this.leaderboardService.applyUpdateFilters(filters);

        // schedule the next data poll
        this.scheduleDataPolling();
      });
  }

  /**
   * Lifecycle.
   */
  ngAfterViewInit() {
    // schedule the first data poll
    this.scheduleDataPolling();
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.pollingSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
  }

  //////////////////////////////////////////////////////////////////////////
  // Material Select Filter
  //////////////////////////////////////////////////////////////////////////

  /**
   * Used with the optional Material Select Filter to determine state.
   */
  compareWithFn(c1?: ILeaderboardFilterOptions, c2?: ILeaderboardFilterOptions): boolean {
    return c1?.value === c2?.value;
  }

  //////////////////////////////////////////////////////////////////////////
  // Leaderboard Table Tracking
  //////////////////////////////////////////////////////////////////////////

  /**
   * Trackby avoids repainting the whole list for any change.
   *
   * @param index - the index of the item in the list.
   * @param item - the item itself containing an id.
   */
  trackBy(index: number, item?: ILeaderboardEntry) {
    return item?.id;
  }

  //////////////////////////////////////////////////////////////////////////
  // Debug Only
  //////////////////////////////////////////////////////////////////////////

  /**
   * Used for Debugging only on Demos.
   */
  processData(response: ILeaderboardDataResponse) {
    this.leaderboardService.applyLeaderboardUpdateData(response);
  }

  //////////////////////////////////////////////////////////////////////////
  // Network Polling
  //////////////////////////////////////////////////////////////////////////

  /**
   * Configures polling data for entries to the leaderboard.
   */
  private scheduleDataPolling() {
    // clear any existing schedule
    this.pollingSubscription?.unsubscribe();

    // create a new subscription for polling
    this.pollingSubscription = this.leaderboardRepository.scheduleSettings$
      .pipe(
        switchMap((config) => {
          const { onDataAPI, onInterval, filters } = config;

          return this.leaderboardService.createAPIRequest(onInterval, onDataAPI, filters);
        }),
      )
      .subscribe(() => {
        this.pollingSubscription?.unsubscribe();
        this.scheduleDataPolling();
      });
  }
}
