import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { filter, map } from 'rxjs';
import { DataboxContainerSupportedTypes, IDataboxContainer } from 'src/app/api/modules/core/dynamic/databoxes/IDataboxContainer';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DataboxContainerModel } from './databox-container.model';

/**
 * The Default State
 */
function initialState(): DataboxContainerModel {
  return {
    configured: false,
    componentArray: [],
  };
}

/**
 * The Store used for a {@link DataboxContainerComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DataboxContainerRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `databox-container-${uniqueId()}`,
    },
    withProps<DataboxContainerModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the DataboxContainerStore with a configuration.
   * @param configuration - The configuration for the DataboxContainer.
   * @throws Throws an error if no configuration is provided.
   */
  applyInitialize(configuration?: IDataboxContainer) {
    if (!configuration) {
      throw createDigitaServiceError(`DataboxContainer`, `initialze`, `All containers required a configuration to be provided.`, `config`);
    }

    // component array
    let componentArray: DataboxContainerSupportedTypes[] = [];
    if (configuration.componentArray) {
      componentArray = cloneDeep(configuration.componentArray);
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.componentArray = componentArray;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Stream that emits when the screen is configured.
   * Filters the configured property of the state, emitting only when it is true.
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((configured) => configured),
  );

  /**
   * Stream that emits the current array of components for the screen.
   * This observable allows subscribers to react to changes in the components array.
   */
  private _componentArray$ = this.store.pipe(select((state) => state.componentArray));

  ///////////////////////////////////////////////////////////////////////////
  // SCREEN CONFIGURATION
  ///////////////////////////////////////////////////////////////////////////

  /**
   * Publicly exposed observable that combines the configured status and component array streams.
   * It emits the component array only when the screen is configured.
   * This is useful for consumers that need to react to changes in the component array
   * but only after the screen has been fully configured.
   */
  componentArray$ = ElfCombineQueries([this._configured$, this._componentArray$]).pipe(
    filter(([configured]) => configured),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, componentArray]) => componentArray),
  );
}
