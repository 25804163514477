import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { map } from 'rxjs';
import { ILeaderboardEntry } from 'src/app/api/modules/core/dynamic/leaderboard/ILeaderboardEntry';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { LeaderboardEntryModel } from './leaderboard-entry.model';

/**
 * The Default State
 */
function initialState(): LeaderboardEntryModel {
  return {
    id: undefined,
    rendered: false,
    belongsToUser: false,
    changed: false,
    data: [],
    headerCount: 0,
  };
}

/**
 * The Store used for an {@link LeaderboardEntryComponent}
 */
@Injectable()
export class LeaderboardEntryRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `leaderboard-entry-${uniqueId()}`,
    },
    withProps<LeaderboardEntryModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyInitialize(configuration?: Partial<ILeaderboardEntry>) {
    // if there is no configuration then that is an error
    if (!configuration) {
      return;
    }

    const id = configuration.id;
    if (!id) {
      throw createDigitaServiceError(`LeaderboardEntry`, `initialize`, `No id was provided for the entry.`, `config`);
    }

    let belongsToUser = false;
    if (configuration.belongsToUser === true) {
      belongsToUser = true;
    }

    let changed = false;
    if (configuration.changed === true) {
      if (!belongsToUser) {
        changed = true;
      }
    }

    const data = configuration.data ? cloneDeep(configuration.data) : [];

    this.store.update(
      ElfWrite((state) => {
        state.id = id;
        state.belongsToUser = belongsToUser;
        state.changed = changed;
        state.data = data;
      }),
    );
  }

  /**
   * Set the number of headers in the table.
   *
   * @param count - The number of headers in the table.
   */
  applyHeaderCount(count: number) {
    this.store.update(
      ElfWrite((state) => {
        state.headerCount = count;
      }),
    );
  }

  /**
   * Has the component been rendered?
   */
  applyRendered() {
    this.store.update(
      ElfWrite((state) => {
        state.rendered = true;
      }),
    );
  }

  /**
   * Occurs when the highlight has completed.
   */
  appyHighlightComplete() {
    this.store.update(
      ElfWrite((state) => {
        state.changed = false;
      }),
    );
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERY
  ////////////////////////////////////////////////////////////////////

  /**
   * Belongs to the user?
   */
  private _belongsToUser$ = this.store.pipe(select((state) => state.belongsToUser));

  /**
   * Has the entry changed?
   */
  private _changed$ = this.store.pipe(select((state) => state.changed));

  /**
   * Has the entry been rendered?
   */
  private _rendered$ = this.store.pipe(select((state) => state.rendered));

  /**
   * Is there an important change?
   */
  hasImportantChange$ = ElfCombineQueries([this._belongsToUser$, this._changed$, this._rendered$]).pipe(
    map(([belongsToUser, changed, rendered]) => {
      if (!belongsToUser) {
        if (changed && rendered) {
          return true;
        }
      }
      return false;
    }),
  );

  /**
   * Retrieve the data for the entry.
   */
  private _data$ = this.store.pipe(select((state) => state.data));

  /**
   * The number of headers in the table.
   */
  private _headercount$ = this.store.pipe(select((state) => state.headerCount));

  /**
   * The data used in the template.
   */
  templateData$ = ElfCombineQueries([this._belongsToUser$, this.hasImportantChange$, this._data$, this._headercount$]).pipe(
    map(([belongsToUser, hasImportantChange, data, headerCount]) => {
      return {
        belongsToUser,
        hasImportantChange,
        data,
        headerCount,
      };
    }),
  );
}
