import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { IDataboxAttemptsIcon } from 'src/app/api/modules/core/dynamic/databoxes/attempts-icon/IDataboxAttemptsIcon';
import { IconStateComponent } from '../../../../../icons/components/icon-state/icon-state.component';
import { DataboxAttemptsIconRepository } from './databox-attempts-icon.repository';

@Component({
  selector: 'app-databox-attempts-icon',
  templateUrl: './databox-attempts-icon.component.html',
  styleUrls: ['./databox-attempts-icon.component.scss'],
  providers: [DataboxAttemptsIconRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, IconStateComponent, AsyncPipe],
})
export class DataboxAttemptsIconComponent {
  /**
   * The Configuration powering the component.
   */
  private _config?: IDataboxAttemptsIcon;
  @Input()
  set config(configuration: IDataboxAttemptsIcon) {
    this._config = configuration;
    this.databoxAttemptsIconRepository.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly databoxAttemptsIconRepository: DataboxAttemptsIconRepository) {}

  /**
   * Updates the component
   *
   * @param stateList - an array of states to show
   */
  update(stateList: string[]) {
    this.databoxAttemptsIconRepository.applyUpdate(stateList);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.databoxAttemptsIconRepository.applyReset();
  }
}
