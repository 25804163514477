@if (mediaLockRepository.configured$ | async) {
  <div class="container">
    <!-- The ng-container is not rendered but structural to hold the asyc pipe -->
    @if (mediaLockRepository.lock$ | async; as lock) {
      @if (lock) {
        <app-media-lockable class="lock" [config]="lock" (unlocked)="mediaLockUnlocked()" (error)="mediaLockError()"></app-media-lockable>
      }
    }
    <div class="ui-container" fxLayout="row" fxLayoutAlign="center center">
      <!-- The ng-container is not rendered but structural to hold the asyc pipe -->
      @if (mediaLockRepository.button$ | async; as button) {
        @if (button) {
          <app-button class="ui-button" @mediaLockButtonAnimation [config]="button"></app-button>
        }
      }
    </div>
  </div>
}
