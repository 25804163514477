import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { filter, map, switchMap } from 'rxjs';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import {
  ButtonStyleTypes,
  ButtonTypes,
  IButton,
  IButtonFeatureCopyToClipboard,
  OldButtonStyleTypes,
} from 'src/app/api/modules/core/dynamic/components/IButton';
import { IMatIcon } from 'src/app/api/modules/icons/mat/IMatIcon';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ButtonModel } from './button.model';

/**
 * The Default State
 */
function initialState(): ButtonModel {
  return {
    configured: false,
    hasLabel: false,
    label: undefined,
    type: 'button',
    style: 'basic',
    hasIconPostfix: false,
    iconPostfix: undefined,
    hasIconPrefix: false,
    iconPrefix: undefined,
    link: undefined,
    hasClickToCloseFeature: false,
    hasCopyToClipboardFeature: false,
    hasPrintFeature: false,
    copyToClipboard: undefined,
    isTextured: undefined,
    hasDialog: false,
    dialog: undefined,
    disabled: false,
    belongsToForm: false,
    form: undefined,
    extended: false,
    tabIndex: undefined,
  };
}

/**
 * The Store used for an {@link ButtonComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class ButtonRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `button-${uniqueId()}`,
    },
    withProps<ButtonModel>(initialState()),
  );

  constructor(private readonly loggerService: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: Partial<IButton>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      throw createDigitaServiceError(`ButtonStore`, `applyInitialize`, `No configuration provided`, `config`);
    }

    // button type
    let type: ButtonTypes = 'button';
    if (configuration.type === 'link') {
      if (configuration.link?.href) {
        type = 'link';
      } else {
        this.loggerService.warn(
          `[Button] - The configured button with 'type: "link"' was requested but no 'link' property was configured.`,
        );
      }
    }

    // the default is `basic`
    let style: ButtonStyleTypes | OldButtonStyleTypes = configuration.style || 'basic';

    // firstly deal with the deprecated styles
    switch (style) {
      case `mat-button`:
        this.loggerService.warn(`[Button] - The type "mat-button" is deprecated and should be replaced with "basic".`);
        style = 'basic';
        break;
      case `mat-stroked-button`:
        this.loggerService.warn(`[Button] - The type "mat-stroked-button" is deprecated and should be replaced with "stroked".`);
        style = 'stroked';
        break;
      case `mat-raised-button`:
        this.loggerService.warn(`[Button] - The type "mat-raised-button" is deprecated and should be replaced with "raised".`);
        style = 'raised';
        break;
      case `mat-flat-button`:
        this.loggerService.warn(`[Button] - The type "mat-flat-button" is deprecated and should be replaced with "flat".`);
        style = 'flat';
        break;
      case `mat-icon-button`:
        this.loggerService.warn(`[Button] - The type "mat-icon-button" is deprecated and should be replaced with "icon".`);
        style = 'icon';
        break;
      case `mat-fab`:
        this.loggerService.warn(`[Button] - The type "mat-fab" is deprecated and should be replaced with "fab".`);
        style = 'fab';
        break;
      case `mat-mini-fab`:
        this.loggerService.warn(`[Button] - The type "mat-mini-fab" is deprecated and should be replaced with "mini-fab".`);
        style = 'mini-fab';
        break;
    }

    // now deal with the modern styles
    switch (style) {
      case `basic`:
      case `raised`:
      case `flat`:
      case `stroked`:
      case `icon`:
      case `fab`:
      case `mini-fab`:
        break;
      default:
        this.loggerService.warn(`[Button] - The configured button style "${style}" is not supported and has been changed to "basic".`);
        style = 'basic';
        break;
    }

    // extended (used for mat-fab to allow them to grow with a label)
    let extended = false;
    if (style === 'fab' && configuration.extended === true) {
      extended = true;
    }

    // button color

    if (configuration.color) {
      this.loggerService.warn(
        `[Button] - The "color" property is no longer supported and has been ignored. Remove it from the configuration.`,
      );
    }

    // button label
    let hasLabel = false;
    let label: string | undefined = undefined;
    if (configuration.label) {
      if (style === `icon` || style === `mini-fab` || (style === `fab` && !extended)) {
        this.loggerService.warn(
          `[Button] - The "label" property is not allowed for buttons with style "icon", "mini-fab", or "fab" with "extended: false". It has been ignored.`,
        );
      } else if (style === `basic` || style === `raised` || style === `flat` || style === `stroked` || style === `fab`) {
        if (typeof configuration.label === 'string' && configuration.label.length > 0) {
          hasLabel = true;
          label = configuration.label;
        }
      }
    } else {
      if (style === `basic` || style === `raised` || style === `flat` || style === `stroked`) {
        this.loggerService.warn(
          `[Button] - The "label" property is required for basic, raised, flat, stroked buttons. It should be added.`,
        );
      }
    }

    // icon
    if (configuration.icon) {
      this.loggerService.warn(
        `[Button] - The "icon" property is no longer supported. You should use "iconPrefix" or "iconPostfix" instead.`,
      );
    }

    // icon prefix
    let hasIconPrefix = false;
    let iconPrefix: IMatIcon | undefined = undefined;
    if (configuration.iconPrefix) {
      hasIconPrefix = true;
      iconPrefix = cloneDeep(configuration.iconPrefix);
    }

    // icon postfix
    let hasIconPostfix = false;
    let iconPostfix: IMatIcon | undefined = undefined;
    if (configuration.iconPostfix) {
      hasIconPostfix = true;
      iconPostfix = cloneDeep(configuration.iconPostfix);
    }

    // if the style of the button is `icon`, `fab` (extended: false), or `mini-fab`
    if (style === `icon` || (style === `fab` && !extended) || style === `mini-fab`) {
      // if there is both a prefix and postfix icon then use the prefix
      if (hasIconPrefix && hasIconPostfix) {
        hasIconPostfix = false;
        iconPostfix = undefined;
      } else if (!hasIconPrefix && hasIconPostfix) {
        hasIconPrefix = true;
        iconPrefix = iconPostfix;
        hasIconPostfix = false;
        iconPostfix = undefined;
      }
    }

    // check for iconPrefix.color deprecation
    if (hasIconPrefix && iconPrefix?.color) {
      this.loggerService.warn(`[Button] - The "iconPrefix.color" property is deprecated on buttons. Remove it from the configuration.`);
    }
    // check for iconPostfix.color deprecation
    if (hasIconPostfix && iconPostfix?.color) {
      this.loggerService.warn(`[Button] - The "iconPostfix.color" property is deprecated on buttons. Remove it from the configuration.`);
    }

    // check for iconPrefix.align deprecation
    if (hasIconPrefix && iconPrefix?.align) {
      this.loggerService.warn(`[Button] - The "iconPrefix.align" property is deprecated on buttons. Remove it from the configuration.`);
    }
    // check for iconPostfix.align deprecation
    if (hasIconPostfix && iconPostfix?.align) {
      this.loggerService.warn(`[Button] - The "iconPostfix.align" property is deprecated on buttons. Remove it from the configuration.`);
    }

    // link
    let link: ILink | undefined = undefined;
    if (type === 'link' && configuration.link) {
      link = cloneDeep(configuration.link);
    }

    // feature
    let hasCopyToClipboardFeature = false;
    let copyToClipboard: IButtonFeatureCopyToClipboard | undefined = undefined;
    let hasClickToCloseFeature = false;
    let hasPrintFeature = false;
    if (configuration.feature) {
      if (configuration.feature.copyToClipboard) {
        hasCopyToClipboardFeature = true;
        copyToClipboard = cloneDeep(configuration.feature.copyToClipboard);
      } else if (configuration.feature.clickToClose) {
        hasClickToCloseFeature = true;
      } else if (configuration.feature.print) {
        hasPrintFeature = true;
      }
    }

    // isTextured
    let isTextured = false;
    if (configuration.isTextured) {
      isTextured = configuration.isTextured;
    }

    // dialog
    let hasDialog = false;
    let dialog: IDialogContent | undefined = undefined;
    if (configuration.dialog) {
      hasDialog = true;
      dialog = cloneDeep(configuration.dialog);
    }

    // tab index
    let tabIndex: number | undefined = undefined;
    if (typeof configuration.tabIndex === 'number') {
      tabIndex = configuration.tabIndex;
    }

    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.hasLabel = hasLabel;
        state.label = label;
        state.type = type;
        state.style = style;
        state.hasIconPrefix = hasIconPrefix;
        state.iconPrefix = iconPrefix;
        state.hasIconPostfix = hasIconPostfix;
        state.iconPostfix = iconPostfix;
        state.link = link;
        state.hasClickToCloseFeature = hasClickToCloseFeature;
        state.hasCopyToClipboardFeature = hasCopyToClipboardFeature;
        state.copyToClipboard = copyToClipboard;
        state.hasPrintFeature = hasPrintFeature;
        state.isTextured = isTextured;
        state.hasDialog = hasDialog;
        state.dialog = dialog;
        state.extended = extended;
        state.tabIndex = tabIndex;
      }),
    );
  }

  /**
   * Is the button disabled?
   *
   * @param isDisabled - true if disabled, false otherwise.
   */
  applyDisabled(isDisabled: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.disabled = isDisabled;
      }),
    );
  }

  /**
   * Does the button belong to a form?
   *
   * @param formName - the name of the form to which the button belongs.
   */
  applyForm(formName?: string) {
    let belongsToForm = false;
    if (formName && formName.length > 0) {
      belongsToForm = true;
    } else {
      formName = undefined;
    }

    this.store.update(
      ElfWrite((state) => {
        state.belongsToForm = belongsToForm;
        state.form = formName;
      }),
    );
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERY
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the Button Configured?
   */
  private _configured$ = this.store.pipe(select((state) => state.configured));

  /**
   * Does the Button have a label?
   */
  private _hasLabel$ = this.store.pipe(select((state) => state.hasLabel));

  /**
   * If `hasLabel` then what is the configuration of the label?
   */
  private _label$ = this.store.pipe(select((state) => state.label));

  /**
   * What is the type of the button?
   */
  private _type$ = this.store.pipe(select((state) => state.type));

  /**
   * What is the style of the button?
   */
  private _style$ = this.store.pipe(select((state) => state.style));

  /**
   * What is the color of the button?
   */
  private _color$ = this.store.pipe(select((state) => state.color));

  /**
   * Does the Button have an Icon Prefix?
   */
  private _hasIconPrefix$ = this.store.pipe(select((state) => state.hasIconPrefix));

  /**
   * If `hasIconPrefix` what is the configuration of that icon?
   */
  private _iconPrefix$ = this.store.pipe(select((state) => state.iconPrefix));

  /**
   * The Icon Prefix Name
   */
  private _iconPrefixName$ = ElfCombineQueries([this._hasIconPrefix$, this._iconPrefix$]).pipe(
    map(([hasIcon, icon]) => {
      if (hasIcon && icon.name) {
        return icon.name;
      } else {
        return null;
      }
    }),
  );

  /**
   * Does the Button have an Icon Postfix?
   */
  private _hasIconPostfix$ = this.store.pipe(select((state) => state.hasIconPostfix));

  /**
   * If `hasIconPostfix` what is the configuration of that icon?
   */
  private _iconPostfix$ = this.store.pipe(select((state) => state.iconPostfix));

  /**
   * The Icon Postfix Name
   */
  private _iconPostfixName$ = ElfCombineQueries([this._hasIconPostfix$, this._iconPostfix$]).pipe(
    map(([hasIcon, icon]) => {
      if (hasIcon && icon.name) {
        return icon.name;
      } else {
        return null;
      }
    }),
  );

  /**
   * If `hasLink` what is the data for that link?
   */
  link$ = this.store.pipe(select((state) => state.link));

  /**
   * Does the button contain a clickToClose feature?
   */
  private _hasClickToCloseFeature$ = this.store.pipe(select((state) => state.hasClickToCloseFeature));

  /**
   * Does the button contain a print feature?
   */
  private _hasPrintFeature$ = this.store.pipe(select((state) => state.hasPrintFeature));

  /**
   * Does the button contain a copy to clipboard feature?
   */
  private _hasCopyToClipboardFeature$ = this.store.pipe(select((state) => state.hasCopyToClipboardFeature));

  /**
   * If `hasCopyToClipboardFeature` then what is the configure to use?
   */
  copyToClipboard$ = this.store.pipe(select((state) => state.copyToClipboard));

  /**
   * If any feature is true then this will be true otherwise false.
   */
  private _hasFeature$ = ElfCombineQueries([this._hasClickToCloseFeature$, this._hasCopyToClipboardFeature$, this._hasPrintFeature$]).pipe(
    map(([hasClickToCloseFeature, hasCopyToClipboardFeature, hasPrintFeature]) => {
      if (hasClickToCloseFeature || hasCopyToClipboardFeature || hasPrintFeature) {
        return true;
      } else {
        return false;
      }
    }),
  );

  /**
   * Is the Button Textured?
   */
  private _isTextured$ = this.store.pipe(select((state) => state.isTextured));

  /**
   * Does the Button contain a Dialog?
   */
  private _hasDialog$ = this.store.pipe(select((state) => state.hasDialog));

  /**
   * If `hasDialog` then what is the configured dialog data?
   */
  dialog$ = this.store.pipe(select((state) => state.dialog));

  /**
   * Is the Button Disabled?
   */
  private _disabled$ = this.store.pipe(select((state) => state.disabled));

  /**
   * Does the button belong to a form?
   */
  private _belongsToForm$ = this.store.pipe(select((state) => state.belongsToForm));

  /**
   * If `belongsToForm` then what is the name of the form?
   */
  private _form$ = this.store.pipe(select((state) => state.form));

  /**
   * Is the Button Extended? (applies to mat-fab buttons only)
   */
  private _extended$ = this.store.pipe(select((state) => state.extended));

  /**
   * The tabindex of the Button. (optional)
   */
  private _tabIndex$ = this.store.pipe(select((state) => state.tabIndex));

  /**
   * The ClickAction of the Button gathers information about the configuration in order to
   * determine what should happen when the button is clicked.
   */
  clickAction$ = ElfCombineQueries([
    this._type$,
    this._hasFeature$,
    this._hasDialog$,
    this._disabled$,
    this._hasClickToCloseFeature$,
    this._hasCopyToClipboardFeature$,
    this._hasPrintFeature$,
  ]).pipe(
    map(([type, hasFeature, hasDialog, isDisabled, hasClickToCloseFeature, hasCopyToClipboardFeature, hasPrintFeature]) => {
      return {
        type,
        hasFeature,
        hasDialog,
        isDisabled,
        hasClickToCloseFeature,
        hasCopyToClipboardFeature,
        hasPrintFeature,
      };
    }),
  );

  ////////////////////////////////////////////////////////////////////
  // TEMPLATE
  ////////////////////////////////////////////////////////////////////

  /**
   * Combine all the data into a single object observable to use within the template
   */
  private _templateData$ = ElfCombineQueries([
    this._type$,
    this._style$,
    this._color$,
    this._isTextured$,
    this._disabled$,
    this._tabIndex$,
    this._extended$,
    this._form$,
    this._hasIconPrefix$,
    this._iconPrefixName$,
    this._hasIconPostfix$,
    this._iconPostfixName$,
    this._hasLabel$,
    this._label$,
  ]).pipe(
    map(
      ([
        type,
        style,
        color,
        isTextured,
        disabled,
        tabIndex,
        extended,
        form,
        hasIconPrefix,
        iconPrefixName,
        hasIconPostfix,
        iconPostfixName,
        hasLabel,
        label,
      ]) => {
        return {
          type,
          style,
          color,
          isTextured,
          disabled,
          tabIndex,
          extended,
          form,
          hasIconPrefix,
          iconPrefixName,
          hasIconPostfix,
          iconPostfixName,
          hasLabel,
          label,
        };
      },
    ),
  );

  /**
   * The Template Data used within the Template markup.
   */
  templateData$ = this._configured$.pipe(
    filter((configured) => configured),
    switchMap(() => this._templateData$),
  );
}
