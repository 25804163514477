<!-- If a configuration exists-->
@if (dialogContentRepository.templateData$ | async; as templateData) {
  <div>
    <!-- Set the title -->
    @if (templateData.header) {
      <h2 mat-dialog-title>{{ templateData.header }}</h2>
    }
    <!-- Set the content -->
    @if (templateData.content) {
      <mat-dialog-content>
        <!-- The content is an app container similar to a screen -->
        <app-container [config]="templateData.content"></app-container>
      </mat-dialog-content>
    }
    <!-- Set the Actions -->
    @if (templateData.footer) {
      <mat-dialog-actions
        [fxLayout]="templateData.footer.layout || 'row-reverse'"
        [fxLayoutAlign]="templateData.footer.layoutAlign || 'space-between center'"
      >
        <!-- The Positive Button -->
        @if (templateData.footer.positiveButton) {
          <app-button [config]="templateData.footer.positiveButton" (buttonClick)="onPositiveClick($event)"> </app-button>
        }
        <!-- The Negative Button -->
        @if (templateData.footer.negativeButton) {
          <app-button [config]="templateData.footer.negativeButton" (buttonClick)="onNegativeClick($event)"> </app-button>
        }
      </mat-dialog-actions>
    }
  </div>
}
