import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * These animations are applied to the ghost component.
 */
export const imageGhostExitAnimation = trigger('imageGhostExitAnimation', [
  state('true', style({ backgroundColor: `transparent` })),
  transition('* <=> true', animate('400ms 100ms cubic-bezier(0.645, 0.045, 0.355, 1)')),
]);

/**
 * These animations are applied to the spinner component.
 */
export const imageProgressSpinnerAnimations = [
  trigger('progressSpinnerfadeInOut', [transition(':enter', [style({ opacity: 0 }), animate('1s 1s ease-in', style({ opacity: 1 }))])]),
];
