import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxProgressionNumeric } from 'src/app/api/modules/core/dynamic/databoxes/progression-numeric/IDataboxProgressionNumeric';
import { DataboxProgressionNumericRepository } from './databox-progression-numeric.repository';

@Component({
  selector: 'app-databox-progression-numeric',
  templateUrl: './databox-progression-numeric.component.html',
  styleUrls: ['./databox-progression-numeric.component.scss'],
  providers: [DataboxProgressionNumericRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class DataboxProgressionNumericComponent {
  private _config?: IDataboxProgressionNumeric;
  /**
   * The Configuration powering the component.
   */
  @Input()
  set config(configuration: IDataboxProgressionNumeric) {
    this._config = configuration;
    this.databoxProgressionNumericRepository.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly databoxProgressionNumericRepository: DataboxProgressionNumericRepository) {}

  /**
   * Updates the component with the users progress
   *
   * @param current - the current progress of the user
   * @param total - the total amount of progress possible.
   */
  update(current: number, total?: number) {
    this.databoxProgressionNumericRepository.applyUpdate(current, total);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.databoxProgressionNumericRepository.applyReset();
  }
}
