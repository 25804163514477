<div class="mat-mdc-table-scroller">
  <!-- Only subscribe once at the top level using ng-container -->
  @if (repository.templateData$ | async; as templateData) {
    <table class="mat-mdc-table">
      <!-- Use another ng-container to handle header loading -->
      @if (templateData.headers; as headers) {
        @if (headers.length > 0) {
          <thead>
            <tr>
              @for (cell of headers; track $index) {
                <th>
                  <div class="cell-flex">
                    @if (cell) {
                      <span class="mat-body-medium">{{ cell }}</span>
                    }
                  </div>
                </th>
              }
            </tr>
          </thead>
        }
      }
      <!-- Handle rows in a similar fashion -->
      @if (templateData.rows; as rows) {
        @if (rows.length > 0) {
          <tbody>
            @for (row of rows; track $index) {
              <tr>
                @for (cell of row; track cell) {
                  <td>
                    <div class="cell-flex">
                      @if (cell) {
                        <span class="mat-body-medium">{{ cell }}</span>
                      }
                    </div>
                  </td>
                }
              </tr>
            }
          </tbody>
        }
      }
      <!-- Footer, following the same pattern -->
      @if (templateData.footers; as footers) {
        @if (footers.length > 0) {
          <tfoot>
            <tr>
              @for (cell of footers; track $index) {
                <td>
                  <div class="cell-flex">
                    @if (cell) {
                      <span class="mat-body-medium">{{ cell }}</span>
                    }
                  </div>
                </td>
              }
            </tr>
          </tfoot>
        }
      }
    </table>
  }
</div>
