<!-- If the component is configured -->
@if (countdownRepository.configured$ | async) {
  <div class="countdown" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <!-- days (optional) -->
    @if (countdownRepository.days$ | async; as days) {
      @if (days.enabled) {
        <app-countdown-section [config]="days"></app-countdown-section>
      }
    }
    <!-- hours (optional) -->
    @if (countdownRepository.hours$ | async; as hours) {
      @if (hours.enabled) {
        <app-countdown-section [config]="hours"></app-countdown-section>
      }
    }
    <!-- minutes (optional) -->
    @if (countdownRepository.minutes$ | async; as minutes) {
      @if (minutes.enabled) {
        <app-countdown-section [config]="minutes"></app-countdown-section>
      }
    }
    <!-- seconds (required) -->
    @if (countdownRepository.seconds$ | async; as seconds) {
      <app-countdown-section [config]="seconds"></app-countdown-section>
    }
  </div>
}
