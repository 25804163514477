import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { AppService } from '../../../services/application/application.service';

@Component({
  selector: 'app-abstract-link',
  templateUrl: './abstract-link.component.html',
  styleUrls: ['./abstract-link.component.scss'],
  standalone: true,
})
/**
 * An AbstractLink can directly wrap a local component with a <a> and cature that click to send it
 * through a common linking service. The default link behavior is prevented and replaced with our own.
 *
 * See [Link]{@link ILink}
 */
export class AbstractLinkComponent {
  /**
   * Input configuration for the link
   */
  @Input()
  config: ILink;

  /**
   * Output occurs when no href is provided
   */
  @Output()
  clicked = new EventEmitter<MouseEvent | PointerEvent | TouchEvent>(true);

  /**
   * @param windowService - the window service to which all links must pass
   */
  constructor(private appService: AppService) {}

  /**
   * Occurs when the link has been touched
   * @param e - the native click event
   */
  onClicked(e: MouseEvent | PointerEvent | TouchEvent) {
    this.appService.registerFirstInteraction();
    if (this.config && this.config.href && this.config.type !== 'none') {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.appService.openLink(this.config);
    } else {
      this.clicked.emit(e);
    }
  }
}
