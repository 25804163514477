import { Injectable } from '@angular/core';
import { IPluginTextEntity } from 'src/app/api/modules/core/dynamic/plugin/components/IPluginTextEntity';
import { PluginTextRepository } from './plugin-text.repository';

/**
 * Service responsible for managing the state and behavior of text entities within a plugin.
 * It provides a high-level API for initializing and updating the text entity state.
 */
@Injectable()
export class PluginTextService {
  /**
   * Constructor
   *
   * @param store - The store for the plugin text.
   */
  constructor(private readonly pluginTextRepository: PluginTextRepository) {}

  /**
   * Adds a new entity to the store or updates an existing one.
   *
   * @param id The unique identifier for the entity.
   * @param config The configuration object for the entity, conforming to IPluginTextEntity.
   */
  add(id: string, config: IPluginTextEntity) {
    this.pluginTextRepository.add(id, config);
  }

  /**
   * Removes an entity from the store.
   * @param id The unique identifier for the entity to be removed.
   */
  remove(id: string) {
    this.pluginTextRepository.remove(id);
  }

  /**
   * Clears all entities from the store.
   */
  clear() {
    this.pluginTextRepository.clear();
  }
}
