import { Injectable, OnDestroy } from '@angular/core';
import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { EMPTY, delay, filter, map, of, skipWhile, switchMap } from 'rxjs';
import { IMediaLockable, MediaLockType } from 'src/app/api/modules/core/dynamic/components/IMediaLock';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { MediaLockableModel } from './media-lockable.model';

/**
 * The Default State
 */
function initialState(): MediaLockableModel {
  return {
    configured: false,
    initialized: false,
    progress: 0,
    mediaReady: false,
    mediaDuration: 0,
    mediaCurrentTime: 0,
    mediaIsPortrait: false,
    unlocked: false,
    error: false,
    media: undefined,
    target: 0,
    hideProgress: false,
    type: MediaLockType.End,
  };
}

/**
 * The Store used for a {@link MediaLockableComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class MediaLockableRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `media-lockable-${uniqueId()}`,
    },
    withProps<MediaLockableModel>(initialState()),
  );

  constructor(protected readonly mediaObserver: MediaObserver) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IMediaLockable) {
    // if there is no configuration then that is an error
    if (!configuration) {
      throw createDigitaServiceError(
        `MediaLockableStore`,
        `applyConfiguration`,
        `No configuration provided but this is required.`,
        `config`,
      );
    }

    // if there is no media then that is an error
    const media = configuration.media;
    if (!media) {
      throw createDigitaServiceError(
        `MediaLockableStore`,
        `applyConfiguration`,
        `No "media" property provided but this is required.`,
        `config`,
      );
    }

    // type
    let type: MediaLockType = MediaLockType.End;
    if (configuration.type) {
      switch (configuration.type) {
        default:
          break;
        case MediaLockType.Time:
          type = MediaLockType.Time;
          break;
        case MediaLockType.Percent:
          type = MediaLockType.Percent;
          break;
      }
    }

    // target
    let target: number | undefined = undefined;
    if (type === MediaLockType.Time || type === MediaLockType.Percent) {
      if (typeof configuration.target === 'number') {
        if (type === MediaLockType.Time) {
          target = configuration.target;
        } else if (type === MediaLockType.Percent) {
          target = configuration.target;
          if (target < 0) {
            target = 0;
          } else if (target > 100) {
            target = 100;
          }
        }
      } else {
        throw createDigitaServiceError(
          `MediaLockableStore`,
          `applyConfiguration`,
          `No "target" property provided but this is required because "type" property is set to ${type}.`,
          `config`,
        );
      }
    }

    // hideProgress
    let hideProgress = false;
    if (configuration.hideProgress === true) {
      hideProgress = true;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.media = media;
        state.target = target;
        state.type = type;
        state.hideProgress = hideProgress;
      }),
    );
  }

  /**
   * Occurs when the Media starts to play.
   */
  applyInitialize() {
    this.store.update(
      ElfWrite((state) => {
        state.initialized = true;
      }),
    );
  }

  /**
   * An error Occurred.
   */
  applyError() {
    this.store.update(
      ElfWrite((state) => {
        state.error = true;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////
  // MEDIA STATE
  ////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the Media is ready to be played.
   */
  applyMediaIsReady() {
    this.store.update(
      ElfWrite((state) => {
        state.mediaReady = true;
      }),
    );
  }

  /**
   * Apply the Duration of the Media.
   *
   * @param duration - the total duration of the media.
   */
  applyDuration(duration: number) {
    const { unlocked } = this.store.getValue();

    // if ended then do nothing
    if (unlocked) {
      return;
    }

    this.store.update(
      ElfWrite((state) => {
        state.mediaDuration = duration;
      }),
    );

    // update the percentage
    this.updatePercentage();
  }

  /**
   * Apply the Current Time of the Media.
   *
   * @param currentTime - the current time of the media.
   */
  applyCurrentTime(currentTime: number) {
    const { unlocked } = this.store.getValue();

    // if ended then do nothing
    if (unlocked) {
      return;
    }

    // otherwise update the media current time
    this.store.update(
      ElfWrite((state) => {
        state.mediaCurrentTime = currentTime;
      }),
    );

    // update the percentage
    this.updatePercentage();
  }

  /**
   * Calculates the percentage of the media lock and updates the store.
   */
  private updatePercentage() {
    const { initialized, type, target, mediaDuration, mediaCurrentTime } = this.store.getValue();

    // if not initialized then ignore
    if (!initialized || mediaDuration === 0) {
      return;
    }

    // calc
    let percentage = 0;
    switch (type) {
      default: {
        percentage = (mediaCurrentTime / mediaDuration) * 100;
        break;
      }
      case MediaLockType.Time: {
        percentage = (mediaCurrentTime / target) * 100;
        break;
      }
      case MediaLockType.Percent: {
        const durationPercentage = (mediaDuration / 100) * target;
        percentage = (mediaCurrentTime / durationPercentage) * 100;
        break;
      }
    }

    // truncate
    if (percentage > 100) {
      percentage = 100;
    } else if (percentage < 0) {
      percentage = 0;
    }

    // has the media lock ended?
    let unlocked = false;
    if (percentage === 100) {
      unlocked = true;
    }

    this.store.update(
      ElfWrite((state) => {
        state.progress = percentage;
        state.unlocked = unlocked;
      }),
    );
  }

  /**
   * Occurs if the media is in portrait mode or not.
   *
   * @param isPortrait - whether the media is in portrait mode.
   */
  applyMediaIsPortrait(isPortrait: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.mediaIsPortrait = isPortrait;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP & ERRORS
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Is the Media Ready?
   */
  private _mediaReady$ = this.store.pipe(select((state) => state.mediaReady));

  /**
   * Is the Component Configured?
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  private _configured$ = this.store.pipe(select((state) => state.configured));

  /**
   * Has the Media Been Configured.
   */
  configured$ = ElfCombineQueries([this._mediaReady$, this._configured$]).pipe(
    skipWhile((configured) => !configured),
    delay(500),
  );

  /**
   * Once the Media has stopped initializing it becomes initialized.
   */
  initialized$ = this.store.pipe(select((state) => state.initialized));

  /**
   * Has an error occurred?
   */
  error$ = this.store.pipe(select((state) => state.error));

  /**
   * Has the media been unlocked?
   */
  unlocked$ = this.store.pipe(select((state) => state.unlocked));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // MEDIA
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * What is the Media Configuration?
   */
  private _media$ = this.store.pipe(select((state) => state.media));

  /**
   * The Media Configuration becomes avilaible once the Media has been configured.
   */
  media$ = ElfCombineQueries([this.configured$, this._media$]).pipe(
    switchMap(([isConfigured, media]) => {
      if (isConfigured) {
        return of(media);
      }
      return EMPTY;
    }),
  );

  //////////////////////////////////////////////////////////////////////////////////
  // PROGRESS
  //////////////////////////////////////////////////////////////////////////////////

  /**
   * Has the progress bar explicitly been hidden?
   */
  private _hideProgress$ = this.store.pipe(select((state) => state.hideProgress));

  /**
   * Should progress be displayed?
   */
  shouldDisplayProgress$ = this._hideProgress$.pipe(
    map((hideProgress) => {
      if (!hideProgress) {
        return true;
      }
      return false;
    }),
  );

  /**
   * What is the progress of the unlock?
   *
   * This isn't to be confused with the progress of the video.
   */
  progress$ = this.store.pipe(select((state) => state.progress));

  /**
   * Is the media in portrait mode?
   */
  private _isMediaPortrait$ = this.store.pipe(select((state) => state.mediaIsPortrait));

  /**
   * From the Media Observer, get the current breakpoint.
   */
  private _mediaObserver$ = this.mediaObserver.asObservable().pipe(filter((changes: MediaChange[]) => changes.length > 0));

  /**
   * Using the Media Observer and the Media Portrait, determine if the Media should be limited in width on desktop to prevent
   * portrait videos being too big to fit on horizontal screens.
   */
  limitPortraitWidthOnDesktop$ = ElfCombineQueries([this._mediaObserver$, this._isMediaPortrait$]).pipe(
    map(([changes, isMediaPortrait]) => {
      // if the media is not portrait then this doesn't apply
      if (!isMediaPortrait) {
        return false;
      }

      // get the first change
      const targetChange = changes[0];

      // if the media alias is not xs then we want to limit the width
      if (targetChange.mqAlias !== 'xs') {
        return true;
      }

      return false;
    }),
  );
}
