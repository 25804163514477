import { LoggerService } from '@angular-ru/cdk/logger';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { IImage } from 'src/app/api/modules/core/dynamic/components/IImage';
import { FaIconComponent } from '../../../../icons/components/fa-icon/fa-icon.component';
import { AbstractLinkComponent } from '../../../components/abstract/abstract-link/abstract-link.component';
import { imageGhostExitAnimation, imageProgressSpinnerAnimations } from './image.animation';
import { ImageRepository } from './image.repository';
import { ImageService } from './image.service';

/**
 * The Image Component is the primary way in which Images within the application are used.
 */
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  animations: [imageGhostExitAnimation, imageProgressSpinnerAnimations],
  providers: [ImageService, ImageRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, MatProgressSpinner, FaIconComponent, AbstractLinkComponent, AsyncPipe],
})
export class ImageComponent implements OnDestroy, AfterViewInit {
  /**
   * The Image Fade is controlled programatically.
   */
  @ViewChildren('img') images: QueryList<HTMLImageElement>;

  /**
   * The Config as provided to the element
   */
  private _config?: IImage;
  @Input()
  set config(configuration: IImage) {
    this._config = configuration;
    this.imageService.initialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Disable the loading progress spinner manually.
   */
  @Input() set disableLoadingProgress(hide: boolean) {
    if (hide === true) {
      this.imageService.disableLoadingProgress();
    }
  }

  // used to teardown the setup observable
  private setupSubscription?: Subscription;

  // used to teardown the loading observable
  private imageSubscription?: Subscription;

  // a reference to the animation player
  private imageAnimationPlayer?: AnimationPlayer;

  // stores the URL.createObjectURL() reference for teardown
  private imageBlob?: string;

  /**
   * Constructor
   */
  constructor(
    private readonly loggerService: LoggerService,
    private readonly animationBuilder: AnimationBuilder,
    private readonly imageService: ImageService,
    public readonly imageRepository: ImageRepository,
  ) {}

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // The image children subscription will listen to the component DOM
    // seeking for when there is an HTMLImageElement. As soon as it finds
    // it, it is animated and the subscription is destroyed.

    this.imageSubscription = this.images.changes.subscribe((changes) => {
      // is there an image on the DOM?
      if (changes?.first?.nativeElement) {
        // If so we ditch this subscription as it's done it's job
        this.imageSubscription?.unsubscribe();

        const imageAnimationFactory = this.animationBuilder.build([
          style({ opacity: 0 }),

          animate('500ms cubic-bezier(0.645, 0.045, 0.355, 1)', style({ opacity: 1 })),
        ]);

        this.imageAnimationPlayer = imageAnimationFactory.create(changes.first.nativeElement);
        this.imageAnimationPlayer.play();
      }
    });

    // listen for when setup is complete
    this.setupSubscription = this.imageRepository.isSetup$
      .pipe(
        // listen for when the component is setup
        filter((isSetup) => isSetup),
        // switch to the image source
        switchMap(() => this.imageRepository.src$),
        // with the image source, load the image with the service
        switchMap((src) => this.imageService.imageLoadingRequest(src)),
      )
      .subscribe({
        // with a successful update
        next: (response) => {
          // if there is a blob, then the image is completed
          if (response.blob) {
            // stop listening for updates
            this.setupSubscription?.unsubscribe();
            // create a URL for the blob
            const createdBlob = URL.createObjectURL(response.blob);
            // keep a reference to this because it must be freed when the component is destroyed
            this.imageBlob = createdBlob;
            // apply the loaded data to the store
            this.imageService.imageData(createdBlob);
          } else {
            // if there is no blob, we must be loading so if the progress is a number
            if (typeof response.progress === 'number') {
              // and the progress is between 1 and 99
              if (response.progress > 0 && response.progress < 100) {
                // update the progress
                this.imageRepository.applyProgress(response.progress);
              }
            }
          }
        },
        error: (error: Error) => {
          // we no longer need the subscription
          this.setupSubscription?.unsubscribe();
          // log the error
          this.loggerService.error(error.message);
          // loading has failed
          this.imageService.error();
        },
      });
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.setupSubscription?.unsubscribe();
    this.imageSubscription?.unsubscribe();
    this.imageAnimationPlayer?.destroy();

    // The image was loaded as a blob so it should be revoked to free memory
    if (this.imageBlob) {
      URL.revokeObjectURL(this.imageBlob);
    }
  }
}
