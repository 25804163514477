<!-------------------------------------------------------------------------------------------->
<!-- INITIALIZING: During initializing the media is being configured and attempting to load -->
<!-------------------------------------------------------------------------------------------->

<!-- During Initialize display a query loading bar as there is nothing to show -->
@if (mediaRepository.initializing$ | async) {
  <mat-progress-bar class="loader disable-pointer" mode="query"></mat-progress-bar>
}

<!-------------------------------------------------------------------------------------------->
<!-- INITIALIZING ERROR: During initializing if an error occurs the component will never    -->
<!-- reach the initialized state                                                            -->
<!-------------------------------------------------------------------------------------------->

<!-- If an error occurs during initiailization, then a specific error duplication is shown. -->
@if (mediaRepository.initializingError$ | async) {
  <div
    class="container"
    [style.aspectRatio]="(mediaRepository.aspectRatio$ | async) || 0"
    [class.limit-portrait-width-on-desktop]="mediaRepository.limitPortraitWidthOnDesktop$ | async"
  >
    @if (mediaRepository.error$ | async) {
      <div class="error">
        <div class="error-content" fxLayout="column" fxLayoutAlign="space-between center">
          @if (mediaRepository.errorIcon$ | async) {
            <app-fa-icon class="error-icon" [config]="mediaRepository.errorIcon$ | async"></app-fa-icon>
          }
          @if (mediaRepository.errorReason$ | async) {
            <div>{{ mediaRepository.errorReason$ | async }}</div>
          }
        </div>
      </div>
    }
  </div>
}

<!-------------------------------------------------------------------------------------------->
<!-- INITIALIZED: The media has been configured and managed to boot up.                     -->
<!-------------------------------------------------------------------------------------------->

<!-- Initialized -->
@if (mediaRepository.initialized$ | async) {
  <div
    class="container"
    [style.aspectRatio]="(mediaRepository.aspectRatio$ | async) || 0"
    [class.limit-portrait-width-on-desktop]="mediaRepository.limitPortraitWidthOnDesktop$ | async"
  >
    <!-- Once details become available, the poster of the media can be displayed -->
    @if (mediaRepository.hasPoster$ | async) {
      <div class="poster disable-pointer" [style.backgroundImage]="mediaRepository.poster$ | async"></div>
    }
    @if (mediaRepository.initialized$ | async) {
      <div
        class="provider-container"
        [class.disable-pointer]="mediaRepository.interactionDisabled$ | async"
        appDomIntersectionObserver
        (isIntersecting)="isIntersecting($event)"
        [isContinuous]="true"
      >
        <!-- Once all dependencies are met, the system becomes active as displays the correct player -->
        @if (mediaRepository.shouldRenderYouTube$ | async) {
          <app-media-youtube
            class="provider"
            [@mediaReadyAnimation]="(mediaRepository.ready$ | async) ? 'true' : 'false'"
          ></app-media-youtube>
        }
        @if (mediaRepository.shouldRenderVimeo$ | async) {
          <app-media-vimeo class="provider" [@mediaReadyAnimation]="(mediaRepository.ready$ | async) ? 'true' : 'false'"></app-media-vimeo>
        }
        @if (mediaRepository.shouldRenderVideo$ | async) {
          <app-media-native-video class="provider" [@mediaReadyAnimation]="(mediaRepository.ready$ | async) ? 'true' : 'false'">
          </app-media-native-video>
        }
        @if (mediaRepository.shouldRenderAudio$ | async) {
          <app-media-native-audio class="provider" [@mediaReadyAnimation]="(mediaRepository.ready$ | async) ? 'true' : 'false'">
          </app-media-native-audio>
        }
        <!-- Contains overlaid UI for play button, and play and pause state animations -->
        @if (mediaRepository.displayUIOverlay$ | async) {
          <app-media-ui class="ui disable-pointer"></app-media-ui>
        }
      </div>
    }
    <!-- Errors can occur for any reason -->
    @if (mediaRepository.error$ | async) {
      <div class="error">
        <div class="error-content" fxLayout="column" fxLayoutAlign="space-between center">
          @if (mediaRepository.errorIcon$ | async) {
            <app-fa-icon class="error-icon" [config]="mediaRepository.errorIcon$ | async"></app-fa-icon>
          }
          @if (mediaRepository.errorReason$ | async) {
            <div>{{ mediaRepository.errorReason$ | async }}</div>
          }
        </div>
      </div>
    }
  </div>
}
