import { Injectable } from '@angular/core';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { ButtonRepository } from './button.repository';

/**
 * The Button Service is used with the {@link ButtonComponent}.
 */
@Injectable()
export class ButtonService {
  /**
   * Constructor
   */
  constructor(private readonly buttonRepository: ButtonRepository) {}

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: IButton) {
    this.buttonRepository.applyInitialize(configuration);
  }

  /**
   * Is the Button disabled?
   *
   * @param isDisabled - true if disabled, false otherwise.
   */
  applyDisabled(isDisabled: boolean) {
    this.buttonRepository.applyDisabled(isDisabled);
  }

  /**
   * Is the Button related to a form?
   *
   * @param formName - the name of the form this button belongs to.
   */
  applyForm(formName: string) {
    this.buttonRepository.applyForm(formName);
  }
}
