import { Injectable } from '@angular/core';
import { MediaChange } from '@ngbracket/ngx-layout';
import { IButtonContainer } from 'src/app/api/modules/core/dynamic/containers/IButtonContainer';
import { ButtonContainerRepository } from './button-container.repository';

/**
 * Service for managing button container state and interactions.
 * This service acts as a mediator between the component and its underlying state management store.
 * It provides methods to initialize the button container with a configuration and to handle media change events.
 */
@Injectable()
export class ButtonContainerService {
  /**
   * Creates an instance of ButtonContainerService.
   * @param store An instance of ButtonContainerStore which holds the state and logic of the button container component.
   */
  constructor(private readonly store: ButtonContainerRepository) {}

  /**
   * Initializes the button container with an optional configuration.
   * If provided, the configuration is passed to the store to set up the initial state of the button container.
   * @param configuration An optional IButtonContainer configuration object to initialize the button container.
   */
  initialize(configuration?: IButtonContainer) {
    this.store.initialize(configuration);
  }

  /**
   * Handles media change events.
   * This method is used to inform the store about changes in media (such as screen size changes)
   * and to adjust the button container accordingly.
   * @param change An instance of MediaChange which provides details about the media query change.
   */
  mediaChange(change: MediaChange) {
    this.store.mediaChange(change);
  }
}
