import { Injectable } from '@angular/core';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import { DialogContentRepository } from './dialog-content.repository';

/**
 * The Dialog Content Service is responsible for managing the state of the {@link DialogContentComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DialogContentService {
  /**
   * Constructor
   */
  constructor(private readonly dialogContentRepository: DialogContentRepository) {}

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Configure the system with the JSON configuration.
   *
   * @param configuration - the configuration to apply to the system.
   */
  applyConfiguration(configuration?: IDialogContent) {
    this.dialogContentRepository.applyConfiguration(configuration);
  }
}
