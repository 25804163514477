import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FaIconComponent as FaIconLibraryComponent } from '@fortawesome/angular-fontawesome';
import { IFaIcon } from 'src/app/api/modules/icons/fa/IFaIcon';
import { FaIconNamePipe } from '../../pipes/fa-icon-name.pipe';
import { FaIconRepository } from './fa-icon.repository';

@Component({
  selector: 'app-fa-icon',
  templateUrl: './fa-icon.component.html',
  styleUrls: ['./fa-icon.component.scss'],
  providers: [FaIconRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconLibraryComponent, AsyncPipe, FaIconNamePipe],
})
export class FaIconComponent {
  constructor(public faIconRepository: FaIconRepository) {}

  /**
   * The Config as provided to the element
   */
  @Input()
  set config(configuration: IFaIcon) {
    this.faIconRepository.initialize(configuration);
  }
}
