import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ITable } from 'src/app/api/modules/core/dynamic/components/ITable';
import { TableRepository } from './table.repository';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [TableRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class TableComponent {
  /**
   * The configuration for this component.
   */
  private _config?: ITable;

  /**
   * Get the configuration for this component.
   */
  get config() {
    return this._config;
  }

  /**
   * Set the configuration for this component.
   */
  set config(config: ITable) {
    this._config = config;
    this.repository.applyConfiguration(config);
  }

  constructor(public readonly repository: TableRepository) {}
}
