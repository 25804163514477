@if (leaderboardEntryRepository.templateData$ | async; as templateData) {
  @if (templateData.data; as data) {
    @for (item of data; track $index) {
      <td
        [class.belongs-to-user]="templateData.belongsToUser"
        [class.highlight]="templateData.hasImportantChange"
        [attr.colspan]="templateData.data.length === 1 ? templateData.headerCount : null"
      >
        <div class="cell-content" [class.nowrap]="item.preventWrap" [class.single-cell]="templateData.data.length === 1">
          @if (item?.icon) {
            <app-fa-icon [config]="item.icon"></app-fa-icon>
          }
          @if (item?.text) {
            <span class="mat-body-medium"> {{ item.text }} </span>
          }
        </div>
      </td>
    }
  }
}
