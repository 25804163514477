/**
 * All Material Typography Tokens.
 *
 * This is the only place where you should list Material Typography Tokens in Javascript.
 *
 * Utility methods generate the Typescript Interface from this list.
 */
export const MaterialTypographyTokenKeys = {
  '--sys-display-large': undefined,
  '--sys-display-large-font': undefined,
  '--sys-display-large-line-height': undefined,
  '--sys-display-large-size': undefined,
  '--sys-display-large-tracking': undefined,
  '--sys-display-large-weight': undefined,
  '--sys-display-medium': undefined,
  '--sys-display-medium-font': undefined,
  '--sys-display-medium-line-height': undefined,
  '--sys-display-medium-size': undefined,
  '--sys-display-medium-tracking': undefined,
  '--sys-display-medium-weight': undefined,
  '--sys-display-small': undefined,
  '--sys-display-small-font': undefined,
  '--sys-display-small-line-height': undefined,
  '--sys-display-small-size': undefined,
  '--sys-display-small-tracking': undefined,
  '--sys-display-small-weight': undefined,
  '--sys-headline-large': undefined,
  '--sys-headline-large-font': undefined,
  '--sys-headline-large-line-height': undefined,
  '--sys-headline-large-size': undefined,
  '--sys-headline-large-tracking': undefined,
  '--sys-headline-large-weight': undefined,
  '--sys-headline-medium': undefined,
  '--sys-headline-medium-font': undefined,
  '--sys-headline-medium-line-height': undefined,
  '--sys-headline-medium-size': undefined,
  '--sys-headline-medium-tracking': undefined,
  '--sys-headline-medium-weight': undefined,
  '--sys-headline-small': undefined,
  '--sys-headline-small-font': undefined,
  '--sys-headline-small-line-height': undefined,
  '--sys-headline-small-size': undefined,
  '--sys-headline-small-tracking': undefined,
  '--sys-headline-small-weight': undefined,
  '--sys-title-large': undefined,
  '--sys-title-large-font': undefined,
  '--sys-title-large-line-height': undefined,
  '--sys-title-large-size': undefined,
  '--sys-title-large-tracking': undefined,
  '--sys-title-large-weight': undefined,
  '--sys-title-medium': undefined,
  '--sys-title-medium-font': undefined,
  '--sys-title-medium-line-height': undefined,
  '--sys-title-medium-size': undefined,
  '--sys-title-medium-tracking': undefined,
  '--sys-title-medium-weight': undefined,
  '--sys-title-small': undefined,
  '--sys-title-small-font': undefined,
  '--sys-title-small-line-height': undefined,
  '--sys-title-small-size': undefined,
  '--sys-title-small-tracking': undefined,
  '--sys-title-small-weight': undefined,
  '--sys-body-large': undefined,
  '--sys-body-large-font': undefined,
  '--sys-body-large-line-height': undefined,
  '--sys-body-large-size': undefined,
  '--sys-body-large-tracking': undefined,
  '--sys-body-large-weight': undefined,
  '--sys-body-medium': undefined,
  '--sys-body-medium-font': undefined,
  '--sys-body-medium-line-height': undefined,
  '--sys-body-medium-size': undefined,
  '--sys-body-medium-tracking': undefined,
  '--sys-body-medium-weight': undefined,
  '--sys-body-small': undefined,
  '--sys-body-small-font': undefined,
  '--sys-body-small-line-height': undefined,
  '--sys-body-small-size': undefined,
  '--sys-body-small-tracking': undefined,
  '--sys-body-small-weight': undefined,
  '--sys-label-large': undefined,
  '--sys-label-large-font': undefined,
  '--sys-label-large-line-height': undefined,
  '--sys-label-large-size': undefined,
  '--sys-label-large-tracking': undefined,
  '--sys-label-large-weight': undefined,
  '--sys-label-large-weight-prominent': undefined,
  '--sys-label-medium': undefined,
  '--sys-label-medium-font': undefined,
  '--sys-label-medium-line-height': undefined,
  '--sys-label-medium-size': undefined,
  '--sys-label-medium-tracking': undefined,
  '--sys-label-medium-weight': undefined,
  '--sys-label-medium-weight-prominent': undefined,
  '--sys-label-small': undefined,
  '--sys-label-small-font': undefined,
  '--sys-label-small-line-height': undefined,
  '--sys-label-small-size': undefined,
  '--sys-label-small-tracking': undefined,
  '--sys-label-small-weight': undefined,
} as const;

////////////////////////////////////////////////////////////////////////////////////////
// DO NOT MODIFY BELOW THIS LINE
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Generates System Tokens from the provided Typography.
 */
export const GenerateMaterialTypographySystemTokens = (typography?: TypographySchema) => {
  if (!typography) {
    return undefined;
  }

  const typo: MaterialTypographyTokens = {
    '--sys-display-large': `400 3.562rem / 4rem ${typography.name}`,
    '--sys-display-large-font': typography.name,
    '--sys-display-large-line-height': '4rem',
    '--sys-display-large-size': '3.562rem',
    '--sys-display-large-tracking': '-0.016rem',
    '--sys-display-large-weight': '400',
    '--sys-display-medium': `400 2.812rem / 3.25rem ${typography.name}`,
    '--sys-display-medium-font': typography.name,
    '--sys-display-medium-line-height': '3.25rem',
    '--sys-display-medium-size': '2.812rem',
    '--sys-display-medium-tracking': '0',
    '--sys-display-medium-weight': '400',
    '--sys-display-small': `400 2.25rem / 2.75rem ${typography.name}`,
    '--sys-display-small-font': typography.name,
    '--sys-display-small-line-height': '2.75rem',
    '--sys-display-small-size': '2.25rem',
    '--sys-display-small-tracking': '0',
    '--sys-display-small-weight': '400',
    '--sys-headline-large': `400 2rem / 2.5rem ${typography.name}`,
    '--sys-headline-large-font': typography.name,
    '--sys-headline-large-line-height': '2.5rem',
    '--sys-headline-large-size': '2rem',
    '--sys-headline-large-tracking': '0',
    '--sys-headline-large-weight': '400',
    '--sys-headline-medium': `400 1.75rem / 2.25rem ${typography.name}`,
    '--sys-headline-medium-font': typography.name,
    '--sys-headline-medium-line-height': '2.25rem',
    '--sys-headline-medium-size': '1.75rem',
    '--sys-headline-medium-tracking': '0',
    '--sys-headline-medium-weight': '400',
    '--sys-headline-small': `400 1.5rem / 2rem ${typography.name}`,
    '--sys-headline-small-font': typography.name,
    '--sys-headline-small-line-height': '2rem',
    '--sys-headline-small-size': '1.5rem',
    '--sys-headline-small-tracking': '0',
    '--sys-headline-small-weight': '400',
    '--sys-title-large': `400 1.375rem / 1.75rem ${typography.name}`,
    '--sys-title-large-font': typography.name,
    '--sys-title-large-line-height': '1.75rem',
    '--sys-title-large-size': '1.375rem',
    '--sys-title-large-tracking': '0',
    '--sys-title-large-weight': '400',
    '--sys-title-medium': `500 1rem / 1.5rem ${typography.name}`,
    '--sys-title-medium-font': typography.name,
    '--sys-title-medium-line-height': '1.5rem',
    '--sys-title-medium-size': '1rem',
    '--sys-title-medium-tracking': '0.009rem',
    '--sys-title-medium-weight': '500',
    '--sys-title-small': `500 0.875rem / 1.25rem ${typography.name}`,
    '--sys-title-small-font': typography.name,
    '--sys-title-small-line-height': '1.25rem',
    '--sys-title-small-size': '0.875rem',
    '--sys-title-small-tracking': '0.006rem',
    '--sys-title-small-weight': '500',
    '--sys-body-large': `400 1rem / 1.5rem ${typography.name}`,
    '--sys-body-large-font': typography.name,
    '--sys-body-large-line-height': '1.5rem',
    '--sys-body-large-size': '1rem',
    '--sys-body-large-tracking': '0.031rem',
    '--sys-body-large-weight': '400',
    '--sys-body-medium': `400 0.875rem / 1.25rem ${typography.name}`,
    '--sys-body-medium-font': typography.name,
    '--sys-body-medium-line-height': '1.25rem',
    '--sys-body-medium-size': '0.875rem',
    '--sys-body-medium-tracking': '0.016rem',
    '--sys-body-medium-weight': '400',
    '--sys-body-small': `400 0.75rem / 1rem ${typography.name}`,
    '--sys-body-small-font': typography.name,
    '--sys-body-small-line-height': '1rem',
    '--sys-body-small-size': '0.75rem',
    '--sys-body-small-tracking': '0.025rem',
    '--sys-body-small-weight': '400',
    '--sys-label-large': `500 0.875rem / 1.25rem ${typography.name}`,
    '--sys-label-large-font': typography.name,
    '--sys-label-large-line-height': '1.25rem',
    '--sys-label-large-size': '0.875rem',
    '--sys-label-large-tracking': '0.006rem',
    '--sys-label-large-weight': '500',
    '--sys-label-large-weight-prominent': '700',
    '--sys-label-medium': `500 0.75rem / 1rem ${typography.name}`,
    '--sys-label-medium-font': typography.name,
    '--sys-label-medium-line-height': '1rem',
    '--sys-label-medium-size': '0.75rem',
    '--sys-label-medium-tracking': '0.031rem',
    '--sys-label-medium-weight': '500',
    '--sys-label-medium-weight-prominent': '700',
    '--sys-label-small': `500 0.688rem / 1rem ${typography.name}`,
    '--sys-label-small-font': typography.name,
    '--sys-label-small-line-height': '1rem',
    '--sys-label-small-size': '0.688rem',
    '--sys-label-small-tracking': '0.031rem',
    '--sys-label-small-weight': '500',
  };

  return typo;
};

/**
 * The Interface representing Typography Settings
 */
export interface TypographySchema {
  /**
   * The Font Family to use for the Typography.
   */
  name: string;
}

/**
 * Helper typescript syntax for mapping keys from a constant to a type.
 */
type MappedInterface<T> = {
  [K in keyof T]?: string;
};

/**
 * Generated Interface for the Material Color Tokens.
 */
export interface MaterialTypographyTokens extends Partial<MappedInterface<typeof MaterialTypographyTokenKeys>> {}
