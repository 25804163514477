import { animation, sequence, useAnimation } from '@angular/animations';
import { ANIMATION_DISPLAY_DURATION } from '../AnimationConsts';
import { IAnimationCompoundOptions } from '../IAnimationCompoundOptions';
import {
  slideInFromBottomAnimation,
  slideInFromLeftAnimation,
  slideInFromRightAnimation,
  slideInFromTopAnimation,
  slideOutToBottomAnimation,
  slideOutToLeftAnimation,
  slideOutToRightAnimation,
  slideOutToTopAnimation,
} from '../parts/common.animation';

export const slideInOutFromLeftCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = slideInFromLeftAnimation(options.enter);
  const exit = slideOutToRightAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};

export const slideInOutFromRightCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = slideInFromRightAnimation(options.enter);
  const exit = slideOutToLeftAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};

export const slideInOutFromTopCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = slideInFromTopAnimation(options.enter);
  const exit = slideOutToBottomAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};

export const slideInOutFromBottomCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = slideInFromBottomAnimation(options.enter);
  const exit = slideOutToTopAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};

export const slideInOutFromLeftReversedCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = slideInFromLeftAnimation(options.enter);
  const exit = slideOutToLeftAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};

export const slideInOutFromRightReversedCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = slideInFromRightAnimation(options.enter);
  const exit = slideOutToRightAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};

export const slideInOutFromTopReversedCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = slideInFromTopAnimation(options.enter);
  const exit = slideOutToTopAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};

export const slideInOutFromBottomReversedCompoundAnimation = (options: Partial<IAnimationCompoundOptions> = {}) => {
  const enter = slideInFromBottomAnimation(options.enter);
  const exit = slideOutToBottomAnimation({
    ...options.exit,
    delay: options.displayDuration || ANIMATION_DISPLAY_DURATION,
  });

  return animation([sequence([useAnimation(enter), useAnimation(exit)])]);
};
