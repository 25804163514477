import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { filter, map, switchMap } from 'rxjs';
import { IIconState } from 'src/app/api/modules/icons/IIconState';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { IconStateModel } from './icon-state.model';

/**
 * The Default State
 */
function initialState(): IconStateModel {
  return {
    states: [],
    state: undefined,
    stateName: 'default',
    configured: false,
  };
}

/**
 * The Store used for a {@link IconStateComponent}.
 *
 * It belongs to the {@link IconModule}.
 */
@Injectable()
export class IconStateRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `icon-state-${uniqueId()}`,
    },
    withProps<IconStateModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  initialize(configuration?: IIconState) {
    const { stateName } = this.store.getValue();

    // if there is no configuration then return
    if (!configuration && !configuration.states) {
      return;
    }

    // clone the configuration states
    const states = cloneDeep(configuration.states);

    // the default state
    const newStateName = stateName || 'default';

    // find the default state
    const selectedState = states.find((state) => state.state === newStateName);

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.states = states;
        state.stateName = newStateName;
        state.state = selectedState;
        state.configured = true;
      }),
    );
  }

  /**
   * Selects the state.
   *
   * @param name - the name of the state to select
   */
  state(name: string) {
    const { states } = this.store.getValue();

    // the new state
    let stateName = name;
    if (typeof stateName !== 'string') {
      stateName = 'default';
    }

    // find the new state
    const selectedState = states.find((state) => state.state === stateName);

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.stateName = stateName;
        state.state = selectedState;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  private _configured = this.store.pipe(
    select((state) => state.configured),
    filter((value) => value),
  );

  private _state$ = this.store.pipe(select((state) => state.state));

  private _stateName$ = this.store.pipe(select((state) => state.stateName));

  templateData$ = this._configured.pipe(
    switchMap(() => ElfCombineQueries([this._state$, this._stateName$])),
    map(([state, stateName]) => ({ state, stateName })),
  );
}
