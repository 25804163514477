import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { filter, map, switchMap } from 'rxjs';
import { IFaIcon } from 'src/app/api/modules/icons/fa/IFaIcon';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { FaIconModel } from './fa-icon.model';

/**
 * The Default State
 */
function initialState(): FaIconModel {
  return {
    size: undefined,
    color: undefined,
    fixedWidth: undefined,
    rotate: undefined,
    flip: undefined,
    name: undefined,
    mask: undefined,
    transform: undefined,
    state: undefined,
    configured: false,
    pull: undefined,
  };
}

/**
 * The Store used for a {@link FaIconComponent}.
 *
 * It belongs to the {@link IconModule}.
 */
@Injectable()
export class FaIconRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `fa-icon-${uniqueId()}`,
    },
    withProps<FaIconModel>(initialState()),
  );

  /**
   * Constructor
   */
  constructor(private readonly loggerService: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  initialize(configuration?: IFaIcon) {
    if (configuration.color) {
      switch (configuration.color) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.loggerService.warn(
            '[Icon]::initialize - The "color" property of an icon should no longer be "primary" "accent" or "warn". These do not apply to Material 3".',
          );
          break;
      }
    }

    if (configuration) {
      this.store.update(
        ElfWrite((state) => {
          state.size = configuration.size;
          state.fixedWidth = configuration.fixedWidth;
          state.color = configuration.color;
          state.rotate = configuration.rotate;
          state.flip = configuration.flip;
          state.pull = configuration.pull;
          state.name = configuration.name;
          state.mask = configuration.mask;
          state.transform = configuration.transform;
          state.state = configuration.state;
          state.configured = true;
        }),
      );
    }
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((value) => value),
  );
  private _size$ = this.store.pipe(select((state) => state.size));
  private _color$ = this.store.pipe(select((state) => state.color));
  private _fixedWidth$ = this.store.pipe(select((state) => state.fixedWidth));
  private _rotate$ = this.store.pipe(select((state) => state.rotate));
  private _flip$ = this.store.pipe(select((state) => state.flip));
  private _pull$ = this.store.pipe(select((state) => state.pull));
  private _name$ = this.store.pipe(select((state) => state.name));
  private _mask$ = this.store.pipe(select((state) => state.mask));
  private _transform$ = this.store.pipe(select((state) => state.transform));
  private _state$ = this.store.pipe(select((state) => state.state));

  templateData$ = this._configured$.pipe(
    switchMap(() =>
      ElfCombineQueries([
        this._size$,
        this._color$,
        this._fixedWidth$,
        this._rotate$,
        this._flip$,
        this._pull$,
        this._name$,
        this._mask$,
        this._transform$,
        this._state$,
      ]),
    ),
    map(([size, color, fixedWidth, rotate, flip, pull, name, mask, transform, state]) => {
      return { size, color, fixedWidth, rotate, flip, pull, name, mask, transform, state };
    }),
  );
}
